import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import Header from './components/Header';
import Aside from './components/Aside';
import FloorRow from './components/FloorRow';
import BreadcrumbMenu from './components/BreadcrumbMenu';

export default function Floors(props) {
  const [loading, setLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('Loading ...')
  const [project, setProject] = useState({})
  const [floors, setFloors] = useState([])
  const [fetchedProject, setFetchedProject] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  const { projectId } = useParams()

  useEffect(() => {
    async function fetchProject() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      })

      let projectFloors = []
      for (let floor of response.data.floors) {
        setLoadingMessage(`Loading floor ${projectFloors.length+1}/${response.data.floors.length} ...`)

        const floorRes = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/floors/${floor}`
        })

        projectFloors.push(floorRes.data)
      }

      setFloors(projectFloors)
      setProject(response.data)
      setLoading(false)
    }

    if (!fetchedProject) {
      fetchProject()
      setFetchedProject(true)
    }
  }, [fetchedProject, cookies, projectId])

  let floorItems;
  if (loading) {
    floorItems = (
      <div className="page-section text-center mb-4 mt-5">
        <div className="spinner-border text-primary" role="status"></div>
        <p className="text-muted mt-2">{loadingMessage}</p>
      </div>
    )
  } else {
    floorItems = floors.map((floor, i) => (
      <FloorRow
        key={`floor-${i}`}
        project={project}
        floors={floors}
        floor={floor}
        fetchProject={() => setFetchedProject(false)}
        />
    ))
  }

  return (
    <div className="app planpoint-floors">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='floors' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">{(project.previewMode || project.projectType === 'land') ? 'Phases' : 'Floors'}</h1>
              </header>

              <div className="page-section">
                <div className="card card-fluid">
                  <div className="list-group list-group-flush list-group-divider border-bottom">
                    {floorItems}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col text-right">
                    <a href={`/projects/${projectId}/exterior`} className="btn btn-outline-primary bg-white">+ add floor</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}