import React, { useEffect, useState } from 'react';
import BarChart from '../../charts/BarChart';

export default function SalesLastWeek(props) {
  const [data, setData] = useState([])
  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)

    if (today.getDay() !== 1) {
      const offset = today.getDay()-1
      today.setDate(today.getDate()-offset+1)
    }
    let thisWeekStart = new Date(today)

    let lastWeekStart = new Date(thisWeekStart)
    lastWeekStart.setDate(lastWeekStart.getDate()-6)

    let weekEvents = [
      { label: 'Sun', value: 0 },
      { label: 'Mon', value: 0 },
      { label: 'Tue', value: 0 },
      { label: 'Wed', value: 0 },
      { label: 'Thu', value: 0 },
      { label: 'Fri', value: 0 },
      { label: 'Sat', value: 0 }
    ]

    if (props.data && props.data.sale) {
      for (let event of props.data.sale) {
        const eventTS = new Date(event.createdAt)

        if (eventTS >= lastWeekStart && eventTS < thisWeekStart) {
          weekEvents[eventTS.getDay()].value += 1
        }
      }
    }

    setData([...weekEvents.slice(1,7), weekEvents[0]])
    setDataReady(true)
  }, [props.data])

  let conditionalChart;
  if (dataReady) {
    conditionalChart = (
      <BarChart
        limit={7}
        items={data}
        />
    )
  }

  return (
    <div className="col-md-6 col-sm-12">
      <div className="card card-fluid">
      <div className="card-header"> Sales/rentals last week </div>
        <div className="card-body text-center">
          {conditionalChart}
        </div>
      </div>
    </div>
  )
}