import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import BreadcrumbMenu from './components/BreadcrumbMenu';
import Header from './components/Header';
import Aside from './components/Aside';
import InteractiveCanvas from './components/InteractiveCanvas/InteractiveCanvas';

import Form from 'react-bootstrap/Form';

export default function Floorplan(props) {
  const [project, setProject] = useState({})
  const [fetchedProject, setFetchedProject] = useState(false)
  const [floor, setFloor] = useState({})
  const [fetchedFloor, setFetchedFloor] = useState(false)
  const [editableUnit, setEditableUnit] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [floors, setFloors] = useState([]);

  const cookies = useMemo(() => new Cookies(), [])

  const { projectId, floorId } = useParams()

  useEffect(() => {
    async function fetchProject() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      })

      setProject(response.data)

      response.data.floors.forEach(floor => {
        if (floor._id === floorId) setFloor(floor)
      })

      // fetch floors
      let floorData = []
      for (let floor of response.data.floors) {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/floors/${floor}`
        })

        floorData.push(response.data)
      }

      setFloors(floorData)
    }

    async function fetchFloor() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/floors/${floorId}`
      })

      setFloor(response.data)
    }

    if (!fetchedProject) {
      fetchProject()
      setFetchedProject(true)
    }

    if (!fetchedFloor) {
      fetchFloor()
      setFetchedFloor(true)
    }
  }, [fetchedProject, fetchedFloor, cookies, floorId, projectId])

  let unitItems = ((floor || {}).units || []).sort((a, b) => {
    const validNumberA = !isNaN(a.name) && !isNaN(parseFloat(a.name))
    const validNumberB = !isNaN(b.name) && !isNaN(parseFloat(b.name))

    if (validNumberA && validNumberB) {
      return (parseInt(a.name) > parseInt(b.name)) ? 1 : -1
    } else {
      return (a.name > b.name) ? 1 : -1
    }
  }).map((unit, i) => {
    let unitActions;
    if (unit._id === editableUnit) {
      unitActions = (
        <div className="list-group-item-figure">
          <button type="button" className="btn btn-outline-secondary" onClick={() => setEditableUnit()}>
            Cancel
          </button>
        </div>
      )
    } else {
      unitActions = (
        <div className="list-group-item-figure">
          <button type="button" className={unit.path && unit.path !== '' ? 'btn btn-outline-primary' : 'btn btn-primary'} onClick={() => setEditableUnit(unit._id)}>
            {unit.path && unit.path !== '' ? 'Redraw' : 'Draw'}
          </button>
        </div>
      )
    }

    return (
      <div className="list-group-item" key={`unit-${i}`}>
        <div className="list-group-item-body">
          <h4 className="list-group-item-title">
            <a href={`/projects/${projectId}/units`}>{unit.name}</a>
          </h4>
        </div>
        {unitActions}
      </div>
    )
  })

  async function patchUnit(unit, resetEditableUnit = true) {
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: unit,
      url: `${Env.url}/api/v1/units/${unit._id}`
    };

    await axios(options)
    setFetchedProject(false)

    if (resetEditableUnit) setEditableUnit()
  }

  async function saveCoordinates(unit, coordinates) {
    unit.path = JSON.stringify(coordinates)
    patchUnit(unit)
  }

  function resetCoordinates(unit) {
    unit.path = ""
    patchUnit(unit, false)
  }

  function zoomIn() {
    setFullscreen(true)
  }

  function zoomOut() {
    setFullscreen(false)
  }

  return (
    <div className="app planpoint-floors">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='floors' projectId={projectId}/>
                    </li>
                    <li className="breadcrumb-item active">
                      <Form.Control
                        custom
                        as="select"
                        value={(floor || {})._id}
                        className="project-scope-selector"
                        onChange={e => document.location = `/projects/${projectId}/floors/${e.target.value}`}
                        >
                          {(floors || []).map(f => (
                            <option value={f._id}>{f.name}</option>
                          ))}
                      </Form.Control>

                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">{(project.previewMode || project.projectType === 'land') ? 'Phaseplan' : 'Floorplan'}</h1>
              </header>

              <div className="page-section">
                <div className="card">
                  <div className="row">
                    <div className={fullscreen ? "col-md-12 order-2" : "col-md-6"}>
                      <div className="card-body">
                        <div className="list-group list-group-flush list-group-divider border-bottom">
                          {unitItems}
                        </div>
                        <div className="row mt-3 pr-3">
                          <div className="col text-right">
                            <a href={`/projects/${projectId}/units`} className="btn btn-secondary">
                              + add unit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={fullscreen ? "col-md-12 order-1" : "col-md-6"}>
                      <InteractiveCanvas
                        styleSettings={floor.floorplanUrl ? {backgroundImage: `url('${floor.floorplanUrl}')`} : {}}
                        backgroundUrl={floor.floorplanUrl}
                        editableItem={editableUnit}
                        container={floor}
                        project={project}
                        scope='floors'
                        accentColor={project.accentColor}
                        items={floor.units}
                        containerId={floor._id}
                        setContainer={f => setFloor(f)}
                        saveAction={(unit, coordinates) => saveCoordinates(unit, coordinates)}
                        resetAction={unit => resetCoordinates(unit)}
                        zoomIn={() => zoomIn()}
                        zoomOut={() => zoomOut()}
                        addCTALabel='Add floorplan image'
                        updateCTALabel={(project.previewMode || project.projectType === 'land') ? 'Change phaseplan image' : 'Change floorplan image'}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
