import React from 'react';

export default function Aside(props) {
  let conditionalMenuItems;
  if (props.user.isAdmin || props.user.username.endsWith('@planpoint.io')) {
    conditionalMenuItems = [
      <li className="menu-item">
        <a href="/collections" className="menu-link">
          <span className="menu-text">Collections</span>
        </a>
      </li>,
      <li className="menu-item">
        <a href="/godmode" className="menu-link">
          <span className="menu-text"><code className="text-muted">/god_mode</code></span>
        </a>
    </li>
    ]
  }

  return (
    <aside className="app-aside app-aside-light app-aside-expand-md">
      <div className="aside-content">
        <header className="aside-header d-block d-md-none">
          <button className="btn-account" type="button" data-toggle="collapse" data-target="#dropdown-aside">
            <span className="account-icon">
              <span className="fa fa-caret-down fa-lg"></span>
            </span>
            <span className="account-summary">
              <span className="account-name">My Account</span>
              <span className="account-description">{props.user.username}</span>
            </span>
          </button>

          <div id="dropdown-aside" className="dropdown-aside collapse">
            <div className="pb-3">
              <a className="dropdown-item" href="/">Dashboard</a>
              <a className="dropdown-item" href="/">Settings</a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="https://planpoint.helpscoutdocs.com/">Help Center</a>
              <a className="dropdown-item" href="https://planpoint.helpscoutdocs.com/">Contact us</a>
            </div>
          </div>
        </header>

        <section className="aside-menu perfect-scrollbar">
          <nav id="stacked-menu" className="stacked-menu">
            <ul className="menu">
              <li className="menu-header">Account</li>
              <li className="menu-item">
                <a href="/" className="menu-link">
                  <span className="menu-text">Dashboard</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="/settings" className="menu-link">
                  <span className="menu-text">Settings</span>
                </a>
              </li>
              {conditionalMenuItems}
              <li className="menu-header">Help & Support</li>
              <li className="menu-item">
                <a href="https://planpoint.helpscoutdocs.com/" className="menu-link">
                  <span className="menu-text">Help Center</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="https://planpoint.helpscoutdocs.com/" className="menu-link">
                  <span className="menu-text">Contact us</span>
                </a>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </aside>
  )
}
