import React, { useState, useEffect, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Header from '../components/Header';
import Aside from '../components/Aside';

import CollectionItem from './components/CollectionItem';

export default function Collections(props) {
  const [fetchedCollections, setFetchedCollections] = useState(false)
  const [showNewCollectionModal, setShowNewCollectionModal] = useState(false)
  const [collectionName, setCollectionName] = useState()
  const [collections, setCollections] = useState([])

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchCollections() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/collections`
      };

      let response = await axios(options)
      setCollections(response.data)
    }

    if (props.user && !fetchedCollections) {
      fetchCollections()
      setFetchedCollections(true)
    }
  }, [fetchedCollections, cookies, props.user])

  async function createCollection() {
    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        name: collectionName,
        user: { '_id': props.user._id }
      },
      url: `${Env.url}/api/v1/collections`
    })

    setCollectionName()
    setShowNewCollectionModal(false)
    setFetchedCollections(false)
  }

  let collectionItems = collections.map(collection => {
    return (
      <CollectionItem collection={collection} />
    )
  })

  return (
    <div className="app planpoint-embed">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar d-flex justify-content-between">
                <h1 className="page-title">Collections</h1>
                <div className="btn-toolbar d-flex flex-row align-items-center">
                  <button
                    type="button"
                    className='btn ml-2 btn-outline-primary'
                    onClick={() => setShowNewCollectionModal(true)}
                    >
                    New Collection
                  </button>
                </div>
              </header>

              <div className="page-section">
                <div className="masonry-layout">
                  {collectionItems}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal show={showNewCollectionModal} onHide={() => setShowNewCollectionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New collection</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Name your new collection. This name will be displayed publicly.</p>

          <div className="section-block mb-5">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Collection #123"
                value={collectionName}
                onChange={e => setCollectionName(e.target.value)}
                />
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => createCollection()}>Create collection</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}