import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Header from './components/Header';
import Aside from './components/Aside';

export default function Godmode(props) {
  const [showWarning, setShowWarning] = useState(true)
  const [fetchedUsers, setFetchedUsers] = useState(false)
  const [users, setUsers] = useState([])

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchUsers() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/users`
      })

      setUsers(response.data)
    }

    if (!fetchedUsers) {
      fetchUsers()
      setFetchedUsers(true)
    }
  }, [fetchedUsers, cookies])

  async function signInAsUser(evt, user) {
    evt.preventDefault()

    props.signInUser(user)

    document.location = '/'
  }

  let userRows = users.map(u => (
    <tr>
      <td className="align-middle">
        <a href="/" onClick={e => signInAsUser(e, u)}>{u.name} {u.lastName}</a>
      </td>
      <td className="align-middle">{u.username}</td>
      <td className="align-middle">{u.projects.length}</td>
      <td className="align-middle">{u.groups.length}</td>
      <td className="align-middle"><span className="badge badge-subtle badge-info">{(new Date(u.createdAt).toLocaleString('en-ca'))}</span></td>

      <td className="align-middle text-right">
        <OverlayTrigger placement='top' overlay={<Tooltip>Sign in</Tooltip>}>
          <button type="button" className="btn btn-sm btn-icon btn-secondary" data-toggle="collapse" data-target="#details-cid5743" aria-expanded="false" onClick={e => signInAsUser(e, u)}>
            <span className="collapse-indicator"><i className="fa fa-angle-right"></i></span>
          </button>
        </OverlayTrigger>
      </td>
    </tr>
  ))

  return (
    <div className="app planpoint-embed">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar d-flex justify-content-between">
                <h1 className="page-title">God Mode</h1>
              </header>

              <div className="page-section">

              <div className="card card-fluid">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Customer</th>
                            <th>Email</th>
                            <th>Projects</th>
                            <th>Groups</th>
                            <th>Joined</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>{userRows}</tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal show={showWarning} onHide={() => setShowWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Caution!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p><span className="text-muted">This panel gives you the power to mess around with this application in ways no users could ever dream of. We very careful and try not to break anything.</span><br /><br /><strong>With great power comes great responsibility.</strong></p>
        </Modal.Body>

        <Modal.Footer>
          <a href="https://en.wikipedia.org/wiki/With_great_power_comes_great_responsibility#cite_note-25" className="btn btn-outline-primary mr-2">Learn more</a>
          <Button variant="primary" onClick={() => setShowWarning(false)}>Got it, thanks.</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}