import React, { useState, useEffect, useMemo } from "react"

import axios from 'axios';
import Cookies from 'universal-cookie';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import Env from '../../../../Environments';

import './style.css';

export default function CollectionItem(props) {
  const [fetchedCollection, setFetchedCollection] = useState(false)
  const [collection, setCollection] = useState()

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    function initializeMapbox(c) {
      let coordinates = [ -123.113889, 49.260833 ]
      if (c && c.lon && c.lat) {
        coordinates = [ c.lon, c.lat ]
      } else {
        for (let project of c.projects) {
          if (project.lon && project.lat) coordinates = [ project.lon, project.lat ]
        }
      }

      mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXM5N2NrIiwiYSI6ImNrczhwa25ndTB4MXIybnJuaGl2NXA0d2EifQ.Okc9gC3_brAyb8A4jIsyfQ';
      const map = new mapboxgl.Map({
        container: props.collection._id,
        style: 'mapbox://styles/mapbox/light-v10',
        center: coordinates,
        zoom: c.zoomLevel || 14
      });

      const projectGeo = c.projects.map(p => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [p.lon, p.lat]
        },
        properties: {
          title: p.name,
          description: p.address
        }
      }))

      const groupGeo = c.groups.map(g => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [g.lon, g.lat]
        },
        properties: {
          title: g.name,
          description: g.address
        }
      }))

      const geojson = {
        type: 'FeatureCollection',
        features: [...projectGeo, ...groupGeo]
      };

      // add markers to map
      geojson.features.forEach(function(marker) {

        // create a HTML element for each feature
        var el = document.createElement('div');
        el.className = 'marker';

        // make a marker for each feature and add to the map
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML('<h3 className="card-title">' + marker.properties.title + '</h3><p className="card-subtitle text-muted">' + marker.properties.description + '</p>'))
          .addTo(map);
      });
    }

    async function fetchCollection() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/collections/${props.collection._id}`
      })

      setCollection(response.data)

      // initialize mapbox
      initializeMapbox(response.data)
    }

    if (!fetchedCollection) {
      fetchCollection()
      setFetchedCollection(true)
    }
  }, [setFetchedCollection, cookies, fetchedCollection, props.collection._id])

  let collectionCover;
  if (collection) {
    if (collection.projects.length) {
      collectionCover = collection.projects[0].projectImageUrl
    } else if (collection.groups.length) {
      collectionCover = collection.groups[0].groupImageUrl
    } else {
      collectionCover = "assets/images/decoration/square-dots.svg"
    }
  } else {
    collectionCover = "assets/images/decoration/square-dots.svg"
  }

  return (
    <div className="CollectionItem masonry-item col-lg-6">
      <div className="card card-fluid">
        <div className="card-img-top img-fluid" id={props.collection._id} style={{ height: '300px' }}></div>
        <div className="card-body pt-2">
          <div className="row align-items-center mb-3">
            <div className="col-auto">
              <div className="user-avatar user-avatar-floated user-avatar-xl" style={{ background: 'white' }}>
                <img src={collectionCover} alt="" />
              </div>
            </div>

            <div className="col">
              <h3 className="card-title">
                <a href="user-profile.html">{props.collection.name}</a>
              </h3>
              <h6 className="card-subtitle text-muted"> Created at {new Date(props.collection.createdAt).toLocaleString('en-US')} </h6>
            </div>

            <div className="col-auto">
              <a href={`/collections/${props.collection._id}/embed`} className="btn btn-outline-primary mr-2">
                <i className="fas fa-code"></i>
              </a>
              <a href={`/collections/${props.collection._id}`} className="btn btn-outline-primary">
                <i className="fas fa-cog"></i> Settings
              </a>
            </div>
          </div>

          <div className="row text-center">
            <div className="col">
              <div className="metric">
                <h6 className="metric-value"> {collection ? collection.groups.length : 'loading ...'} </h6>
                <p className="metric-label"> Groups </p>
              </div>
            </div>

            <div className="col">
              <div className="metric">
              <h6 className="metric-value"> {collection ? collection.projects.length : 'loading ...'} </h6>
                <p className="metric-label"> Projects </p>
              </div>
            </div>

            <div className="col">
              <div className="metric">
                <h6 className="metric-value"> {props.collection.collectionLang} </h6>
                <p className="metric-label"> Language </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}