import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import BreadcrumbMenu from '../components/BreadcrumbMenu';
import Header from '../components/Header';
import Aside from '../components/Aside';

import SalesLastWeek from './components/SalesLastWeek';
import SalesLastMonth from './components/SalesLastMonth';
import ProjectEventTable from './components/ProjectEventTable';

import BarChart from '../charts/BarChart';

export default function Floors(props) {
  const [loading, setLoading] = useState(true)
  const [project, setProject] = useState({})
  const [units, setUnits] = useState([])
  const [unitEvents, setUnitEvents] = useState([])
  const [events, setEvents] = useState([])

  const cookies = useMemo(() => new Cookies(), [])

  const { projectId } = useParams()

  useEffect(() => {
    async function fetchStats() {
      const projectRes = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      })

      setProject(projectRes.data)

      let floorData = []
      let unitData = []
      for (let floor of projectRes.data.floors) {
        const floorRes = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/floors/${floor}`
        })

        floorData.push(floorRes.data)
        unitData = [...unitData, ...floorRes.data.units]
      }

      setUnits(unitData)

      // fetch events
      const unitEventRes = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: {
          filters: {
            unit: unitData.map(u => u._id)
          },
          projections: {},
          options: {}
        },
        url: `${Env.url}/api/v1/events/search`
      })

      let unitEventMap = {}
      let eventMap = {}
      for (let event of unitEventRes.data) {
        // store unit events
        if (unitEventMap[event.unit]) {
          if (unitEventMap[event.unit][event.name]) {
            unitEventMap[event.unit][event.name].push(event)
          } else {
            unitEventMap[event.unit][event.name] = [event]
          }
        } else {
          let newEvent = {}
          newEvent[event.name] = [event]
          unitEventMap[event.unit] = newEvent
        }

        // store events
        if (eventMap[event.name]) {
          eventMap[event.name].push(event)
        } else {
          eventMap[event.name] = [event]
        }
      }

      setUnitEvents(unitEventMap)
      setEvents(eventMap)

      setLoading(false)
    }

    fetchStats()
  }, [cookies, projectId])

  let activeView;
  if (loading) {
    activeView = (
      <div className="page-section text-center mt-5">
        <div className="spinner-border text-primary" role="status"></div>
        <p className="text-muted mt-2">Loading ...</p>
      </div>
    )
  } else {
    let viewsItems = []
    let dlItems = []
    let signupItems = []
    for (let i = 0; i < units.length; i++) {
      const unit = units[i]

      if (unitEvents[unit._id] && unitEvents[unit._id].view) {
        viewsItems.push({
          value: unitEvents[unit._id].view.length, label: unit.name
        })
      }

      if (unitEvents[unit._id] && unitEvents[unit._id].download_pdf) {
        dlItems.push({
          value: unitEvents[unit._id].download_pdf.length, label: unit.name
        })
      }
      
      if (unitEvents[unit._id] && unitEvents[unit._id].signup) {
        signupItems.push({
          value: unitEvents[unit._id].signup.length, label: unit.name
        })
      }
    }
    viewsItems = viewsItems.sort((a, b) => b.value - a.value)
    dlItems = dlItems.sort((a, b) => b.value - a.value)
    signupItems = signupItems.sort((a, b) => b.value - a.value)

    activeView = (
      <div className="page-section">
        <div className="metric-row">
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="card-metric">
              <div className="metric">
                <p className="metric-value h3">
                  <sub><i className="fa fa-tags"></i></sub> <span className="value">{units.length}</span>
                </p>
                <h2 className="metric-label"> Total number of units </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="card-metric">
              <div className="metric">
                <p className="metric-value h3">
                  <sub><i className="oi oi-heart"></i></sub> <span className="value">{(events['add_to_favorites'] || []).length}</span>
                </p>
                <h2 className="metric-label"> Total number of favorites </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="card-metric">
              <div className="metric">
                <p className="metric-value h3">
                  <sub><i className="oi oi-people"></i></sub> <span className="value"> {(project.leads || []).length}</span>
                </p>
                <h2 className="metric-label"> Total number of leads </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="card-metric">
              <div className="metric">
                <p className="metric-value h3">
                  <sub><i className="fa fa-eye"></i></sub> <span className="value">{(events['view'] || []).length}</span>
                </p>
                <h2 className="metric-label">
                  Unit views across the board <br />
                  <small>(since July 16, 2021)</small>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-5"></hr>

        <div className="section-block">
          <h2 className="section-title"> Sales performance </h2>
          <p className="text-muted"> See how your units sold over time </p>
        </div>

        <div className="row">
          <SalesLastWeek data={events} />
          <SalesLastMonth data={events} />
        </div>

        <hr className="my-5"></hr>

        <div className="section-block">
          <h2 className="section-title"> Insights </h2>
          <p className="text-muted"> See how your data stacks up </p>
        </div>

        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="card card-fluid">
              <div className="card-header"> Most viewed units </div>
              <div className="card-body text-center">
                <BarChart items={viewsItems} />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card card-fluid">
            <div className="card-header"> Most downloaded PDF plans </div>
              <div className="card-body text-center">
              <BarChart items={dlItems} />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card card-fluid">
            <div className="card-header"> Most requested - messages sent </div>
              <div className="card-body text-center">
              <BarChart items={signupItems} />
              </div>
            </div>
          </div>
        </div>

        <hr className="my-5"></hr>

        <div className="section-block">
          <h2 className="section-title"> Performance Metrics </h2>
          <p className="text-muted"> Browse your historic data </p>
        </div>

        <div className="page-section">
          <div className="card card-fluid">
            <div className="card-body">
              <div className="table-responsive">
                <ProjectEventTable
                  units={units}
                  unitEvents={unitEvents}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="app planpoint-stats">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='stats' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <div className="d-md-flex align-items-md-start">
                  <h1 className="page-title mr-sm-auto">Statistics</h1>
                </div>
              </header>

              {activeView}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
