const formatOptions = {
  formatDate: function(dateString = '') {
    let dateObject = new Date((dateString).replace(/-/g, '/'))
    const dateIsValid = dateObject instanceof Date && !isNaN(dateObject)

    if (!dateIsValid) dateObject = new Date(dateString)

    if (dateObject.valueOf()) {
      return dateObject.toLocaleDateString()
    } else {
      // format separators
      const [d, m, y] = dateString.split(' ')
      dateObject = new Date(`${m}/${d}/${y}`)

      return dateObject.toLocaleDateString()
    }
  }
}

export default formatOptions;
