import React from 'react';

export default function MemberRow(props) {
  return (
    <tr>
      <td className="align-middle">
        <a href={`mailto:${props.email}`}>{props.email}</a>
      </td>

      <td className="align-middle">
        <input type="text"
          className="form-control"
          defaultValue={`https://dashboard.planpoint.io/join-project/${props.projectId}?email=${props.email}`}/>
      </td>

      <td className="align-middle">
        <span className={`badge badge-subtle ${props.status === 'Pending' ? 'badge-secondary' : 'badge-success'}`}>{props.status}</span>
      </td>

      <td className="align-middle">
        <span className={`badge badge-subtle ${props.level === 'Administrator' ? 'badge-success' : 'badge-warning'}`}>{props.level}</span>
      </td>

      <td className="align-middle text-right">
        <a href="/" className="btn btn-sm btn-icon btn-secondary" onClick={e => props.deleteMember()}>
          <i className="far fa-trash-alt"></i> <span className="sr-only">Remove</span>
        </a>
      </td>
    </tr>
  )
}
