import React, { useEffect, useState } from 'react';

import Header from './components/Header';

export default function Preview(props) {
  const [token, setToken] = useState()
  const [project, setProject] = useState()
  const [group, setGroup] = useState()
  const [lang, setLang] = useState('en')

  function setupScriptTag() {
    const script = document.createElement('script');
    script.src = "https://planpoint-v1.herokuapp.com/static/js/planpoint.js";
    script.async = true;
    document.querySelector('main.app-main').appendChild(script);
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    setToken(urlParams.get('token'))
    setProject(urlParams.get('project'))
    setGroup(urlParams.get('group'))
    setLang(urlParams.get('lang'))

    setupScriptTag()
  }, [])

  return (
    <div className="app planpoint-embed">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <main className="app-main">
        <link href="https://planpoint-v1.herokuapp.com/static/css/main.css" rel="stylesheet" />
        <div
          id="planpoint-root"
          data-token={token}
          data-project={project}
          data-group={group}
          data-lang={lang}
          />
      </main>
    </div>
  )
}
