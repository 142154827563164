import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import BreadcrumbMenu from './components/BreadcrumbMenu';
import Header from './components/Header';
import Aside from './components/Aside';
import InteractiveCanvas from './components/InteractiveCanvas/InteractiveCanvas';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function Exterior(props) {
  const [loading, setLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('Loading ...')
  const [project, setProject] = useState({})
  const [floors, setFloors] = useState([])
  const [fetchedProject, setFetchedProject] = useState(false)
  const [fetchedFloors, setFetchedFloors] = useState(false)
  const [editableFloor, setEditableFloor] = useState();
  const [editableFloorName, setEditableFloorName] = useState();
  const [editableFloorPath, setEditableFloorPath] = useState();
  const [confirmationFloor, setConfirmationFloor] = useState();
  const [fullscreen, setFullscreen] = useState(false);

  const cookies = useMemo(() => new Cookies(), [])

  const { projectId } = useParams()

  useEffect(() => {
    async function fetchProject() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      };

      const response = await axios(options)
      setProject(response.data)
    }

    async function fetchFloors() {
      let projectFloors = []

      for (let floor of project.floors) {
        setLoadingMessage(`Loading floor ${projectFloors.length+1}/${project.floors.length} ...`)

        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/floors/${floor}`
        })

        setFloors([...projectFloors, response.data])
        projectFloors.push(response.data)
      }

      setLoading(false)
    }

    if (!fetchedProject) {
      fetchProject()
      setFetchedProject(true)
    }

    if (project._id && !fetchedFloors) {
      fetchFloors()
      setFetchedFloors(true)
    }
  }, [fetchedProject, project._id, fetchedFloors, cookies, project.floors, projectId])

  async function deleteFloor(floor) {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/floors/${floor._id}`
    };

    await axios(options)
    setConfirmationFloor()

    setFloors(floors.filter(f => f._id !== floor._id))
  }

  async function createFloor() {
    const count = (floors || []).length + 1
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        "project": { "_id": project._id },
        "name": `New floor (#${count})`,
        "position": count,
        "floorplanUrl": ''
      },
      url: `${Env.url}/api/v1/floors`
    };

    const response = await axios(options)

    // add floor to the list
    setFloors([...floors, response.data])
  }

  async function updateEditableFloor() {
    await patchFloor({
      _id: editableFloor,
      name: editableFloorName,
      path: editableFloorPath
    })

    setNewEditableFloor()
  }

  function setNewEditableFloor(floor) {
    setEditableFloor(floor ? floor._id : undefined)
    setEditableFloorName(floor ? floor.name : undefined)
    setEditableFloorPath(floor ? floor.path : undefined)
  }

  function copyEditableFloorPathFrom(newPath) {
    setEditableFloorPath(newPath)
  }

  let floorItems;
  if (loading) {
    floorItems = (
      <div className="page-section text-center mt-5 mb-5">
        <div className="spinner-border text-primary" role="status"></div>
        <p className="text-muted mt-2">{loadingMessage}</p>
      </div>
    )
  } else {
    floorItems = (floors || []).sort((a, b) => a.name - b.name).map((floor, i) => {
      let floorInfo;
      if (floor._id === editableFloor) {
        floorInfo = (
          <div className="list-group-item-body list-group-item-figure">
            <Form.Group className="mb-0 mr-2">
              <Form.Label>{(project.previewMode || project.projectType === 'land') ? 'Phase name' : 'Floor name'}</Form.Label>
              <Form.Control type="text" value={editableFloorName} onChange={e => setEditableFloorName(e.target.value)} />
            </Form.Group>
          </div>
        )
      } else {
        // prevent empty and undefined paths from breaking JSON.parse
        let floorPath;
        if (floor.path) {
          try {
            floorPath = JSON.parse(floor.path === '' ? '[]' : floor.path)
          } catch (error) {
            floorPath = JSON.parse('[]')
          }
        } else {
          floorPath = []
        }
  
        let conditionalFloorPathIndicator;
        if (!floorPath || !floorPath.length) {
          conditionalFloorPathIndicator = (
            <small
              className="text-muted opacity-2"
              style={{ opacity: 0.6 }}
              >
              (missing path)
            </small>
          )
        }
  
        floorInfo = (
          <div className="list-group-item-body">
            <h4 className="list-group-item-title">
              <a className="d-flex align-items-center justify-content-flex-start" href={`/projects/${projectId}/units`}>
                <span className="mr-1">{floor.name}</span> {conditionalFloorPathIndicator}
              </a>
            </h4>
            <p className="list-group-item-text">{(floor.units || []).length} units</p>
          </div>
        )
      }
  
      let allFloorOptions = (floors || [])
        .filter((floor, j) => floor._id !== editableFloor)
        .map((floor, j) => (
          <option value={floor.path} key={j}>{floor.name}</option>
        ))
  
      let floorActions;
      if (floor._id === editableFloor) {
        floorActions = (
          <div className="list-group-item-figure">
            <Form.Group className="mb-0 mr-2">
              <Form.Label>{project.previewMode ? 'Copy phase layout from ...' : 'Copy floor layout from ...'}</Form.Label>
              <Form.Control as="select" custom onChange={e => copyEditableFloorPathFrom(e.target.value)}>
                <option value=""></option>
                {allFloorOptions}
              </Form.Control>
            </Form.Group>
  
            <Form.Group className="mb-0 mr-2 h-100 d-flex align-items-end">
              <button type="button" className="btn btn-outline-primary" onClick={() => setNewEditableFloor()}>
                Cancel
              </button>
            </Form.Group>
  
            <Form.Group className="mb-0 h-100 d-flex align-items-end">
              <button type="button" className="btn btn-primary" onClick={() => updateEditableFloor()}>
                Save
              </button>
            </Form.Group>
          </div>
        )
      } else {
        floorActions = (
          <div className="list-group-item-figure">
            <button type="button" className="btn btn-outline-danger mr-3" onClick={() => setConfirmationFloor(floor)}>
              Delete
            </button>
            <button type="button" className="btn btn-primary" onClick={() => setNewEditableFloor(floor)}>
              Edit
            </button>
          </div>
        )
      }
  
      return (
        <div className="list-group-item" key={`floor-${i}`}>
          {floorInfo}
          {floorActions}
        </div>
      )
    })
  }

  async function patchFloor(floor, resetEditableFloor = true) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: floor,
      url: `${Env.url}/api/v1/floors/${floor._id}`
    })

    const updatedFloor = response.data

    setFloors(floors.map(f => f._id === updatedFloor._id ? updatedFloor : f))

    if (resetEditableFloor) setEditableFloor()
  }

  async function saveCoordinates(floor, coordinates) {
    await patchFloor({
      _id: floor._id,
      path: JSON.stringify(coordinates)
    })
  }

  function resetCoordinates(floor) {
    floor.path = ""
    patchFloor(floor, false)
  }

  function zoomIn() {
    setFullscreen(true)
  }

  function zoomOut() {
    setFullscreen(false)
  }

  return (
    <div className="app planpoint-exterior">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <Modal show={confirmationFloor} onHide={() => setConfirmationFloor()}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-muted">
              Are you sure you want to delete this floor? It will delete all units inside of it and cannot be unchanged.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmationFloor()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => deleteFloor(confirmationFloor)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{project.name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='exterior' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">
                  {(project && (project.previewMode || project.projectType === 'land')) ? 'Land' : 'Exterior'}
                </h1>
              </header>

              <div className="page-section">
                <div className="card">
                  <div className="row">
                    <div className={fullscreen ? "col-md-12 order-2" : "col-md-6"}>
                      <div className="card-body">
                        <div className="list-group list-group-flush list-group-divider border-bottom">
                          {floorItems}
                        </div>
                        <div className="row mt-3 pr-3">
                          <div className="col text-right">
                            <button type="button" className="btn btn-outline-primary" onClick={() => createFloor()}>
                              {project.previewMode ? '+ add phase' : '+ add floor'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={fullscreen ? "col-md-12 order-1" : "col-md-6"}>
                      <InteractiveCanvas
                        styleSettings={project.projectImageUrl ? {backgroundImage: `url('${project.projectImageUrl}')`} : {}}
                        backgroundUrl={project.projectImageUrl}
                        editableItem={editableFloor}
                        container={project}
                        project={project}
                        scope='projects'
                        accentColor={project.accentColor}
                        items={floors}
                        containerId={projectId}
                        setContainer={p => setProject(p)}
                        saveAction={(floor, coordinates) => saveCoordinates(floor, coordinates)}
                        resetAction={(floor) => resetCoordinates(floor)}
                        zoomIn={() => zoomIn()}
                        zoomOut={() => zoomOut()}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
