import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

export default function Hosted(props) {
  const [token, setToken] = useState()
  const [project, setProject] = useState()

  const cookies = useMemo(() => new Cookies(), [])

  let { hostName, namespace, lang } = useParams();

  function setupScriptTag() {
    const script = document.createElement('script');
    script.src = "https://planpoint-v1.herokuapp.com/static/js/planpoint.js";
    script.async = true;
    document.querySelector('main.app-host').appendChild(script);
  }

  useEffect(() => {
    async function fetchProject() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/hosted/${namespace}/${hostName}`
      })

      setToken(response.data.user.token)
      setProject(response.data._id)

      document.title = `Online plan viewer for ${response.data.name}`

      setupScriptTag()
    }

    let hsBeacomRemoval = setInterval(removeHsBeacon, 100)

    function removeHsBeacon() {
      const hsBeacon = document.querySelector('.hsds-beacon')
      if (hsBeacon) {
        hsBeacon.remove()
        clearInterval(hsBeacomRemoval)
      }
    }

    fetchProject()
  }, [cookies, hostName, namespace])

  return (
    <div className="app planpoint-embed">
      <main className="app-host min-vh-100 bg-white">
        <link href="https://planpoint-v1.herokuapp.com/static/css/main.css" rel="stylesheet" />
        <div
          id="planpoint-root"
          data-token={token}
          data-project={project}
          data-lang={lang}
          />
      </main>
    </div>
  )
}
