import React, { useState } from 'react'
import axios from 'axios';

import Env from '../../Environments';

import '../Auth/Auth.css';

import planpointLogo from '../../assets/planpoint-logo.svg'

export default function Restore(props) {
  const [newPassword1, setNewPassword1] = useState()
  const [newPassword2, setNewPassword2] = useState()

  async function handleSubmit(e) {
    e.preventDefault()

    if (newPassword1 !== newPassword2) {
      alert("Your new password and password confirmation don't match!")
    } else {
      await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        data: {
          token: (new URL(document.location)).searchParams.get('restoreId'),
          newPassword: newPassword1,
          passwordConfirmation: newPassword2
        },
        url: `${Env.url}/api/v1/users/restore-password`
      })

      // go back
      document.location = '/'
    }
  }

  return (
    <div className="Auth">
      <main className="auth bg-white" style={{ minHeight: '100vh' }}>
        <form className="auth-form auth-form-reflow Auth-form" onSubmit={e => handleSubmit(e)}>
          <div className="text-center mb-4">
            <div className="mb-4">
              <img src={planpointLogo} height="24px" alt="" />
            </div>
            <h4>Restore Your Password</h4>
          </div>

          <div className="form-group mb-4">
            <label className="d-block text-left" htmlFor="inputUser"></label>
            <input
              type="password"
              className="form-control form-control-lg mb-4"
              required=""
              autofocus=""
              placeholder="New password"
              value={newPassword1}
              onChange={e => setNewPassword1(e.target.value)}
              />

            <input
              type="password"
              className="form-control form-control-lg"
              required=""
              autofocus=""
              placeholder="Password confirmation"
              value={newPassword2}
              onChange={e => setNewPassword2(e.target.value)}
              />
          </div>

          <div className="d-block d-md-inline-block mb-2">
            <button className="w-100 Auth-form-submit btn btn-primary" type="submit">Restore Password</button>
          </div>

          <div className="d-block d-md-inline-block">
            <a href="/login" className="btn btn-block btn-light">Return to signin</a>
          </div>
        </form>
      </main>
    </div>
  )
}
