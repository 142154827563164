import React from 'react';

export default function Notifications(props) {
  return (
    <div className="card card-fluid">
      <h6 className="card-header"> Notifications </h6>
      <div className="list-group list-group-flush">

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone follows you

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif01" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone mentions you

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif02" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone sends you a message

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif03" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>
        <div className="list-group-header"> Team Notifications </div>
        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone invite you to a team

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif04" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone update a team

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif05" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone add new project

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif06" className="switcher-input" /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone update a project

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif07" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>
        <div className="list-group-header"> Project Notifications </div>
        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone update a team

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif08" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone add new task

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif09" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Someone update a task

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif10" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>
        <div className="list-group-header"> News & Trending </div>
        <div className="list-group-item d-flex justify-content-between align-items-center"> Top members this week

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif11" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Top Teams this week

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif12" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Rating reminders

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif13" className="switcher-input" /> <span className="switcher-indicator"></span></label>
        </div>

        <div className="list-group-item d-flex justify-content-between align-items-center"> Project deadline

          <label className="switcher-control switcher-control-success"><input type="checkbox" name="notif14" className="switcher-input" checked /> <span className="switcher-indicator"></span></label>
        </div>
      </div>
    </div>
  )
}
