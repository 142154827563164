import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Formatter from '../../services/formatter';
import eventService from '../../services/eventService';

import { Square, CheckSquare } from 'react-feather';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export default function UnitRow(props) {
  const [editMode, setEditMode] = useState(false)
  const [unitName, setUnitName] = useState(props.unit.name)
  const [unitModel, setUnitModel] = useState(props.unit.type)
  const [unitSquareFeet, setUnitSquareFeet] = useState(props.unit.squareFeet)
  const [unitParking, setUnitParking] = useState(props.unit.parking)
  const [unitOrientation, setUnitOrientation] = useState(props.unit.orientation)
  const [unitInclusions, setUnitInclusions] = useState(props.unit.inclusions)
  const [unitDeliveryDate, setUnitDeliveryDate] = useState(props.unit.deliveryDate)
  const [unitBathrooms, setUnitBathrooms] = useState(props.unit.bathrooms)
  const [unitBedrooms, setUnitBedrooms] = useState(props.unit.bedrooms)
  const [unitPrice, setUnitPrice] = useState(props.unit.price)
  const [unitAvailability, setUnitAvailability] = useState(props.unit.availability)
  const [clonedImages, setClonedImages] = useState(props.unit.clonedImages || [])
  const [showUnitModal, setShowUnitModal] = useState(false)
  const [unitEmbedCode, setUnitEmbedCode] = useState(props.unit.embedCode)
  const [isUploadingPdf, setIsUploadingPdf] = useState(false)
  const [isUploadingLayout, setIsUploadingLayout] = useState(false)
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [showFinishesModal, setShowFinishesModal] = useState(false)
  const [showLayoutModal, setShowLayoutModal] = useState(false)
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [files, setFiles] = useState([])
  const [layouts, setLayouts] = useState([])
  const [pdfs, setPdfs] = useState([])
  const [selectedProjectImages, setSelectedProjectImages] = useState([])
  const [selectedProjectFinishes, setSelectedProjectFinishes] = useState([])
  const [unitPriceTBD, setUnitPriceTBD] = useState(props.unit.unitPriceTBD)
  const [unitDescription, setUnitDescription] = useState(props.unit.description)
  const [selected, setSelected] = useState(false)

  const unitLayoutInput = useRef()
  const unitPdfInput = useRef()

  const cookies = new Cookies()

  useEffect(() => {
    if (props.selectedUnits && props.unit) {
      setSelected(props.selectedUnits.indexOf(props.unit) !== -1)
    }
  }, [props.selectedUnits, props.unit])

  let unitNameField;
  if (editMode && !props.forUnitModel) {
    unitNameField = (
      <input
        type="text"
        className="form-control"
        value={unitName}
        onChange={e => setUnitName(e.target.value)}
        />
    )
  } else if (props.forUnitModel) {
    unitNameField = <span></span>
  } else {
    unitNameField = <span>{props.unit.name}</span>
  }

  function enableEditMode() {
    setEditMode(true)
    setUnitName(props.unit.name)
    setUnitModel(props.unit.type)
    setUnitSquareFeet(props.unit.squareFeet)
    setUnitParking(props.unit.parking)
    setUnitBedrooms(props.unit.bedrooms)
    setUnitPrice(props.unit.price)
    setUnitAvailability(props.unit.availability)
    setUnitDescription(props.unit.description)
  }

  function updateUnitModel(newValue) {
    setUnitModel(newValue)

    if (!props.forUnitModel) {
      // fetch all available units across all floors
      let availableUnits = []
      props.floors.forEach(floor => {
        availableUnits = [...availableUnits, ...floor.units]
      })

      // automatically pull model data
      availableUnits.forEach(unit => {
        if (unit.type === newValue) {
          setUnitSquareFeet(unitSquareFeet || unit.squareFeet)
          setUnitParking(unitParking || unit.parking)
          setUnitOrientation(unitOrientation || unit.orientation)
          setUnitBathrooms(unitBathrooms || unit.bathrooms)
          setUnitInclusions(unitInclusions || unit.inclusions)
          setUnitDeliveryDate(unitDeliveryDate || unit.deliveryDate)
          setUnitBedrooms(unitBedrooms || unit.bedrooms)
          setUnitPrice(unitPrice || unit.price)
          setUnitPriceTBD(unitPriceTBD || unit.unitPriceTBD)
          setUnitAvailability(unitAvailability || unit.availability)
          setClonedImages(unit.images)
          setUnitDescription(unit.description)
        }
      })
    }
  }

  async function addUnitModel() {
    // IMPORTANT: if this is the first model, make sure to create another one
    // based on the current unit configuration.
    if (props.unit.unitmodels.length === 0) {
      await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: {
          unit: { _id: props.unit._id },
          name: props.unit.name,
          bedrooms: props.unit.bedrooms,
          squareFeet: props.unit.squareFeet,
          price: props.unit.price,
          type: props.unit.type,
          availability: props.unit.availability,
          path: props.unit.path,
          layoutUrl: props.unit.layoutUrl,
          downloadableAsset: props.unit.downloadableAsset,
          embedCode: props.unit.embedCode,
          parking: props.unit.parking,
          bathrooms: props.unit.bathrooms,
          orientation: props.unit.orientation,
          inclusions: props.unit.inclusions,
          description: props.unit.description
        },
        url: `${Env.url}/api/v1/unitmodels`
      })
    }

    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        unit: { _id: props.unit._id },
        name: '',
        bedrooms: '',
        squareFeet: 0,
        price: 0,
        type: '',
        availability: '',
        path: '',
        layoutUrl: '',
        downloadableAsset: '',
        embedCode: ''
      },
      url: `${Env.url}/api/v1/unitmodels`
    })

    await props.fetchFloors()
  }

  let unitModelField;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitModelField = <span>-</span>
  } else {
    if (editMode) {
      unitModelField = (
        <input
          type="text"
          className="form-control"
          value={unitModel}
          onChange={e => updateUnitModel(e.target.value)}
          />
      )
    } else {
      unitModelField = props.unit.type
    }
  }

  let unitSquareFeetField;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitSquareFeetField = <span>-</span>
  } else {
    if (editMode) {
      unitSquareFeetField = (
        <input
          type="number"
          className="form-control"
          value={unitSquareFeet}
          onChange={e => setUnitSquareFeet(e.target.value)}
          />
      )
    } else {
      unitSquareFeetField = <span>{props.unit.squareFeet || 0} sqft.</span>
    }
  }

  let unitBedroomsField;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitBedroomsField = <span>-</span>
  } else {
    if (editMode) {
      unitBedroomsField = (
        <Form.Group className="mb-0">
          <Form.Control as="select" custom value={unitBedrooms} onChange={e => setUnitBedrooms(e.target.value)}>
            <option value=""></option>
            <option value="Studio">Studio</option>
            <option value="1 bedroom">1 bedroom</option>
            <option value="1 bedroom + den">1 bedroom + den</option>
            <option value="2 bedrooms">2 bedrooms</option>
            <option value="2 bedrooms + den">2 bedrooms + den</option>
            <option value="3 bedrooms">3 bedrooms</option>
            <option value="3 bedrooms + den">3 bedrooms + den</option>
            <option value="4 bedrooms">4 bedrooms</option>
            <option value="4 bedrooms + den">4 bedrooms + den</option>
            <option value="5 bedrooms">5 bedrooms</option>
            <option value="5 bedrooms + den">5 bedrooms + den</option>
            <option value="Penthouse">Penthouse</option>
            <option value="Townhouse">Townhouse</option>
          </Form.Control>
        </Form.Group>
      )
    } else {
      unitBedroomsField = props.unit.bedrooms
    }
  }

  let unitPriceField;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitPriceField = <span>-</span>
  } else {
    if (editMode) {
      unitPriceField = (
        <>
          <input
            type="number"
            className="form-control"
            value={unitPrice}
            onChange={e => setUnitPrice(e.target.value)}
            disabled={unitPriceTBD}
            />
          <div className="mt-1">
            <label className="switcher-control switcher-control">
              <input
                type="checkbox"
                name="showAvailability"
                className="switcher-input"
                checked={unitPriceTBD}
                onChange={e => setUnitPriceTBD(!unitPriceTBD)}
                />
              <span className="switcher-indicator"></span>
            </label>
            <label className="ml-2">
              <small className={unitPriceTBD ? '' : 'text-muted'}>to be discussed</small>
            </label>
          </div>
        </>
      )
    } else {
      unitPriceField = (props.unit.price || 0).toLocaleString('en-CA', { style: 'currency', currency: 'CAD', minimumFractionDigits: 0 }).replace(/[A-Z]/g, '')
    }
  }

  const rentalObject = props.project.rentalObject

  let unitAvailabilityField;
  let unitAvailabilityClass;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitAvailabilityField = <span>-</span>
  } else {
    if (editMode) {
      unitAvailabilityField = (
        <Form.Group className="mb-0">
          <Form.Control as="select" custom value={unitAvailability} onChange={e => setUnitAvailability(e.target.value)}>
            <option value=""></option>
            <option value="Available">Available</option>
            <option value="Reserved">Reserved</option>
            <option value="Unavailable">Unavailable</option>
            <option value="Sold">{rentalObject ? 'Leased' : 'Sold'}</option>
          </Form.Control>
        </Form.Group>
      )
    } else {
      unitAvailabilityField = props.unit.availability || 'Unknown'
      switch (props.unit.availability) {
        case 'Available':
          unitAvailabilityClass = "badge badge-subtle badge-success"
          break;
        case 'Reserved':
          unitAvailabilityClass = "badge badge-subtle badge-warning"
          break;
        case 'Sold':
          unitAvailabilityClass = "badge badge-subtle badge-danger"
          break;
        case 'Unavailable':
          unitAvailabilityClass = "badge badge-subtle badge-danger"
          break;
        default:
          unitAvailabilityClass = "badge badge-subtle badge-dark"
      }

      if (rentalObject && props.unit.availability === 'Sold') unitAvailabilityField = 'Leased'
    }
  }

  let unitParkingField;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitParkingField = <td className="align-top"><span>-</span></td>
  } else {
    if (props.project.showParking) {
      if (editMode) {
        unitParkingField = (
          <td className="align-top">
            <input
              type="number"
              className="form-control"
              value={unitParking}
              onChange={e => setUnitParking(e.target.value)}
              />
          </td>
        )
      } else {
        unitParkingField = (
          <td className="align-top"><span>{props.unit.parking || 0}</span></td>
        )
      }
    }
  }

  let unitBathroomsField;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitBathroomsField = <td className="align-top"><span>-</span></td>
  } else {
    if (props.project.showBathrooms) {
      if (editMode) {
        unitBathroomsField = (
          <td className="align-top">
            <input
              type="number"
              className="form-control"
              value={unitBathrooms}
              onChange={e => setUnitBathrooms(e.target.value)}
              />
          </td>
        )
      } else {
        unitBathroomsField = <td className="align-top"><span>{props.unit.bathrooms || 0}</span></td>
      }
    }
  }

  let unitOrientationField;
  if (!props.forUnitModel && props.unit.unitmodels.length) {
    unitOrientationField = <td className="align-top"><span>-</span></td>
  } else {
    if (props.project.showOrientation) {
      if (editMode) {
        unitOrientationField = (
          <td className="align-top">
            <input
              type="text"
              className="form-control"
              value={unitOrientation}
              onChange={e => setUnitOrientation(e.target.value)}
              />
          </td>
        )
      } else {
        unitOrientationField = <td className="align-top"><span>{props.unit.orientation || ''}</span></td>
      }
    }
  }

  let unitInclusionsField;
  if (props.project.showInclusions) {
    if (!props.forUnitModel && props.unit.unitmodels.length) {
      unitInclusionsField = <td className="align-top"><span>-</span></td>
    } else {
      if (editMode) {
        unitInclusionsField = (
          <td className="align-top">
            <input
              type="text"
              className="form-control"
              value={unitInclusions}
              onChange={e => setUnitInclusions(e.target.value)}
              />
          </td>
        )
      } else {
        unitInclusionsField = <td className="align-top"><span>{props.unit.inclusions || ''}</span></td>
      }
    }
  }

  let unitDeliveryDateField;
  if (props.project.deliveryDates) {
    if (!props.forUnitModel && props.unit.unitmodels.length) {
      unitDeliveryDateField = <td className="align-top"><span>-</span></td>
    } else {
      if (editMode) {
        unitDeliveryDateField = (
          <td className="align-top">
            <DatePicker className="form-control" selected={unitDeliveryDate ? new Date(unitDeliveryDate) : new Date()} onChange={date => setUnitDeliveryDate(date)} />
          </td>
        )
      } else {
        let dDate = props.unit.deliveryDate
        if (dDate) {
          unitDeliveryDateField = <td className="align-top"><span>{Formatter.formatDate(dDate)}</span></td>
        } else {
          unitDeliveryDateField = <td className="align-top"><span></span></td>
        }
      }
    }
  }

  let pdfButton;
  if (isUploadingPdf) {
    pdfButton = (
      <button disabled className={`btn btn-sm btn-icon btn-${props.unit.downloadableAsset ? 'success' : 'secondary'} ml-1 mb-1`} title="Unit PDF" onClick={() => toggleUpload(unitPdfInput)}>
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span className="sr-only">Loading...</span>
      </button>
    )
  } else {
    pdfButton = (
      <button
        className={`btn btn-sm btn-icon btn-${props.unit.downloadableAsset ? 'success' : 'secondary'} ml-1 mb-1`}
        title="Unit PDF"
        onClick={() => setShowPdfModal(true)}
        >
        <i className="fa fa-file-invoice"></i> <span className="sr-only">PDF</span>
      </button>
    )
  }

  let layoutButton;
  const btnClass = (props.unit.layoutGallery.length || props.unit.layoutUrl) ? 'success' : 'secondary'
  if (isUploadingLayout) {
    layoutButton = (
      <button className={`btn btn-sm btn-icon btn-${btnClass} ml-1 mb-1`} title="Unit Layout" onClick={() => toggleUpload(unitLayoutInput)}>
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span className="sr-only">Loading...</span>
      </button>
    )
  } else {
    layoutButton = (
      <button
        className={`btn btn-sm btn-icon btn-${btnClass} ml-1 mb-1`}
        title="Unit Layout"
        onClick={() => setShowLayoutModal(true)}
        >
        <i className="fa fa-vector-square"></i> <span className="sr-only">Layout</span>
      </button>
    )
  }

  let editButtons;
  if (editMode) {
    editButtons = (
      <>
        <button className="btn btn-secondary ml-1" title="Cancel" onClick={() => justExitEditMode()}>
          Cancel
        </button>
        <button className="btn btn-secondary ml-1" title="Save Unit" onClick={() => saveAndExitEditMode()}>
          Save
        </button>
      </>
    )
  } else {
    let conditionalUnitModelButton;
    if (!props.forUnitModel && (props.project.previewMode || props.project.landOnly || props.project.projectType === 'land')) {
      conditionalUnitModelButton = (
        <OverlayTrigger placement='top' overlay={<Tooltip>Add model</Tooltip>}>
          <button className={`btn btn-sm btn-icon btn-secondary ml-1 mb-1`} title="Add model" onClick={() => addUnitModel()}>
            <i className="fa fa-code-branch"></i> <span className="sr-only">Add model</span>
          </button>
        </OverlayTrigger>
      )
    }

    editButtons = (
      <>
        {conditionalUnitModelButton}

        <OverlayTrigger placement='top' overlay={<Tooltip>Finishes</Tooltip>}>
          <button className={`btn btn-sm btn-icon btn-secondary ml-1 mb-1`} title="Finishes" onClick={() => setShowFinishesModal(true)}>
            <span>{(props.unit.finishes || []).length}</span>
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Gallery</Tooltip>}>
          <button className={`btn btn-sm btn-icon btn-secondary ml-1 mb-1`} title="Gallery" onClick={() => setShowGalleryModal(true)}>
            <span>{(props.unit.images || []).length}</span>
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>3D Visit</Tooltip>}>
          <button className={`btn btn-sm btn-icon btn-${props.unit.embedCode ? 'success' : 'secondary'} ml-1 mb-1`} title="3D Visit Embed Code" onClick={() => setShowUnitModal(true)}>
            <i className="fa fa-file-code"></i> <span className="sr-only">3D Visit</span>
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>PDF</Tooltip>}>
          {pdfButton}
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Unit Layout</Tooltip>}>
          {layoutButton}
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
          <button className="btn btn-sm btn-icon btn-secondary ml-1 mb-1" title="Edit Unit" onClick={() => enableEditMode()}>
            <i className="fa fa-pencil-alt"></i> <span className="sr-only">Edit</span>
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
          <button className="btn btn-sm btn-icon btn-secondary ml-1 mb-1" title="Delete Unit" onClick={() => deleteUnit()}>
            <i className="far fa-trash-alt"></i> <span className="sr-only">Remove</span>
          </button>
        </OverlayTrigger>
      </>
    )
  }

  async function saveAndExitEditMode() {
    const unitWasSold = props.unit.availability === 'Sold' || props.unit.availability === 'Leased'
    const unitIsSold = unitAvailability === 'Sold' || unitAvailability === 'Leased'

    let updatedUnit = Object.assign(props.unit)
    updatedUnit.name = unitName
    updatedUnit.type = unitModel
    updatedUnit.squareFeet = unitSquareFeet
    updatedUnit.parking = unitParking
    updatedUnit.orientation = unitOrientation
    updatedUnit.inclusions = unitInclusions
    updatedUnit.deliveryDate = unitDeliveryDate
    updatedUnit.bathrooms = unitBathrooms
    updatedUnit.bedrooms = unitBedrooms
    updatedUnit.price = unitPrice
    updatedUnit.unitPriceTBD = unitPriceTBD
    updatedUnit.availability = unitAvailability
    updatedUnit.embedCode = unitEmbedCode
    updatedUnit.description = unitDescription

    if (clonedImages) {
      updatedUnit.images = [...(updatedUnit.images || []), ...(updatedUnit.clonedImages || []), ...clonedImages]
    }

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: updatedUnit,
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })

    // if the unit availability changed to 'Leased'/'Sold', trigger a sale event
    if (!unitWasSold && unitIsSold) {
      eventService.logEvent('unit', props.unit, 'sale', 'manual sale')
    }

    await props.fetchFloors()

    setEditMode(false)
    setShowUnitModal(false)
  }

  async function justExitEditMode() {
    await props.fetchFloors()

    setEditMode(false)
    setShowUnitModal(false)
  }

  async function deleteUnit() {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })

    props.fetchFloors()
  }

  function toggleUpload(ref) {
    ref.current.click()
  }

  async function handleFileUpload(file, scope) {
    if (scope === 'pdf') {
      setIsUploadingPdf(true)
    } else if (scope === 'image') {
      setIsUploadingLayout(true)
    }

    const data = new FormData()
    data.append('vfile', file)

    await axios({
      method: 'POST',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: data,
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/${scope}`
    })

    props.fetchFloors()

    if (scope === 'pdf') {
      setIsUploadingPdf(false)
    } else if (scope === 'image') {
      setIsUploadingLayout(false)
    }
  }

  async function deleteGalleryImage(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/gallery`
    })
    props.fetchFloors()
  }

  async function deleteGalleryFinish(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/finishes`
    })
    props.fetchFloors()
  }

  async function deleteUnitLayout(e, target) {
    e.preventDefault()

    // Temporarily delete layout url on image deletion (legacy)
    await axios({
      method: 'PATCH',
      headers: { 'content-type': 'application/json', 'Authorization': cookies.get('planpoint') },
      data: { layoutUrl: '' },
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })

    // And delete the actual item from the gallery
    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/layouts`
    })

    props.fetchFloors()
  }

  async function deleteUnitPdf(e) {
    e.preventDefault()

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: { downloadableAsset: '' },
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })
    props.fetchFloors()
  }

  async function copyImagesFromProject(e) {
    e.preventDefault()

    let updatedUnit = Object.assign(props.unit)
    updatedUnit.images = [...(updatedUnit.images || []), ...selectedProjectImages]

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: updatedUnit,
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })

    setSelectedProjectImages([])
    await props.fetchFloors()
  }
  
  async function copyFinishesFromProject(e) {
    e.preventDefault()

    let updatedUnit = Object.assign(props.unit)
    updatedUnit.finishes = [...(updatedUnit.finishes || []), ...selectedProjectFinishes]

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: updatedUnit,
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })

    setSelectedProjectFinishes([])
    await props.fetchFloors()
  }

  async function copyLayoutFromProject(e, layout) {
    e.preventDefault()

    let updatedUnit = Object.assign(props.unit)
    updatedUnit.layoutGallery = [...(updatedUnit.layoutGallery || []), layout]

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: updatedUnit,
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })

    await props.fetchFloors()
  }

  async function copyPdfFromProject(e, pdf) {
    e.preventDefault()

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: { downloadableAsset: pdf },
      url: `${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}`
    })

    await props.fetchFloors()
  }

  function selectProjectImage(evt, image) {
    evt.preventDefault();

    if (selectedProjectImages.indexOf(image) === -1) {
      setSelectedProjectImages([...selectedProjectImages, image])
    } else {
      setSelectedProjectImages(selectedProjectImages.filter(img => img !== image))
    }
  }

  function selectProjectFinish(evt, image) {
    evt.preventDefault();

    if (selectedProjectFinishes.indexOf(image) === -1) {
      setSelectedProjectFinishes([...selectedProjectFinishes, image])
    } else {
      setSelectedProjectFinishes(selectedProjectFinishes.filter(img => img !== image))
    }
  }

  const allImages = [...(props.unit.images || []), ...(props.unit.clonedImages || [])]
  let unitImages = allImages.map((image, i) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
      <div className="card card-figure">
        <figure className="figure">
          <div className="figure-img">
            <img className="img-fluid" src={image} alt="Card cap" />
            <div className="figure-tools">
              <a href={image} target="_blank" rel="noopener noreferrer" className="tile tile-circle tile-sm mr-auto">
                <span className="oi oi-data-transfer-download"></span>
              </a>
            </div>
            <div className="figure-action">
              <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteGalleryImage(e, image)}>
                Delete
              </a>
            </div>
          </div>
        </figure>
      </div>
    </div>
  ))

  const allFinishes = [...(props.unit.finishes || []), ...(props.unit.clonedFinishes || [])]
  let unitFinishes = allFinishes.map((image, i) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
      <div className="card card-figure">
        <figure className="figure">
          <div className="figure-img">
            <img className="img-fluid" src={image} alt="Card cap" />
            <div className="figure-tools">
              <a href={image} target="_blank" rel="noopener noreferrer" className="tile tile-circle tile-sm mr-auto">
                <span className="oi oi-data-transfer-download"></span>
              </a>
            </div>
            <div className="figure-action">
              <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteGalleryFinish(e, image)}>
                Delete
              </a>
            </div>
          </div>
        </figure>
      </div>
    </div>
  ))

  let unitLayouts;
  if (props.unit.layoutGallery.length) {
    unitLayouts = props.unit.layoutGallery.map((image, i) => (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
        <div className="card card-figure">
          <figure className="figure">
            <div className="figure-img">
              <img className="img-fluid" src={image} alt="Card cap" />
              <div className="figure-tools">
                <a href={image} target="_blank" rel="noopener noreferrer" className="tile tile-circle tile-sm mr-auto">
                  <span className="oi oi-data-transfer-download"></span>
                </a>
              </div>
              <div className="figure-action">
                <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteUnitLayout(e, image)}>
                  Delete
                </a>
              </div>
            </div>
          </figure>
        </div>
      </div>
    ))
  } else if (props.unit.layoutUrl) {
    unitLayouts = (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
        <div className="card card-figure">
          <figure className="figure">
            <div className="figure-img">
              <img className="img-fluid" src={props.unit.layoutUrl} alt="Card cap" />
              <div className="figure-tools">
                <a href={props.unit.layoutUrl} target="_blank" rel="noopener noreferrer" className="tile tile-circle tile-sm mr-auto">
                  <span className="oi oi-data-transfer-download"></span>
                </a>
              </div>
              <div className="figure-action">
                <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteUnitLayout(e)}>
                  Delete
                </a>
              </div>
            </div>
          </figure>
        </div>
      </div>
    )
  }

  let unitPdfs;
  if (props.unit.downloadableAsset) {
    let fileSegments = props.unit.downloadableAsset.split('/').pop().split('.')
    let fileExtension = fileSegments.pop()
    let fileNameSegments = fileSegments.join('.').split('-')
    fileNameSegments.pop() // drop hash
    let fileNamePreview = fileNameSegments.join('-')

    unitPdfs = (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
        <div className="card card-figure">
          <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
          <a href={props.unit.downloadableAsset} target="_blank" rel="noopener noreferrer" className="btn btn-block btn-sm btn-outline-primary mt-2">
            Download
          </a>
          <a href="/" className="btn btn-block btn-sm btn-danger mt-1" onClick={e => deleteUnitPdf(e)}>
            Delete
          </a>
        </div>
      </div>
    )
  }

  let subUnitRows;
  if (props.unit.unitmodels) {
    subUnitRows = props.unit.unitmodels.map(um => (
      <UnitRow
        project={props.project}
        floors={props.floors}
        floor={props.floor}
        unit={um}
        forUnitModel
        key={`unit-${props.unit._id}-${um._id}`}
        fetchFloors={() => props.fetchFloors()}
        fetchProject={() => props.fetchProject()}
        selectedUnits={props.selectedUnits}
        setSelectedUnits={u => props.setSelectedUnits(u)}
        />
    ));
  }

  let projectImages = props.project.images.map((image, i) => {
    let fileSegments = image.split('/').pop().split('.')
    let fileExtension = fileSegments.pop()
    let fileNameSegments = fileSegments.join('.').split('-')
    fileNameSegments.pop() // drop hash
    let fileNamePreview = fileNameSegments.join('-')
    if (fileNamePreview.length > 10) fileNamePreview = `${fileNamePreview.slice(0, 8)}...`

    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
        <div className={`card card-figure ${selectedProjectImages.includes(image) ? 'border border-primary' : ''}`}>
          <figure className="figure">
            <div className="figure-img">
              <img className="img-fluid" src={image} alt="Card cap" />
              <div className="figure-action">
                <a href="/" className="btn btn-block btn-sm btn-primary" onClick={e => selectProjectImage(e, image)}>
                  Select
                </a>
              </div>
            </div>
          </figure>
          <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
        </div>
      </div>
    )
  })

  let projectFinishes = props.project.finishes.map((image, i) => {
    let fileSegments = image.split('/').pop().split('.')
    let fileExtension = fileSegments.pop()
    let fileNameSegments = fileSegments.join('.').split('-')
    fileNameSegments.pop() // drop hash
    let fileNamePreview = fileNameSegments.join('-')
    if (fileNamePreview.length > 10) fileNamePreview = `${fileNamePreview.slice(0, 8)}...`

    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
        <div className={`card card-figure ${selectedProjectFinishes.includes(image) ? 'border border-primary' : ''}`}>
          <figure className="figure">
            <div className="figure-img">
              <img className="img-fluid" src={image} alt="Card cap" />
              <div className="figure-action">
                <a href="/" className="btn btn-block btn-sm btn-primary" onClick={e => selectProjectFinish(e, image)}>
                  Select
                </a>
              </div>
            </div>
          </figure>
          <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
        </div>
      </div>
    )
  })

  let projectLayouts = props.project.layouts.map((layout, i) => {
    let fileSegments = layout.split('/').pop().split('.')
    let fileExtension = fileSegments.pop()
    let fileNameSegments = fileSegments.join('.').split('-')
    fileNameSegments.pop() // drop hash
    let fileNamePreview = fileNameSegments.join('-')
    if (fileNamePreview.length > 10) fileNamePreview = `${fileNamePreview.slice(0, 8)}...`

    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
        <div className="card card-figure">
          <figure className="figure">
            <div className="figure-img">
              <img className="img-fluid" src={layout} alt="Card cap" />
              <div className="figure-action">
                <a href="/" className="btn btn-block btn-sm btn-primary" onClick={e => copyLayoutFromProject(e, layout)}>
                  Select
                </a>
              </div>
            </div>
          </figure>
          <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
        </div>
      </div>
    )
  })

  let projectPdfs = props.project.downloadableAssets.map((pdf, i) => {
    let fileSegments = pdf.split('/').pop().split('.')
    let fileExtension = fileSegments.pop()
    let fileNameSegments = fileSegments.join('.').split('-')
    fileNameSegments.pop() // drop hash
    let fileNamePreview = fileNameSegments.join('-')

    return (
      <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
        <div className="card card-figure">
          <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
          <a href="/" className="btn btn-block btn-sm btn-primary mt-2" onClick={e => copyPdfFromProject(e, pdf)}>
            Select
          </a>
        </div>
      </div>
    )
  })

  function saveAndCloseFinishesModal() {
    setShowFinishesModal(false)
    setFiles([])
    props.fetchFloors()
    props.fetchProject()
  }

  function saveAndCloseGalleryModal() {
    setShowGalleryModal(false)
    setFiles([])
    props.fetchFloors()
    props.fetchProject()
  }

  function saveAndCloseLayoutModal() {
    setShowLayoutModal(false)
    setLayouts([])
    props.fetchFloors()
    props.fetchProject()
  }

  function saveAndClosePdfModal() {
    setShowPdfModal(false)
    setPdfs([])
    props.fetchFloors()
    props.fetchProject()
  }

  let conditionalBulkSelectCTA;
  if (selectedProjectImages.length) {
    conditionalBulkSelectCTA = (
      <Button variant="primary"  onClick={e => copyImagesFromProject(e)}>
        Select {selectedProjectImages.length} {selectedProjectImages.length === 1 ? 'image' : 'images'}
      </Button>
    )
  }

  let conditionalBulkSelectFinishesCTA;
  if (selectedProjectFinishes.length) {
    conditionalBulkSelectFinishesCTA = (
      <Button variant="primary"  onClick={e => copyFinishesFromProject(e)}>
        Select {selectedProjectFinishes.length} {selectedProjectFinishes.length === 1 ? 'finish' : 'finishes'}
      </Button>
    )
  }

  let unitDescriptionRow;
  if (editMode && props.project.showUnitDescription) {
    unitDescriptionRow = (
      <tr>
        <td colspan='11' className="border-top-0">
          <div className="form-group">
            <textarea
              rows="3"
              className="form-control"
              placeholder='Add unit description'
              value={unitDescription}
              onChange={e => setUnitDescription(e.target.value)}
            />
          </div>
        </td>
      </tr>
    )
  }

  function toggleSelected(evt) {
    evt.preventDefault()

    if (selected) {
      props.setSelectedUnits((props.selectedUnits || []).filter(u => u !== props.unit))
    } else {
      props.setSelectedUnits([...(props.selectedUnits || []), props.unit])
    }
  }

  return (
    <>
      <tr className={props.forUnitModel ? 'bg-light' : ''}>
        <td>
          <a
            href='/'
            onClick={e => toggleSelected(e)}
            className={`btn btn-icon btn-${selected ? 'success' : 'light'}`}>
            {selected ? <CheckSquare size='16' /> : <Square size='16' />}
          </a>
        </td>

        <td>{unitNameField}</td>

        <td className="align-top">{unitModelField}</td>

        <td className="align-top">{unitSquareFeetField}</td>

        <td className="align-top">{unitBedroomsField}</td>

        <td className="align-top">{unitPriceField}</td>

        <td className="align-top">
          <span className={unitAvailabilityClass}>
            {unitAvailabilityField}
          </span>
        </td>

        {unitParkingField}
        {unitBathroomsField}
        {unitOrientationField}
        {unitInclusionsField}
        {unitDeliveryDateField}

        <td className="align-top text-right">
          <Modal show={showUnitModal} onHide={() => setShowUnitModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Embed Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-muted">Add your embed code below:</p>
              <textarea
                className="form-control"
                value={unitEmbedCode}
                onChange={e => setUnitEmbedCode(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowUnitModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={() => saveAndExitEditMode(false)}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showGalleryModal} onHide={() => saveAndCloseGalleryModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Unit Gallery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="section-block">
                <h2 className="section-title">Upload a new image</h2>
                <p className="text-muted">Add a new image to this unit.</p>
              </div>

              <div className="row">
                {unitImages}
              </div>

              <FilePond
                files={files}
                allowRevert={false}
                onupdatefiles={setFiles}
                allowMultiple={true}
                maxParallelUploads={1}
                maxFiles={10 - (props.unit.images || []).length}
                server={`${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/gallery`}
                name="unitimages"
                labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
              />

              <hr className="my-3" />

              <div className="section-block">
                <h2 className="section-title">Or pick from the gallery:</h2>
                <p className="text-muted">Select one of your previously uploaded images from this project.</p>
              </div>

              <div className="row">
                {projectImages}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {conditionalBulkSelectCTA}
              <Button variant="secondary" onClick={() => saveAndCloseGalleryModal()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showFinishesModal} onHide={() => saveAndCloseFinishesModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Unit Finishes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="section-block">
                <h2 className="section-title">Upload a new finish</h2>
                <p className="text-muted">Add a new finish to this unit.</p>
              </div>

              <div className="row">
                {unitFinishes}
              </div>

              <FilePond
                files={files}
                allowRevert={false}
                onupdatefiles={setFiles}
                allowMultiple={true}
                maxParallelUploads={1}
                maxFiles={10 - (props.unit.finishes || []).length}
                server={`${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/finishes`}
                name="unitfinishes"
                labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
              />

              <hr className="my-3" />

              <div className="section-block">
                <h2 className="section-title">Or pick from the gallery:</h2>
                <p className="text-muted">Select one of your previously uploaded finishes from this project.</p>
              </div>

              <div className="row">
                {projectFinishes}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {conditionalBulkSelectFinishesCTA}
              <Button variant="secondary" onClick={() => saveAndCloseFinishesModal()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showLayoutModal} onHide={() => saveAndCloseLayoutModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Unit Layout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="section-block">
                <h2 className="section-title">Upload a new layout</h2>
                <p className="text-muted">Add a new layout to this unit.</p>
              </div>

              <div className="row">
                {unitLayouts}
              </div>

              <FilePond
                files={layouts}
                allowRevert={false}
                onupdatefiles={setLayouts}
                allowMultiple={true}
                maxParallelUploads={1}
                maxFiles={10}
                server={`${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/layouts`}
                name="unitlayouts"
                labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
              />

              <hr className="my-3" />

              <div className="section-block">
                <h2 className="section-title">Or pick from the gallery:</h2>
                <p className="text-muted">Select one of your previously uploaded layouts from this project.</p>
              </div>

              <div className="row">
                {projectLayouts}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => saveAndCloseLayoutModal()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showPdfModal} onHide={() => saveAndClosePdfModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Unit PDF</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="section-block">
                <h2 className="section-title">Upload a new PDF</h2>
                <p className="text-muted">Add a new PDF to this unit.</p>
              </div>

              <div className="row">
                {unitPdfs}
              </div>

              <FilePond
                files={pdfs}
                allowRevert={false}
                onupdatefiles={setPdfs}
                allowMultiple={false}
                maxParallelUploads={1}
                maxFiles={1}
                server={`${Env.url}/api/v1/${props.forUnitModel ? 'unitmodels' : 'units'}/${props.unit._id}/pdfs`}
                name={props.forUnitModel ? 'unitmodelpdfs' : 'unitpdfs'}
                labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
              />

              <hr className="my-3" />

              <div className="section-block">
                <h2 className="section-title">Or pick from the gallery:</h2>
                <p className="text-muted">Select one of your previously uploaded PDFs from this project.</p>
              </div>

              <div className="row">
                {projectPdfs}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => saveAndClosePdfModal()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {editButtons}

          <div className="d-none">
            <input type="file" ref={unitLayoutInput} onChange={e => handleFileUpload(e.target.files[0], 'image')}/>
            <input type="file" ref={unitPdfInput} onChange={e => handleFileUpload(e.target.files[0], 'pdf')}/>
          </div>
        </td>
      </tr>

      {unitDescriptionRow}

      {subUnitRows}
    </>
  )
}
