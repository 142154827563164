import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import Header from './components/Header';
import Aside from './components/Aside';
import BreadcrumbMenu from './components/BreadcrumbMenu';

export default function Embed(props) {
  const [project, setProject] = useState()
  const [embedLang, setEmbedLang] = useState('en');
  const [namespace, setNamespace] = useState();
  const [hostName, setHostName] = useState();
  const [projectLang, setProjectLang] = useState();

  const cookies = new Cookies();

  const { projectId } = useParams()

  useEffect(() => {
    async function fetchProject() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      };

      const response = await axios(options)
      setProject(response.data)
      setNamespace(response.data.namespace)
      setHostName(response.data.hostName)
      setProjectLang(response.data.projectLang)
    }
    if (!project) fetchProject()
  })

  function copyProjectId(e, projectId) {
    e.preventDefault()

    const el = document.createElement('textarea');

    el.value = [
      `<link href="https://live.planpoint.io/static/css/main.css" rel="stylesheet" />`,
      `<div id="planpoint-root" data-token="${cookies.get('planpoint')}" data-project="${projectId}" data-lang="${embedLang}"/>`,
      `<script src="https://live.planpoint.io/static/js/planpoint.js"></script>`
    ].join("\n");

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // reset defaults
    setEmbedLang('en')
  }

  function copyProjectLink(e, project) {
    e.preventDefault()

    const el = document.createElement('textarea');

    el.value = `https://live.planpoint.io/${project.namespace}/${project.hostName}/${project.projectLang}`;

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // reset defaults
    setEmbedLang('en')
  }

  async function saveHostUrlInfo() {
    project.hostName = hostName
    project.namespace = namespace
    project.projectLang = projectLang

    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: project,
      url: `${Env.url}/api/v1/projects/${projectId}`
    })

    setProject(response.data)
  }

  let conditionalSaveButton;
  if (!project || project.namespace !== namespace || project.hostName !== hostName || project.projectLang !== projectLang) {
    conditionalSaveButton = (
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginTop: '29px' }}
        onClick={e => saveHostUrlInfo(e)}
        >
        Save changes
      </button>
    )
  }

  let hostingUrl;
  if (project && project.namespace && project.hostName && project.projectLang) {
    hostingUrl = (<a href={`/${project.namespace}/${project.hostName}/${project.projectLang}`} target="_blank" rel="noopener noreferrer">live.planpoint.io/{project.namespace}/{project.hostName}/{project.projectLang}</a>)
  } else {
    hostingUrl = <span>not set</span>
  }

  return (
    <div className="app planpoint-embed">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='embed' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">Embed {(project || {}).name}</h1>
              </header>

              <div className="page-section">
                <div id="base-style" className="card">
                  <div className="card-body">
                    <form>
                      <fieldset>
                        <h5>Embed code options</h5>
                        <p className="text-muted">Customize and preview your embed code</p>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="sel1">Language</label>
                              <select className="custom-select" id="sel1" required="" onChange={e => setEmbedLang(e.target.value)}>
                                <option value=""> Choose... </option>
                                <option value='en'>English</option>
                                <option value='fr'>French</option>
                                <option value='es'>Spanish</option>
                                <option value='de'>German</option>
                                <option value='zh'>Chinese</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="sel2">Theme</label> <select className="custom-select" id="sel2" required="">
                                <option value=""> Choose... </option>
                                <option defaultValue>Default</option>
                                <option disabled>Light</option>
                                <option disabled>Dark</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <hr />

                      <div className="form-actions">
                        <p className="text-muted">
                          <small>Paste this code wherever you want your Planpoint viewer to appear. For full guides, please visit our <a href="https://planpoint.helpscoutdocs.com">Knowledge Base</a>.</small>
                        </p>
                        <a className="btn btn-outline-primary ml-auto" href={`/preview?project=${projectId}&lang=${embedLang}&token=${cookies.get('planpoint')}`} target="_blank" rel="noopener noreferrer">
                          Preview
                        </a>
                        <button type="submit" className="btn btn-primary ml-2" onClick={e => copyProjectId(e, projectId)}>
                          Copy code to clipboard
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <fieldset>
                      <h5>Hosted URL options</h5>
                      <p className="text-muted">Host your plan viewer on a Planpoint URL for totally autonomous sharing.</p>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Namespace</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder='your-company'
                              value={namespace}
                              onChange={e => setNamespace(e.target.value)}
                              required=""
                              />
                            <small className="form-text text-muted">Only letters, numbers and dashes allowed</small>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Project name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={((project || {}).name || '').toLowerCase().replace(' ', '-')}
                              value={hostName}
                              onChange={e => setHostName(e.target.value)}
                              required=""
                              />
                            <small className="form-text text-muted">Only letters, numbers and dashes allowed</small>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Project language</label>
                            <select className="custom-select" value={projectLang} onChange={e => setProjectLang(e.target.value)}>
                              <option value=""> Choose... </option>
                              <option value='en'>English</option>
                              <option value='fr'>French</option>
                              <option value='es'>Spanish</option>
                              <option value='de'>German</option>
                              <option value='zh'>Chinese</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          {conditionalSaveButton}
                        </div>
                      </div>
                    </fieldset>

                    <hr />

                    <div className="form-actions">
                      <p className="text-muted">
                        <small>Your planpoint hosting URL is: {hostingUrl}</small>
                      </p>
                      <a className="btn btn-outline-primary ml-auto" href={`https://live.planpoint.io/${(project || {}).namespace}/${(project || {}).hostName}/${(project || {}).projectLang}`} target="_blank" rel="noopener noreferrer">
                        Open
                      </a>
                      <button type="submit" className="btn btn-primary ml-2" onClick={e => copyProjectLink(e, (project || {}))}>
                        Copy link to clipboard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
