import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import Header from './components/Header';
import Aside from './components/Aside';
import BreadcrumbMenu from './components/BreadcrumbMenu'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

export default function Forms(props) {
  const [project, setProject] = useState({})
  const [showNewLeadModal, setShowNewLeadModal] = useState(false)
  const [newLeadFirstName, setNewLeadFirstName] = useState()
  const [newLeadLastName, setNewLeadLastName] = useState()
  const [newLeadEmail, setNewLeadEmail] = useState()
  const [newLeadPhone, setNewLeadPhone] = useState()
  const [newLeadMessage, setNewLeadMessage] = useState()
  const [newLeadUnit, setNewLeadUnit] = useState()

  const cookies = new Cookies();

  const { projectId } = useParams()

  async function fetchProject() {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/projects/${projectId}`
    };

    const response = await axios(options)
    setProject(response.data)
  }

  useEffect(() => {
    if (!project._id) fetchProject()
  })

  async function addLead() {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        leads: [...project.leads, {
          firstName: newLeadFirstName,
          lastName: newLeadLastName,
          email: newLeadEmail,
          phone: newLeadPhone,
          message: newLeadMessage,
          unit: newLeadUnit
        }]
      },
      url: `${Env.url}/api/v1/projects/${projectId}`
    })

    setProject(response.data)

    setShowNewLeadModal(false)
    setNewLeadFirstName()
    setNewLeadLastName()
    setNewLeadEmail()
    setNewLeadPhone()
    setNewLeadMessage()
    setNewLeadUnit()
  }

  async function deleteLead(lead) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        leads: project.leads.filter(l => l !== lead)
      },
      url: `${Env.url}/api/v1/projects/${projectId}`
    })

    setProject(response.data)
  }

  function downloadLeads() {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "First name,Last name,Email,Phone,Unit,Message\r\n"
    project.leads.forEach(function(lead) {
      let row = `${lead.firstName},${lead.lastName},${lead.email},${lead.phone},${lead.unit},${lead.message}`
      csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${project.name}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  let leadRows = (project.leads || []).map((l, i) => (
    <tr key={`lead-${i}`}>
      <td>{l.firstName}</td>
      <td>{l.lastName}</td>
      <td><a href={`mailto:${l.email}`}>{l.email}</a></td>
      <td><a href={`tel:${l.phone}`}>{l.phone}</a></td>
      <td>{l.unit}</td>
      <td>{l.message}</td>
      <td>
        <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
          <button className="btn btn-sm btn-icon btn-secondary ml-1" title="Delete Lead" onClick={() => deleteLead(l)}>
            <i className="far fa-trash-alt"></i> <span className="sr-only">Remove</span>
          </button>
        </OverlayTrigger>
      </td>
    </tr>
  ))

  let unitOptions;
  (project.floors || []).forEach((f, i) => {
    if (f.units) {
      unitOptions.push(
        f.units.sort((a, b) => (a.name > b.name) ? 1 : -1).map((u, j) => (
          <option key={`unit-${j}-{i}`} value={u.name}>{u.name}</option>
        ))
      )
    }
  })

  return (
    <div className="app planpoint-forms">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='forms' projectId={projectId}/>
                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">Forms</h1>
              </header>

              <div className="page-section">
                <div className="card card-fluid">
                  <div className="card-header border-0">
                    <div className="d-flex align-items-center">
                      <span className="mr-auto">Leads</span>
                      <button className="btn btn-outline-primary" onClick={() => downloadLeads()}>
                        CSV export
                      </button>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table">

                      <thead>
                        <tr>
                          <th>First name</th>
                          <th>Last name</th>
                          <th>Email</th>
                          <th>Telephone</th>
                          <th>Unit</th>
                          <th>Message</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {leadRows}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal show={showNewLeadModal} onHide={() => setShowNewLeadModal(false)}>
        <Modal.Header closeButton>
          <h6 className="modal-title inline-editable">
            New Lead
          </h6>
        </Modal.Header>
        <Modal.Body>

          <form onSubmit={() => addLead()}>
            <fieldset>
              <div className="list-group list-group-flush">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First name</label>
                      <input type="text" className="form-control" value={newLeadFirstName} onChange={e => setNewLeadFirstName(e.target.value)}/>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last name</label>
                      <input type="text" className="form-control" value={newLeadLastName} onChange={e => setNewLeadLastName(e.target.value)}/>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="email" className="form-control" value={newLeadEmail} onChange={e => setNewLeadEmail(e.target.value)}/>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Phone number</label>
                      <input type="text" className="form-control" value={newLeadPhone} onChange={e => setNewLeadPhone(e.target.value)}/>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Message</label>
                      <textarea className="form-control" value={newLeadMessage} onChange={e => setNewLeadMessage(e.target.value)}/>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Unit</label>
                      <select className="custom-select" required="" onChange={e => setNewLeadUnit(e.target.value)}>
                        <option value=""> Choose... </option>
                        {unitOptions}
                      </select>
                      <small className="form-text text-muted">The unit this lead is interested in</small>
                    </div>
                  </div>
                </div>

              </div>
            </fieldset>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => addLead()}>
            Create Lead
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
