import React, { useState } from 'react';
import axios from 'axios';

import Env from '../../../Environments';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function ProjectInviteForm(props) {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const username = new URLSearchParams(window.location.search).get('email')

  function handleSubmit(e) {
    e.preventDefault()

    async function signUpUser() {
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        data: {
          username: username,
          password: password
        },
        url: `${Env.url}/api/v1/users`
      };

      try {
        const user = await axios(options);

        if(user.data) {
          props.signInUser(user.data)
        } else {
          setShowWarning(true)
        }
      } catch (e) {
        setShowWarning(true)
      }
    }
    signUpUser()
  }

  return (
    <div className="Auth-col-body">
      <h3>You have been invited!</h3>
      <p>Enter your account details to sign up</p>

      <Form onSubmit={e => handleSubmit(e)} className="Auth-form">
        <Row>
          <Col className="half-input">
            <Form.Group>
              <input
                type="text"
                id="inputName"
                placeholder="Name"
                autoFocus=""
                value={name}
                onChange={e => setName(e.target.value)}
                />
            </Form.Group>
          </Col>
          <Col className="half-input">
            <Form.Group>
              <input
                type="text"
                id="inputLastName"
                placeholder="Last Name"
                autoFocus=""
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <input
                disabled
                type="email"
                id="inputUser"
                style={{ opacity: 0.5 }}
                value={username}
                />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <input
                type="password"
                id="inputPassword"
                value={password}
                onChange={e => setPassword(e.target.value)}
                class={`${showWarning ? 'is-invalid' : ''}`}
                placeholder="Password"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="d-flex Auth-checkbox">
              <Form.Check type="checkbox" required />
              <Form.Label>
                I agree to the <a href="https://www.planpoint.io/terms-of-service">Terms & Conditions</a> and <a href="https://www.planpoint.io/privacy-policy">Privacy Policy</a>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Button type="submit" className="w-100 Auth-form-submit">Sign Up</Button>
      </Form>

      <div className="text-center Auth-form-redirect">
        <span>Already have an account? <a href="/login">Log in here</a></span>
      </div>
    </div>
  )
}
