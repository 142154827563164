import React, { useEffect, useState, useMemo } from 'react';

import Env from '../../../Environments';
import axios from 'axios';
import Cookies from 'universal-cookie';

export default function Invoices(props) {
  const [invoices, setInvoices] = useState([])

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchInvoices() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/users/me/invoices`
      })
  
      setInvoices(response.data.data)
    }

    fetchInvoices()
  }, [cookies])

  let invoiceItems = invoices.map(invoice => {
    let invoiceBadge;
    if (invoice.paid) {
      invoiceBadge = <span className="fa fa-check"></span>
    } else {
      invoiceBadge = <span className="fa fa-clock"></span>
    }

    const formatter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: invoice.currency,
      minimumFractionDigits: 2
    })

    return (
      <div className="list-group-item" key={invoice.number}>
        <div className="list-group-item-figure">
          <a href={invoice.invoice_pdf} download className={`tile tile-circle ${invoice.paid ? 'bg-primary' : 'bg-danger'}`}>
            {invoiceBadge}
          </a>
        </div>
        <div className="list-group-item-body">
          <h4 className="list-group-item-title">
            <a href={invoice.invoice_pdf} download>
              {new Date(invoice.created*1000).toLocaleDateString('en-us', { month: 'short', day: '2-digit', year: 'numeric'})}
            </a>
          </h4>
          <p className="list-group-item-text"> Total: {formatter.format(invoice.total/100)} </p>
        </div>
        <div className="list-group-item-figure">
          <a href={invoice.invoice_pdf} download className="btn btn-sm btn-icon btn-light">
            <i className="oi oi-data-transfer-download"></i>
          </a>
        </div>
      </div>
    )
  })

  return (
    <div className="list-group mb-3">
      {invoiceItems}
    </div>
  )
}
