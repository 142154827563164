import React, { useState, useRef, useLayoutEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Konva from 'konva';

import Env from '../../../Environments';

import loader from '../../../assets/waiting.svg';

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import './InteractiveCanvas.css';

function InteractiveCanvas(props) {
  const [isUploading, setIsUploading] = useState(false);
  const [placeholderWidth, setPlaceholderWidth] = useState(1);
  const [placeholderHeight, setPlaceholderHeight] = useState(1);
  const [zoom, setZoom] = useState(false);

  const inputRef = useRef(null)
  const canvasAreaRef = useRef(null)
  const canvasPlaceholderRef = useRef()

  const cookies = new Cookies();

  const GUIDELINE_OFFSET = 3;

  const opacities = {
    tooltip: 1,
    tooltipShadow: 0.25,
    editable: 1,
    regular: 1,
    background: 0.25
  }

  let stage, shapesLayer, tooltipLayer;

  useLayoutEffect(() => {
    initializeCanvas()
  });

  function zoomIn() {
    if (props.zoomIn) {
      props.zoomIn()
      setZoom(true)
    }
  }

  function zoomOut() {
    if (props.zoomOut) {
      props.zoomOut()
      setZoom(false)
    }
  }

  function initializeCanvas() {
    if (canvasPlaceholderRef.current && canvasPlaceholderRef.current.clientWidth && !canvasPlaceholderRef.current.clientHeight) {
      // wait until the placeholder is available
      setTimeout(function() { initializeCanvas() }, 1000)
    } else if (canvasPlaceholderRef.current) {
      // initialize placeholder
      setPlaceholderWidth(canvasAreaRef.current.clientWidth)
      setPlaceholderHeight(canvasPlaceholderRef.current.clientHeight)

      // initialize konva
      let width = canvasPlaceholderRef.current.clientWidth;
      let height = canvasPlaceholderRef.current.clientHeight;

      const isEditable = props.editableItem !== null

      stage = new Konva.Stage({
        container: 'konva-container',
        width: width,
        height: height,
        editable: isEditable,
        name: 'stage'
      });

      shapesLayer = new Konva.Layer({ editable: isEditable, name: 'layer' });
      tooltipLayer = new Konva.Layer({ editable: isEditable, name: 'layer' });

      let tooltip = new Konva.Label({
        opacity: opacities.tooltip,
        visible: false,
        listening: false,
        name: 'label'
      });

      tooltip.add(
        new Konva.Tag({
          fill: props.accentColor,
          pointerDirection: 'down',
          pointerWidth: 10,
          pointerHeight: 10,
          lineJoin: 'round',
          shadowColor: 'black',
          shadowBlur: 10,
          shadowOffsetX: 10,
          shadowOffsetY: 10,
          shadowOpacity: opacities.tooltipShadow,
          name: 'tag'
        })
      );

      tooltip.add(
        new Konva.Text({
          text: '',
          fontFamily: 'Source Sans Pro',
          fontSize: 16,
          padding: 5,
          fill: 'white',
          name: 'tag'
        })
      );

      tooltipLayer.add(tooltip);

      let areas = getData();

      // draw areas
      for (let key in areas) {
        let area = areas[key];
        let points = area.points;
        const areaEditable = area.editable;

        let shape = new Konva.Line({
          stroke: 'white',
          strokeWidth: 2,
          points: points,
          fill: area.color,
          opacity: areaEditable ? opacities.editable : (props.editableItem ? opacities.background : 0),
          closed: true,
          editable: areaEditable,
          key: key,
          perfectDrawEnabled: false,
          name: 'line'
        });

        let circles = initializeAnchors(points, area, shape);

        let group = new Konva.Group({
          name: 'group',
          draggable: areaEditable,
          editable: areaEditable
        })

        shapesLayer.add(group);
        group.add(shape);

        for (let c of circles) { group.add(c) }

        if (area.points.length) {
          // eslint-disable-next-line no-loop-func
          group.on('dragstart', function() {
            this.moveToTop();
            shapesLayer.draw();
          });

          // eslint-disable-next-line no-loop-func
          group.on('dragend', function() {
            // find this group's line
            let line;
            let circles = [];
            for (let child of this.getChildren()) {
              if (child.attrs.name === 'line') {
                line = child
              } else {
                circles.push(child)
              }
            }

            let points = line.points();

            let newX = this.x();
            let newY = this.y();

            let newPoints = [];
            let circleIdx = 0;
            for (let i = 0; i < points.length; i++) {
              if (i%2) {
                newPoints.push(points[i] + newY);
                circles[circleIdx++].y(points[i] + newY)
              } else {
                newPoints.push(points[i] + newX);
                circles[circleIdx].x(points[i] + newX)
              }
            }

            group.absolutePosition({x: 0, y: 0});
            line.points(newPoints);

            shapesLayer.draw();
          });
        }
      }

      stage.add(shapesLayer);
      stage.add(tooltipLayer);

      stage.on('mouseover', function(evt) {
        let shape = evt.target;

        if (shape && !shape.attrs.editable) {
          shape.opacity(props.editableItem ? opacities.background : opacities.regular);
          shapesLayer.draw();
        }
      });

      stage.on('mouseout', function(evt) {
        let shape = evt.target;
        if (shape && !shape.attrs.editable) {
          shape.opacity(props.editableItem ? opacities.background : 0);
          shapesLayer.draw();
          tooltip.hide();
          tooltipLayer.draw();
        }
      });

      stage.on('mousemove', function(evt) {
        let shape = evt.target;
        if (shape) {
          let mousePos = stage.getPointerPosition();
          let x = mousePos.x;
          let y = mousePos.y - 5;
          updateTooltip(tooltip, x, y, shape.attrs.key);
          tooltipLayer.batchDraw();
        }
      });

      stage.on('click', function(evt) {
        if (props.editableItem) {
          let item = props.items.filter(f => f._id === props.editableItem)[0]

          if (!item.path || item.path === '' || item.path === '[]') {
            let mousePos = stage.getPointerPosition();
            addPointToPath(shapesLayer, mousePos.x, mousePos.y);
          }
        }
      })
    }
  }

  function initializeAnchors(points, area, line) {
    // draw anchors
    let x, y;
    let circles = [];

    for (let i = 0; i < points.length; i++) {
      if (i%2) {
        y = points[i]
      } else {
        x = points[i]
      }

      if (x && y) {
        circles.push(drawCircle(x, y, area.editable))

        x = undefined
        y = undefined
      }
    }

    return circles;
  }

  function drawCircle(x, y, editable) {
    let circle = new Konva.Circle({
      name: 'circle',
      x: x,
      y: y,
      opacity: editable ? opacities.editable : (props.editableItem ? opacities.background : 0),
      radius: 3,
      draggable: editable,
      fill: props.accentColor || 'rgba(15, 33, 49, 0.65)',
      editable: editable,
      stroke: 'white',
      strokeWidth: 1
    })

    circle.on('dragstart', function() {
      this.moveToTop();
      shapesLayer.draw();
      this.attrs.oldX = this.x()
      this.attrs.oldY = this.y()
    });

    // were can we snap our objects?
    function getLineGuideStops(skipShape) {
      // we can snap to stage borders and the center of the stage
      let vertical = [0, stage.width() / 2, stage.width()];
      let horizontal = [0, stage.height() / 2, stage.height()];

      // and we snap over edges and center of each object on the canvas
      stage.find('.circle').forEach(guideItem => {
        if (guideItem === skipShape) {
          return;
        }
        let box = guideItem.getClientRect();
        // and we can snap to all edges of shapes
        vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
        horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
      });
      return {
        vertical: vertical.flat(),
        horizontal: horizontal.flat()
      };
    }

    // what points of the object will trigger to snapping?
    // it can be just center of the object
    // but we will enable all edges and center
    function getObjectSnappingEdges(node) {
      let box = node.getClientRect();
      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(node.x() - box.x),
            snap: 'start'
          },
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(node.x() - box.x - box.width / 2),
            snap: 'center'
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(node.x() - box.x - box.width),
            snap: 'end'
          }
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(node.y() - box.y),
            snap: 'start'
          },
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(node.y() - box.y - box.height / 2),
            snap: 'center'
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(node.y() - box.y - box.height),
            snap: 'end'
          }
        ]
      };
    }

    // find all snapping possibilities
    function getGuides(lineGuideStops, itemBounds) {
      let resultV = [];
      let resultH = [];

      lineGuideStops.vertical.forEach(lineGuide => {
        itemBounds.vertical.forEach(itemBound => {
          let diff = Math.abs(lineGuide - itemBound.guide);
          // if the distance between guild line and object snap point is close we can consider this for snapping
          if (diff < GUIDELINE_OFFSET) {
            resultV.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset
            });
          }
        });
      });

      lineGuideStops.horizontal.forEach(lineGuide => {
        itemBounds.horizontal.forEach(itemBound => {
          let diff = Math.abs(lineGuide - itemBound.guide);
          if (diff < GUIDELINE_OFFSET) {
            resultH.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset
            });
          }
        });
      });

      let guides = [];

      // find closest snap
      let minV = resultV.sort((a, b) => a.diff - b.diff)[0];
      let minH = resultH.sort((a, b) => a.diff - b.diff)[0];
      if (minV) {
        guides.push({
          lineGuide: minV.lineGuide,
          offset: minV.offset,
          orientation: 'V',
          snap: minV.snap
        });
      }
      if (minH) {
        guides.push({
          lineGuide: minH.lineGuide,
          offset: minH.offset,
          orientation: 'H',
          snap: minH.snap
        });
      }
      return guides;
    }

    function drawGuides(guides) {
      guides.forEach(lg => {
        if (lg.orientation === 'H') {
          let guideLine = new Konva.Line({
            points: [-6000, lg.lineGuide, 6000, lg.lineGuide],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6]
          });
          shapesLayer.add(guideLine);
          shapesLayer.batchDraw();
        } else if (lg.orientation === 'V') {
          let guideLine = new Konva.Line({
            points: [lg.lineGuide, -6000, lg.lineGuide, 6000],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6]
          });
          shapesLayer.add(guideLine);
          shapesLayer.batchDraw();
        }
      });
    }

    circle.on('dragmove', function(e) {
      // clear all previous lines on the screen
      shapesLayer.find('.guid-line').destroy();

      // find possible snapping lines
      let lineGuideStops = getLineGuideStops(e.target);
      // find snapping points of current object
      let itemBounds = getObjectSnappingEdges(e.target);

      // now find where can we snap current object
      let guides = getGuides(lineGuideStops, itemBounds);

      // do nothing of no snapping
      if (!guides.length) {
        return;
      }

      drawGuides(guides);

      // now force object position
      guides.forEach(lg => {
        switch (lg.snap) {
          case 'start': {
            switch (lg.orientation) {
              case 'V': {
                e.target.x(lg.lineGuide + lg.offset);
                break;
              }
              case 'H': {
                e.target.y(lg.lineGuide + lg.offset);
                break;
              }
              default:
                // V
                e.target.x(lg.lineGuide + lg.offset);
                break;
            }
            break;
          }
          case 'center': {
            switch (lg.orientation) {
              case 'V': {
                e.target.x(lg.lineGuide + lg.offset);
                break;
              }
              case 'H': {
                e.target.y(lg.lineGuide + lg.offset);
                break;
              }
              default: {
                // V
                e.target.x(lg.lineGuide + lg.offset);
                break;
              }
            }
            break;
          }
          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                e.target.x(lg.lineGuide + lg.offset);
                break;
              }
              case 'H': {
                e.target.y(lg.lineGuide + lg.offset);
                break;
              }
              default: {
                // V
                e.target.x(lg.lineGuide + lg.offset);
                break;
              }
            }
            break;
          }
          default:
            switch (lg.orientation) {
              case 'V': {
                e.target.x(lg.lineGuide + lg.offset);
                break;
              }
              case 'H': {
                e.target.y(lg.lineGuide + lg.offset);
                break;
              }
              default: {
                // V
                e.target.x(lg.lineGuide + lg.offset);
                break;
              }
            }
            break;
        }
      });
    });

    circle.on('dragend', function() {
      // find the line in this circle's parent group
      let group = this.getParent()
      let line = group.getChildren()[0]
      let points = line.points()

      let oldX = this.attrs.oldX
      let oldY = this.attrs.oldY

      let newX = this.x()
      let newY = this.y()

      // find the original coordinates
      let newPoints = []
      for (let i = 0; i < points.length; i++) {
        if (points[i] === oldX && points[i+1] === oldY) {
          newPoints.push(newX)
        } else if (points[i] === oldY && points[i-1] === oldX) {
          newPoints.push(newY)
        } else {
          newPoints.push(points[i])
        }
      }

      line.points(newPoints)

      // clear all previous lines on the screen
      shapesLayer.find('.guid-line').destroy();
      shapesLayer.batchDraw();
    });

    return circle;
  }

  function getData() {
    let areas = {}
    for (let item of (props.items || [])) {
      let coordinates = []
      try {
        coordinates = JSON.parse(item.path || '[]')
      } catch (error) {
        console.log(error.message)
      }

      let absolute = []
      for (let i = 0; i < coordinates.length; i++) {
        const e = coordinates[i]

        if (i % 2) {
          // Y coordinate
          absolute.push(placeholderWidth * e)
        } else {
          absolute.push(placeholderHeight * e)
        }
      }

      areas[item.name] = {
        editable: props.highlightItem ? (item._id === props.highlightItem._id) : item._id === props.editableItem,
        color: props.accentColor || 'rgba(15, 33, 49, 0.65)',
        points: absolute
      }
    }

    return areas;
  }

  function updateTooltip(tooltip, x, y, text) {
    tooltip.getText().text(text);
    tooltip.position({ x: x, y: y });
    if (text) tooltip.show();
  };

  function addPointToPath(layer, x, y) {
    let group;
    for (let g of layer.getChildren()) {
      if (g.attrs.editable) group = g
    }

    let line = group.find('.line')[0]

    let circle = drawCircle(x, y, true)
    group.add(circle)

    const period = 1500;
    let anim = new Konva.Animation(function(frame) {
      let scale = Math.sin((frame.time * 4 * Math.PI) / period) + 1.001;
      circle.scale({ x: scale, y: scale });
    }, stage);

    anim.start();

    setTimeout(function() {
      anim.stop();
      circle.scale({ x: 1, y: 1 });
      layer.draw();
    }, 500)

    line.points(line.points().concat([x, y]))
    layer.draw();
  };

  function handleFileUpload(file) {
    async function uploadFile() {
      const data = new FormData()
      data.append('vfile', file)

      const options = {
        method: 'POST',
        headers: { 'Authorization': cookies.get('planpoint') },
        data: data,
        url: `${Env.url}/api/v1/${props.scope}/${props.containerId}/image`
      };

      const response = await axios(options)

      props.setContainer(response.data)

      setIsUploading(false)
    }
    setIsUploading(true)
    uploadFile();
  }

  function saveItemDrawing() {
    let item = props.items.filter(f => f._id === props.editableItem)[0]
    let shape;

    for (let group of shapesLayer.getChildren()) {
      if (group.attrs.editable) shape = group.find('.line')[0]
    }

    const coordinates = shape.points()
    const relative = coordinates.map((e, i) => {
      if (i % 2) {
        return e / placeholderWidth
      } else {
        return e / placeholderHeight
      }
    })

    props.saveAction(item, relative)
  }

  function resetItemDrawing() {
    console.log(`resetItemDrawing`)

    let item = props.items.filter(f => f._id === props.editableItem)[0]

    props.resetAction(item)
  }

  let imageLoader;
  if (isUploading) {
    imageLoader = (
      <div className="image-loader-container">
        <img src={loader} className="image-loader" alt="uploading file ..." />
      </div>
    )
  }

  let canvasSubmission;
  if (props.editableItem) {
    canvasSubmission = (
      <button
        className="btn btn-outline-primary bg-white edit-submission"
        onClick={() => saveItemDrawing()}
        title="Save current drawing"
        >
        Save
      </button>
    )
  }

  let shapeReset;
  if (props.editableItem) {
    shapeReset = (
      <button
        className="btn btn-outline-primary bg-white shape-reset"
        onClick={() => resetItemDrawing()}
        title="Reset Drawing"
        >
        Reset
      </button>
    )
  }

  let buttonLabel;
  const labelObject = (props.project && (props.project.previewMode || props.project.projectType === 'land')) ? 'land' : 'building'
  if (props.backgroundUrl === '') {
    buttonLabel = (props.addCTALabel || `Add ${labelObject} image`)
  } else {
    buttonLabel = (props.updateCTALabel || `Change ${labelObject} image`)
  }

  let updateImageButton;
  if (!props.disableLabel) {
    updateImageButton = (
      <button
        className="btn btn-outline-primary bg-white project-image-form-toggle"
        onClick={() => inputRef.current.click()}
        >
        {buttonLabel}
      </button>
    )
  }

  return (
    <div className="InteractiveCanvas">
      <img
        className="canvas-picture-placeholder"
        ref={canvasPlaceholderRef}
        alt='Canvas Placeholder'
        style={{width: placeholderWidth, height: 'auto'}}
        src={props.backgroundUrl}
        />

      <div
        className="project-details__right"
        id="konva-container"
        style={props.styleSettings}
        ref={canvasAreaRef}
        >
      </div>

      {imageLoader}

      {updateImageButton}

      <form className="project-image-form d-none">
        <input type="file" ref={inputRef} onChange={e => handleFileUpload(e.target.files[0])}/>
      </form>

      {canvasSubmission}
      {shapeReset}

      <ButtonGroup aria-label="canvas-zoom" className="canvas-zoom">
        <Button variant={zoom ? "secondary disabled" : "secondary"} onClick={e => zoomIn()}>+</Button>
        <Button variant={zoom ? "secondary" : "secondary disabled"} onClick={e => zoomOut()}>-</Button>
      </ButtonGroup>
    </div>
  )
}

export default InteractiveCanvas;
