import React, { useState } from 'react';
import axios from 'axios';

import Env from '../../../Environments';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { ArrowLeft } from 'react-feather'

export default function SignupForm(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  // New data
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [projectNameURL, setProjectNameURL] = useState('')

  const [warning, setWarning] = useState([])
  const [showWarning, setShowWarning] = useState(false);
  
  const [step, setStep] = useState(0)

  function handleSubmit(e) {
    e.preventDefault()

    async function signUpUser() {
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        data: {
          name: firstName,
          lastName: lastName,
          fullName: firstName + ' ' + lastName,
          username: username,
          phone,
          company: company ?? '',
          projectNameURL: projectNameURL ?? '',
          password: password
        },
        url: `${Env.url}/api/v1/users`
      };

      if (firstName && lastName && username && phone && password) {
        try {
          const user = await axios(options);

          if(user.data) {
            props.signInUser(user.data)
            
            setFirstName()
            setLastName()
            setUsername()
            setPhone()
            setCompany()
            setProjectNameURL()
            setPassword()
          }  else {
            setWarning(['Seems like the above email or password is not correct. Double check and try again!'])
            setShowWarning(true)
          }
        } catch (e) {
          setWarning(['Seems like the above email or password is not correct. Double check and try again!'])
          setShowWarning(true)
        }
      } else {
        let newWarning = []
        if (!firstName) newWarning.push('You must provide your first name(s).')
        if (!lastName) newWarning.push('You must provide your last name(s).')
        if (!username) newWarning.push('You must provide your email.')
        if (!phone) newWarning.push('You must set a phone number.')
        if (!password) {
          newWarning.push('You must set a password.')
          setShowWarning(true)
        }
  
        setWarning(newWarning)
      }
    }
    signUpUser()
  }

  let conditionalWarning;
  if (warning) conditionalWarning = (
    <div className="Auth-form-warning">
      {warning.map((l, i) => <p key={`warning-${i}`}>{l}</p>)}
    </div>
  )

  function handleStart (e) {
    e.preventDefault()
    if (firstName && lastName && username) {
      setStep(1)
      setWarning([])
    } else {
      let newWarning = []
      if (!firstName) newWarning.push('You must provide your first name.')
      if (!lastName) newWarning.push('You must provide your last name.')
      if (!username) newWarning.push('You must provide your email.')

      setWarning(newWarning)
    }
  }

  function goBackToStart () {
    setStep(0)
    setWarning([])
    setShowWarning(false)
  }

  return (
    <div className="Auth-col-body">
      <h3>Start your project with a 14-day free trial</h3>
      <p>Enter your account details to sign up</p>

      <Form onSubmit={e => handleSubmit(e)} className="Auth-form">
        {step === 0 ?
        <>
        <Row>
          <Col>
            <Form.Group>
              <input
                type="text"
                id="inputName"
                placeholder="First Name *"
                autoFocus=""
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <input
                type="text"
                id="inputLastName"
                placeholder="Last Name *"
                autoFocus=""
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <input
                type="email"
                id="inputUser"
                placeholder="you@email.com *"
                autoFocus=""
                value={username}
                onChange={e => setUsername(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>
        {conditionalWarning}
        <Button onClick={handleStart} className="w-100 Auth-form-submit">Start</Button>
        </>
        :
        <>
        <Row>
          <Col>
            <Form.Group>
              <input
                type="text"
                id="inputPhone"
                placeholder="Phone Number *"
                autoFocus=""
                value={phone}
                onChange={e => setPhone(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <input
                type="text"
                id="inputCompany"
                placeholder="Company Name"
                autoFocus=""
                value={company}
                onChange={e => setCompany(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <input
                type="text"
                id="inputProjectNameURL"
                placeholder="Project Name or URL"
                autoFocus=""
                value={projectNameURL}
                onChange={e => setProjectNameURL(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <input
                type="password"
                id="inputPassword"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className={`${showWarning ? 'is-invalid' : ''}`}
                placeholder="Password *"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="d-flex Auth-checkbox">
              <Form.Check type="checkbox" required />
              <Form.Label>
                I agree to the <a href="https://www.planpoint.io/terms-of-service">Terms & Conditions</a> and <a href="https://www.planpoint.io/privacy-policy">Privacy Policy</a>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        {conditionalWarning}
        <div className="Auth-form-buttons-row">
          <div className='Auth-form-arrowbutton' onClick={goBackToStart}>
            <ArrowLeft color={'white'}/>
          </div>
          <Button type="submit" className="w-100 Auth-form-submit">Sign Up</Button>
        </div>
        </>
        }
      </Form>

      <div className="text-center Auth-form-redirect">
        <span>Already have an account? <a href="/login">Log in here</a></span>
      </div>
    </div>
  )
}
