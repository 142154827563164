import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Form from 'react-bootstrap/Form';

import CheckoutForm from './CheckoutForm';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Env from '../../../Environments';

import * as countryList from 'country-list'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Env.stripePromise);

export default function Billing(props) {
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState(props.user.name)
  const [lastName, setLastName] = useState(props.user.lastName)
  const [country, setCountry] = useState(props.user.country)
  const [address1, setAddress1] = useState(props.user.address1)
  const [address2, setAddress2] = useState(props.user.address2)
  const [city, setCity] = useState(props.user.city)
  const [region, setRegion] = useState(props.user.region)
  const [zip, setZip] = useState(props.user.zip)

  const cookies = new Cookies();

  async function handleSubmit(e) {
    if (e) e.preventDefault()

    setLoading(true)

    let updatedUser = Object.assign(props.user)
    updatedUser.name = firstName
    updatedUser.lastName = lastName
    updatedUser.country = country
    updatedUser.address1 = address1
    updatedUser.address2 = address2
    updatedUser.city = city
    updatedUser.region = region
    updatedUser.zip = zip

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: updatedUser,
      url: `${Env.url}/api/v1/users/${props.user._id}`
    })

    setLoading(false)
    props.fetchUser()
  }

  let availableCountries = countryList.getNames().map((c, i) => (
    <option key={i} value={c}>{c}</option>
  ))

  return (
    <div className="card card-fluid">
      <h6 className="card-header"> Billing Information </h6>
      <div className="card-body">

        <Form onSubmit={e => handleSubmit(e)}>

          <div className="form-row">
            <label htmlFor="first-name" className="col-md-3">First Name</label>
            <div className="col-md-9 mb-3">
              <input
                type="text"
                className="form-control"
                id="first-name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)} required=""
                />
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="last-name" className="col-md-3">Last Name</label>
            <div className="col-md-9 mb-3">
              <input
                type="text"
                className="form-control"
                id="last-name"
                value={lastName}
                onChange={e => setLastName(e.target.value)} required=""
                />
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="country" className="col-md-3">Country</label>
            <div className="col-md-9 mb-3">
              <select id="country" className="custom-select" value={country} onChange={e => setCountry(e.target.value)}>
                <option value=""> Select your country </option>
                {availableCountries}
              </select>
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="address1" className="col-md-3">Address Line 1</label>
            <div className="col-md-9 mb-3">
              <input
                required
                type="text"
                className="form-control"
                id="address1"
                value={address1}
                onChange={e => setAddress1(e.target.value)}
                />
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="address2" className="col-md-3">Address Line 2</label>
            <div className="col-md-9 mb-3">
              <input
                type="text"
                className="form-control"
                id="address2"
                value={address2}
                onChange={e => setAddress2(e.target.value)}
                />
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="city" className="col-md-3">City</label>
            <div className="col-md-9 mb-3">
              <input
                required
                type="text"
                className="form-control"
                id="city"
                value={city}
                onChange={e => setCity(e.target.value)}
                />
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="region" className="col-md-3">Province / Region</label>
            <div className="col-md-9 mb-3">
              <input
                required
                type="text"
                className="form-control"
                id="region"
                value={region}
                onChange={e => setRegion(e.target.value)}
                />
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="zip" className="col-md-3">Zip / Postal Code</label>
            <div className="col-md-9 mb-3">
              <input
                required
                type="text"
                className="form-control"
                id="zip"
                value={zip}
                onChange={e => setZip(e.target.value)}
                />
            </div>
          </div>
        </Form>

        <hr className="my-4" />

        <h4 className="card-title">Payment</h4>
        <p className="text-muted">A valid credit card is required in order to add a subscription to any of your projects.</p>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            user={props.user}
            fetchUser={() => props.fetchUser()}
            fetchCurrentUser={() => props.fetchCurrentUser()}
            submitForm={() => handleSubmit()}
            loading={loading}
            />
        </Elements>
      </div>
    </div>
  )
}
