const planData = {
  plans: {
    live: [
      {
        name: 'Standard Plan',
        id: 'prod_IyFJXKB1VKguiq',
        priceId: 'price_1IMIpICEdfdmzaWJox2OwftW',
        description: '30 units / 3 users per project',
        price: 69,
        maxMembers: 2,
        maxUnits: 30,
        icon: 'fa fa-user'
      }, {
        name: 'Large Plan',
        id: 'prod_IyFLrwcuF3WQGh',
        priceId: 'price_1IMIrHCEdfdmzaWJX8MXlhKz',
        description: '150 units / 3 users per project',
        price: 99,
        maxMembers: 2,
        maxUnits: 150,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Massive Plan',
        id: 'prod_IyFM6PNCuVobS4',
        priceId: 'price_1IMIs6CEdfdmzaWJ3zI7DLN7',
        description: 'unlimited units / 10 users per project',
        price: 139,
        maxMembers: 9,
        maxUnits: 1000,
        icon: 'fa fa-users'
      }, {
        name: 'Standard Plan (Legacy)',
        id: 'prod_HwZC4DJzkUYC58',
        priceId: 'price_1HMg4NCEdfdmzaWJxClVzCog',
        description: '30 units / 3 users per project',
        price: 69,
        maxMembers: 2,
        maxUnits: 30,
        icon: 'fa fa-user'
      }, {
        name: 'Large Plan (Legacy)',
        id: 'prod_HwZBKF5paDamKN',
        priceId: 'price_1HMg3NCEdfdmzaWJFS6rLxs5',
        description: '150 units / 3 users per project',
        price: 99,
        maxMembers: 2,
        maxUnits: 150,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Massive Plan (Legacy)',
        id: 'prod_HwZAfpZp1YG7l1',
        priceId: 'price_1HMg25CEdfdmzaWJZ6d7Zfzp',
        description: 'unlimited units / 10 users per project',
        price: 139,
        maxMembers: 9,
        maxUnits: 1000,
        icon: 'fa fa-users'
      }, {
        name: 'Small Rental',
        id: 'prod_JGsqS03NdGdOXM',
        priceId: 'price_1IeL52CEdfdmzaWJyaeYV7RD',
        description: '20 available units / 3 users per project',
        price: 39,
        maxMembers: 3,
        maxSqft: 20000,
        maxUnits: 20,
        icon: 'fa fa-user',
        rental: true
      }, {
        name: 'Medium Rental',
        id: 'prod_JGsrJButqwSt2J',
        priceId: 'price_1IeL5yCEdfdmzaWJ0QN971PN',
        description: '60 available units / 5 users per project',
        price: 59,
        maxMembers: 5,
        maxSqft: 60000,
        maxUnits: 60,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Big Rental',
        id: 'prod_JGssKHuw8yPAHh',
        priceId: 'price_1IeL6mCEdfdmzaWJNpi9sOoP',
        description: '120 available units / 5 users per project',
        price: 79,
        maxMembers: 5,
        maxSqft: 120000,
        maxUnits: 120,
        icon: 'fa fa-users'
      }, {
        name: 'Massive Rental',
        id: 'prod_JGstVSr4jJjSyh',
        priceId: 'price_1IeL7WCEdfdmzaWJrXdOJj5d',
        description: 'unlimited units / 10 users per project',
        price: 99,
        maxMembers: 5,
        maxSqft: 2147483647,
        maxUnits: 100000000,
        icon: 'fa fa-users'
      }, {
        name: 'Commercial S',
        id: 'prod_JGsqS03NdGdOXM',
        priceId: 'price_1IeL52CEdfdmzaWJyaeYV7RD',
        description: 'up to 20k sqft / 3 users per project',
        price: 39,
        maxMembers: 3,
        maxSqft: 20000,
        maxUnits: 20,
        icon: 'fa fa-user',
        rental: true
      }, {
        name: 'Commercial M',
        id: 'prod_JGsrJButqwSt2J',
        priceId: 'price_1IeL5yCEdfdmzaWJ0QN971PN',
        description: 'up to 60k sqft / 5 users per project',
        price: 59,
        maxMembers: 5,
        maxSqft: 60000,
        maxUnits: 60,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Commercial L',
        id: 'prod_JGssKHuw8yPAHh',
        priceId: 'price_1IeL6mCEdfdmzaWJNpi9sOoP',
        description: 'up to 120k sqft / 5 users per project',
        price: 79,
        maxMembers: 5,
        maxSqft: 120000,
        maxUnits: 120,
        icon: 'fa fa-users'
      }, {
        name: 'Commercial XL',
        id: 'prod_JGstVSr4jJjSyh',
        priceId: 'price_1IeL7WCEdfdmzaWJrXdOJj5d',
        description: 'unlimited sqft / 10 users per project',
        price: 99,
        maxMembers: 5,
        maxSqft: 2147483647,
        maxUnits: 100000000,
        icon: 'fa fa-users'
      }
    ],
    development: [
      {
        name: 'Standard Plan',
        id: 'prod_HsgdyAVrgVV6vR',
        priceId: 'price_1HIvGOBIBKI9V4KIiQJqDN9W',
        description: '30 units / 3 users per project',
        price: 69,
        maxMembers: 2,
        maxUnits: 30,
        icon: 'fa fa-user'
      }, {
        name: 'Large Plan',
        id: 'prod_HsgexvnSv19oJY',
        priceId: 'price_1HIvHUBIBKI9V4KIDsCnFjHE',
        description: '150 units / 3 users per project',
        price: 99,
        maxMembers: 2,
        maxUnits: 150,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Massive Plan',
        id: 'prod_Hsgf1oyQpWh4kx',
        priceId: 'price_1HIvI1BIBKI9V4KI8y3f0uV4',
        description: 'unlimited units / 10 users per project',
        price: 139,
        maxMembers: 9,
        maxUnits: 1000,
        icon: 'fa fa-users'
      }, {
        name: 'Standard Plan (Legacy)',
        id: 'prod_HwZC4DJzkUYC58',
        priceId: 'price_1HMg4NCEdfdmzaWJxClVzCog',
        description: '30 units / 3 users per project',
        price: 69,
        maxMembers: 2,
        maxUnits: 30,
        icon: 'fa fa-user'
      }, {
        name: 'Large Plan (Legacy)',
        id: 'prod_HwZBKF5paDamKN',
        priceId: 'price_1HMg3NCEdfdmzaWJFS6rLxs5',
        description: '150 units / 3 users per project',
        price: 99,
        maxMembers: 2,
        maxUnits: 150,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Massive Plan (Legacy)',
        id: 'prod_HwZAfpZp1YG7l1',
        priceId: 'price_1HMg25CEdfdmzaWJZ6d7Zfzp',
        description: 'unlimited units / 10 users per project',
        price: 139,
        maxMembers: 9,
        maxUnits: 1000,
        icon: 'fa fa-users'
      }, {
        name: 'Small Rental',
        id: 'prod_JGsf1Ib7gM3J4O',
        priceId: 'price_1IeKuEBIBKI9V4KIgmNwUfQ1',
        description: '20 available units / 3 users per project',
        price: 39,
        maxMembers: 3,
        maxUnits: 20,
        icon: 'fa fa-user',
        rental: true
      }, {
        name: 'Medium Rental',
        id: 'prod_JGsgPoU4iskjYy',
        priceId: 'price_1IeKvDBIBKI9V4KIb1nroQ56',
        description: '60 available units / 5 users per project',
        price: 59,
        maxMembers: 5,
        maxUnits: 60,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Big Rental',
        id: 'prod_JGshCjBJGJEuUF',
        priceId: 'price_1IeKvqBIBKI9V4KILepM3qry',
        description: '120 available units / 5 users per project',
        price: 79,
        maxMembers: 5,
        maxUnits: 120,
        icon: 'fa fa-users'
      }, {
        name: 'Massive Rental',
        id: 'prod_JGshy3EgR1Dcbt',
        priceId: 'price_1IeKwQBIBKI9V4KIc3dzENqe',
        description: 'unlimited units / 10 users per project',
        price: 99,
        maxMembers: 5,
        maxUnits: 100000000,
        icon: 'fa fa-users'
      }, {
        name: 'Commercial S',
        id: 'prod_JGsf1Ib7gM3J4O',
        priceId: 'price_1IeKuEBIBKI9V4KIgmNwUfQ1',
        description: 'up to 20k sqft / 3 users per project',
        price: 39,
        maxMembers: 3,
        maxSqft: 20000,
        icon: 'fa fa-user',
        rental: true
      }, {
        name: 'Commercial M',
        id: 'prod_JGsgPoU4iskjYy',
        priceId: 'price_1IeKvDBIBKI9V4KIb1nroQ56',
        description: 'up to 60k sqft / 5 users per project',
        price: 59,
        maxMembers: 5,
        maxSqft: 60000,
        icon: 'fa fa-user-friends'
      }, {
        name: 'Commercial L',
        id: 'prod_JGshCjBJGJEuUF',
        priceId: 'price_1IeKvqBIBKI9V4KILepM3qry',
        description: 'up to 120k sqft / 5 users per project',
        price: 79,
        maxMembers: 5,
        maxSqft: 120000,
        icon: 'fa fa-users'
      }, {
        name: 'Commercial XL',
        id: 'prod_JGshy3EgR1Dcbt',
        priceId: 'price_1IeKwQBIBKI9V4KIc3dzENqe',
        description: 'unlimited sqft / 10 users per project',
        price: 99,
        maxMembers: 5,
        maxSqft: 2147483647,
        icon: 'fa fa-users'
      }
    ]
  },
  coupons: {
    live: [{
      name: 'Admin Coupon',
      id: 'jBy2qpDy'
    }],
    development: [{
      name: 'Admin Coupon',
      id: 'jBy2qpDy'
    }]
  }
}

function filterPlans(plans, projectType, excludeLegacy = true) {
  let planData = plans

  if (excludeLegacy) {
    planData = planData.filter(p => !p.name.includes('Legacy'))
  }

  if (projectType === 'commercial') {
    planData = planData.filter(p => p.name.includes('Commercial'))
  } else if (projectType === 'rental') {
    planData = planData.filter(p => p.name.includes('Rental'))
  } else {
    planData = planData.filter(p => !p.name.includes('Rental') && !p.name.includes('Commercial'))
  }

  return planData
}

const exportData = {
  plans: {
    production: planData.plans.live,
    development: planData.plans.development
  },
  coupons: {
    production: planData.coupons.live,
    development: planData.coupons.development
  },
  filterPlans: filterPlans
}

export default exportData
