import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import Header from './components/Header';
import Aside from './components/Aside';
import BreadcrumbMenu from './components/BreadcrumbMenu';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { Check, Slash, ExternalLink } from 'react-feather';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export default function Gallery(props) {
  const [project, setProject] = useState()
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedLayouts, setSelectedLayouts] = useState([])
  const [selectedFinishes, setSelectedFinishes] = useState([])
  const [selectedFloorplan, setSelectedFloorplan] = useState('')
  const [selectedPDF, setSelectedPDF] = useState('')
  const [selectedEmbedCode, setSelectedEmbedCode] = useState('')

  const [selectedUnits, setSelectedUnits] = useState([])
  const [selectedFloors, setSelectedFloors] = useState([])

  const [selectedAssets, setSelectedAssets] = useState([])
  const [attr, setAttr] = useState('')
  const [mode, setMode] = useState('')
  const [targetsType, setTargetsType] = useState('')
  const [showTargetsModal, setShowTargetsModal] = useState(false)

  const [uploadAssets, setUploadAssets] = useState({images: [], layouts: [], floorplans: [], downloadableAssets: [], finishes: [], embedCodes: []})
  const [uploadAttr, setUploadAttr] = useState('')
  const [uploadAssetType, setUploadAssetType] = useState('')
  const [showUploadModal, setShowUploadModal] = useState(false)

  const cookies = new Cookies();

  const { projectId } = useParams()

  async function fetchProject() {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/projects/${projectId}/full` // Fetches the project, populating with floors, units and unit models
    };

    const response = await axios(options)
    setProject(response.data)
    const {images, layouts, finishes} = response.data
    setSelectedImages(images.map(i => ''))
    setSelectedLayouts(layouts.map(i => ''))
    setSelectedFinishes(finishes.map(i => ''))
    setSelectedPDF('')
    setSelectedFloorplan('')
    setSelectedEmbedCode('')
  }

  useEffect(() => {
    if (!project) fetchProject()
  })

  function handleImagesSelection (idx) {
    const array = [...selectedImages]
    array[idx] = array[idx] ? '' : project.images[idx] 
    return setSelectedImages(array)
  }

  function handleLayoutsSelection (idx) {
    const array = [...selectedLayouts]
    array[idx] = array[idx] ? '' : project.layouts[idx] 
    return setSelectedLayouts(array)
  }

  function handleFinishesSelection (idx) {
    const array = [...selectedFinishes]
    array[idx] = array[idx] ? '' : project.finishes[idx] 
    return setSelectedFinishes(array)
  }

  function handleSelectFloors (id) {
    const array = [...selectedFloors]
    if (array.includes(id)) return setSelectedFloors(array.filter(f => f !== id))
    else array.push(id)
    return setSelectedFloors(array)
  }
  
  function handleSelectUnits (id) {
    const array = [...selectedUnits]
    if (array.includes(id)) return setSelectedUnits(array.filter(u => u !== id))
    else array.push(id)
    return setSelectedUnits(array)
  }

  function handleSelectFloorUnits (units) {
    if (units.length === 0) return
    let array = [...selectedUnits]
    let found = 0;
    let filteredArray = array.filter(u => {
      if (!units.includes(u)) {
        return true
      }
      found = found + 1
      return false
    })
    if(found < units.length) return setSelectedUnits([...filteredArray, ...units])
    return setSelectedUnits(filteredArray)
  }

  let projectImages = ((project ||{}).images || []).map((image, i) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
      <div className="card card-figure card-select" data-selected={selectedImages[i] !== ''} onClick={()=>handleImagesSelection(i)}>
        <figure className="figure">
          <div className="figure-img">
            <img className="img-fluid" src={image} alt="Card cap" />
            <div className="figure-action">
              <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteGalleryImage(e, image)}>
                Delete
              </a>
            </div>
          </div>
        </figure>
      </div>
    </div>
  ))

  let projectFinishes = ((project ||{}).finishes || []).map((image, i) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
      <div className="card card-figure card-select" data-selected={selectedFinishes[i] !== ''} onClick={()=>handleFinishesSelection(i)}>
        <figure className="figure">
          <div className="figure-img">
            <img className="img-fluid" src={image} alt="Card cap" />
            <div className="figure-action">
              <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteGalleryFinish(e, image)}>
                Delete
              </a>
            </div>
          </div>
        </figure>
      </div>
    </div>
  ))

  let projectLayouts = ((project ||{}).layouts || []).map((layout, i) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
      <div className="card card-figure card-select" data-selected={selectedLayouts[i] !== ''} onClick={()=>handleLayoutsSelection(i)}>
        <figure className="figure">
          <div className="figure-img">
            <img className="img-fluid" src={layout} alt="Card cap" />
            <div className="figure-action">
              <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteLayout(e, layout)}>
                Delete
              </a>
            </div>
          </div>
        </figure>
      </div>
    </div>
  ))

  let projectFloorplans = ((project ||{}).floorplans || []).map((floorplan, i) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
      <div className="card card-figure card-select" data-selected={selectedFloorplan === floorplan} onClick={()=>setSelectedFloorplan(floorplan)}>
        <figure className="figure">
          <div className="figure-img">
            <img className="img-fluid" src={floorplan} alt="Card cap" />
            <div className="figure-action">
              <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteFloorplan(e, floorplan)}>
                Delete
              </a>
            </div>
          </div>
        </figure>
      </div>
    </div>
  ))

  let projectPdfs = ((project ||{}).downloadableAssets || []).map((pdf, i) => {
    let fileSegments = pdf.split('/').pop().split('.')
    let fileExtension = fileSegments.pop()
    let fileNameSegments = fileSegments.join('.').split('-')
    fileNameSegments.pop() // drop hash
    let fileNamePreview = fileNameSegments.join('-')

    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
        <div className="card card-figure card-select" data-selected={selectedPDF === pdf} onClick={()=>setSelectedPDF(pdf)}>
          <figure className="figure">
            <div className="figure-img">
              <div style={{minHeight: '100px'}}>
                <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
              </div>
              <div className="figure-action">
                <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deletePdf(e, pdf)}>
                  Delete
                </a>
              </div>
            </div>
          </figure>
        </div>
      </div>
    )
  })
  
  let projectEmbedCodes= ((project ||{}).embedCodes || []).map((embedCode, i) => {
    const srcSplitted = embedCode.split(' ').find(s => s.split('=')[0] === 'src')
    const srcFromHttp = srcSplitted?.slice(srcSplitted.indexOf('http'))
    const source = srcFromHttp?.slice(0, srcFromHttp.indexOf("\""))
    const sourceOrigin = source?.indexOf('youtube') !== -1? 'youtube' : 'kuula'
    const youtubeSrc = sourceOrigin === 'youtube' ? `http://img.youtube.com/vi/${source?.split('/').pop()}/mqdefault.jpg` : ''
    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
        <div className="card card-figure card-select" data-selected={selectedEmbedCode === embedCode} onClick={()=>setSelectedEmbedCode(embedCode)}>
          <figure className="figure">
            <div className="figure-img">
              {sourceOrigin === 'youtube' && 
              <div style={{position: 'relative', backgroundImage: `url(${youtubeSrc})`, minHeight: '180px'}}>
                <a href={source} target='_blank' rel='noreferrer' style={{position: 'absolute', top: '2px', right: '2px', backgroundColor: 'white', borderRadius: '50%', padding: '5px'}}><ExternalLink size={20}/></a>
              </div>
              }

              {sourceOrigin === 'kuula' && 
              <div style={{minHeight: '100px'}}>
                <small className="text-center pt-1"><a href={source} target='_blank' rel='noreferrer'>{source}</a></small>
              </div>}
              
              <div className="figure-action">
                <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteEmbedCode(e, embedCode)}>
                  Delete
                </a>
              </div>
            </div>
          </figure>
        </div>
      </div>
    )
  })

  // Upload Modal

  let uploadGalleryButton = <button className='btn btn-sn' onClick={(e) => openUploadModal(e, 'images', 'files')}>Upload</button>

  let uploadLayoutsButton = <button className='btn btn-sn' onClick={(e) => openUploadModal(e, 'layouts', 'files')}>Upload</button>

  let uploadFloorplanButton = <button className='btn btn-sn' onClick={(e) => openUploadModal(e, 'floorplans', 'files')}>Upload</button>

  let uploadPDFButton = <button className='btn btn-sn' onClick={(e) => openUploadModal(e, 'downloadableAssets', 'files')}>Upload</button>

  let uploadFinishesButton = <button className='btn btn-sn' onClick={(e) => openUploadModal(e, 'finishes', 'files')}>Upload</button>

  let uploadEmbedCodeButton = <button className='btn btn-sn' onClick={(e) => openUploadModal(e, 'embedCodes', 'links')}>Upload</button>

  function openUploadModal(e, attr, assetType) {
    e.preventDefault()
    setUploadAttr(attr)
    setUploadAssetType(assetType)
    setShowUploadModal(true)
  }

  function saveAndCloseUploadModal(e) {
    e.preventDefault()
    setShowUploadModal(false)
    updateMediaGallery(uploadAssets[uploadAttr], uploadAttr, uploadAssetType)
  }

  function clearUpload() {
    setUploadAssets({...uploadAssets, [uploadAttr]: []})
  }

  function handleSelectAssets(e) {
    if (uploadAssetType === 'links') {
      setUploadAssets({...uploadAssets, [uploadAttr]: e.target.value ? e.target.value.split('\n') : []})
    } else if (uploadAssetType === 'files') {
      setUploadAssets({...uploadAssets, [uploadAttr]: [...uploadAssets[uploadAttr], ...e]})
    }
  }

  function handleFilesUploaded (e) {
    setUploadAssets({...uploadAssets, [uploadAttr]: []})
    setShowUploadModal(false)
    fetchProject()
  }

  let conditionalConfirmUpload;
  let conditionalClearUpload;
  if (uploadAssetType === 'links' && uploadAssets[uploadAttr].filter(i => i)?.length > 0) {
    conditionalConfirmUpload = (
      <Button variant="primary" onClick={saveAndCloseUploadModal}>
        Confirm
      </Button>
    )
    conditionalClearUpload = (
      <Button variant="secondary" onClick={clearUpload}>
        Clear
      </Button>
    )
  }

  // Targets Modal

  let applyGalleryButton = <button className='btn btn-sn' onClick={(e) => updateSelectedAndOpenTargetsModal(e, selectedImages, 'units', 'images', 'append')}>Apply</button>

  let applyLayoutsButton = <button className='btn btn-sn' onClick={(e) => updateSelectedAndOpenTargetsModal(e, selectedLayouts, 'units', 'layoutGallery', 'append')}>Apply</button>

  let applyFloorplanButton = <button className='btn btn-sn' onClick={(e) => updateSelectedAndOpenTargetsModal(e, selectedFloorplan, 'floors', 'floorplanUrl', 'single-replace')}>Apply</button>

  let applyPDFButton = <button className='btn btn-sn' onClick={(e) => updateSelectedAndOpenTargetsModal(e, selectedPDF, 'units', 'downloadableAsset', 'single-replace')}>Apply</button>

  let applyFinishesButton = <button className='btn btn-sn' onClick={(e) => updateSelectedAndOpenTargetsModal(e, selectedFinishes, 'units', 'finishes', 'append')}>Apply</button>

  let applyEmbedCodeButton = <button className='btn btn-sn' onClick={(e) => updateSelectedAndOpenTargetsModal(e, selectedEmbedCode, 'units', 'embedCode', 'single-replace')}>Apply</button>
  
  function updateSelectedAndOpenTargetsModal(e, assets, targets, attr, mode) {
    e.preventDefault()
    const assetsArray = typeof assets === 'string' ? [assets] : assets.filter(i => i)
    if (assetsArray.length === 0 || assets === '') return alert('Please select at least one asset')
    setSelectedAssets(assetsArray)
    setShowTargetsModal(true)
    setTargetsType(targets)
    setAttr(attr)
    setMode(mode)
  }

  function saveAndCloseTargetsModal(e) {
    e.preventDefault()
    setShowTargetsModal(false)
    updateUnits(selectedAssets, targetsType === 'units' ? selectedUnits : selectedFloors, attr, mode)
  }

  function clearTargets() {
    if (targetsType === 'units') setSelectedUnits([])
    else if (targetsType === 'floors') setSelectedFloors([])
  }
  
  // Preview selected assets
  let modalSelectedAssets = selectedAssets.map((asset, idx) => {
    if (asset === '') return null
    if (attr === 'downloadableAsset') {
      let fileSegments = asset.split('/').pop().split('.')
      let fileExtension = fileSegments.pop()
      let fileNameSegments = fileSegments.join('.').split('-')
      fileNameSegments.pop() // drop hash
      let fileNamePreview = fileNameSegments.join('-')
      return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={idx}>
          <div className='card card-figure'>
            <figure className='figure'>
              <div className='figure-img'>
                <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
              </div>
            </figure>
          </div>
        </div>
      )
    }else if (attr === 'embedCode') {
      return (
        <div className="col-xl-12" key={idx}>
          <div className='card card-figure'>
            <figure className='figure'>
              <div className='figure-img'>
                <small className="text-center pt-1">{asset}</small>
              </div>
            </figure>
          </div>
        </div>
      )
    }else {
      return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={idx}>
          <div className='card card-figure'>
            <figure className='figure'>
              <div className='figure-img'>
                <img className='img-fluid' src={asset} alt="Card cap"/>
              </div>
            </figure>
          </div>
        </div>
      )
    }
  })

  let modalTargets; // Options to apply the assets. Units or Floors
  if(targetsType === 'units') {
    modalTargets = (
      <>
      <div className='section-block'>
        <h2 className='section-title'>Selected units {`(${selectedUnits.length})`}</h2>
      </div>
      {project?.floors?.map(floor => (
        <div key={floor._id} className='card'>
          <div className="card-header border-0">
            <div className="d-flex align-items-center">
              <div className="card-floor mr-auto" data-selected={floor.units.length > 0 && floor.units.every(u => selectedUnits.includes(u._id))} onClick={() => handleSelectFloorUnits(floor.units.map(u => u._id))}>{floor.name}</div>
            </div>
          </div>
    
          <div className='card-targets'>
            {floor?.units?.map(unit => {
              return(
              <div key={unit._id} className='card-target' data-selected={selectedUnits.includes(unit._id)} onClick={() => handleSelectUnits(unit._id)}>
                <span style={{fontWeight: 'bold'}}>{unit.name}</span>
                <span>{mode === 'single-replace' ? (unit[attr] && unit[attr] !== '') ? <Check /> : <Slash /> : unit[attr].length}</span>
              </div>
            )})}
          </div>
        </div>
      ))}
      </>
    )
  } else if (targetsType === 'floors') {
    modalTargets = (
      <>
      <div className='section-block'>
        <h2 className='section-title'>Selected floors {`(${selectedFloors.length})`}</h2>
      </div>
      <div className='card-targets'>
        {project?.floors?.map(floor => (
          <div key={floor._id} className='card-target' data-selected={selectedFloors.includes(floor._id)} onClick={()=>handleSelectFloors(floor._id)}>
            <span style={{fontWeight: 'bold'}}>{floor.name}</span>
            <span>{mode === 'single-replace' ? (floor[attr] && floor[attr] !== '') ? <Check /> : <Slash /> : floor[attr].length}</span>
        </div>
        ))}
      </div>
      </>
    )
  }

  let conditionalConfirmTargets;
  let conditionalClearTargets;
  if ((targetsType === 'units' && selectedUnits.length > 0) || (targetsType === 'floors' && selectedFloors.length > 0)) { 
    conditionalConfirmTargets = (
      <Button variant="primary" onClick={saveAndCloseTargetsModal}>
        Confirm
      </Button>
    )
    conditionalClearTargets = (
      <Button variant="secondary" onClick={clearTargets}>
        Clear
      </Button>
    )
  }

  // async updates
  async function updateMediaGallery(nonFilteredAssets, attr, assetType) {
    // nonFilteredAssets is an array with all that was saved as when making the goal
    // attr is the name of the attribute as found in the model
    // assetType can be either 'files' or 'links' referencing the type of asset to be uploaded

    // Return if there are repeated values
    for (let i = 0; i < nonFilteredAssets.length - 1; i++) {
      if (nonFilteredAssets[i] === '') continue
      const found = nonFilteredAssets.slice(i + 1).indexOf(nonFilteredAssets[i])
      if(found !== -1) {
        setShowUploadModal(true)
        return alert(`Please check for repeated assets at lines ${i + 1} and ${found + i + 2}`)
      }
    }

    // Filter out empty strings
    const assets = nonFilteredAssets.filter(i => i)
    try {
      if (assetType === 'links') {
        await axios({
          method: 'POST',
          headers: { 'Authorization': cookies.get('planpoint')},
          data: {
            assets,
            attr,
            assetType
          },
          url: `${Env.url}/api/v1/projects/${project?._id}/${attr}`
        })
      }
      
      fetchProject()
    } catch (e) {
      console.log(e)
    }
  }

  async function updateUnits (assets, ids, attr, mode ) {
    // assets can be the string or array of strings corresponding to what has been selected to transfer/copy from the media gallery
    // ids is the array of ids from the selected units to update
    // attr is the name of the attribute to be updated
    // mode can have two values depending on the type of update: 'append', to add the selected assets to the document attr, if available (only for arrays);
    // or replace, to substitute the old assets with the new one(s) selected
    try {
      await axios({
        method: 'PATCH',
        headers: { 'Authorization': cookies.get('planpoint') },
        data: {
          'targets': ids,
          'assets': assets,
          'attr': attr,
          'mode': mode
        },
        url: `${Env.url}/api/v1/${targetsType}/update-from-media` // Update the units or floors collection, depending on the target type
      })
    
      fetchProject()
    } catch (e) {
      console.log(e)
    } 
  }

  async function deleteGalleryImage(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/projects/${project._id}/gallery`
    })

    fetchProject()
  }

  async function deleteGalleryFinish(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/projects/${project._id}/finishes`
    })

    fetchProject()
  }

  async function deleteLayout(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/projects/${project._id}/layouts`
    })

    fetchProject()
  }

  async function deleteFloorplan(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/projects/${project._id}/floorplans`
    })

    fetchProject()
  }

  async function deletePdf(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/projects/${project._id}/pdfs`
    })

    fetchProject()
  }

  async function deleteEmbedCode(e, target) {
    e.preventDefault()

    await axios({
      method: 'DELETE',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: { 'target': target },
      url: `${Env.url}/api/v1/projects/${project._id}/embedCodes`
    })

    fetchProject()
  }

  // Actual name of the attribute in the corresponding endpoint
  function endpointName (attr) {
    switch (attr) {
      case 'images':
        return 'gallery'
      case 'layouts':
        return 'layouts'
      case 'floorplans':
        return 'floorplans'
      case 'downloadableAssets':
        return 'pdfs'
      case 'finishes':
        return 'finishes'
      default:
        break;
    }
  }

  // Actual field name for processing files
  function fieldName (attr) {
    switch (attr) {
      case 'images':
        return 'projectimages'
      case 'layouts':
        return 'projectlayouts'
      case 'floorplans':
        return 'projectfloorplans'
      case 'downloadableAssets':
        return 'projectpdfs'
      case 'finishes':
        return 'projectfinishes'
      default:
        break;
    }
  }

  return (
    <div className="app planpoint-embed">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />
      
      <Modal show={showTargetsModal} onHide={() => setShowTargetsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Apply Assets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='section-block'>
            <h2 className='section-title'>{modalSelectedAssets.length === 1 ? `Selected asset `: `Selected assets (${modalSelectedAssets.length})`}</h2>
          </div>
          <div className='row' style={{maxHeight: '180px', overflow: 'auto'}}>
            {modalSelectedAssets}
          </div>
          {modalTargets}
        </Modal.Body>
        <Modal.Footer>
          {conditionalConfirmTargets}
          {conditionalClearTargets}
          <Button variant="secondary" onClick={() => setShowTargetsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Assets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadAssetType === 'files' &&
          <FilePond
            files={uploadAssets[uploadAttr]}
            allowRevert={false}
            onupdatefiles={handleSelectAssets}
            onprocessfiles={handleFilesUploaded}
            allowMultiple={true}
            maxParallelUploads={1}
            maxFiles={10}
            server={`${Env.url}/api/v1/projects/${project?._id}/${endpointName(uploadAttr)}`}
            name={fieldName(uploadAttr)}
            labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
          />}

          {uploadAssetType === 'links' &&
            <>
              <p className="text-muted">Add your links, one per line: {`[${uploadAssets[uploadAttr].filter(i => i).length}]`}</p>
              <textarea
                className="form-control"
                value={uploadAssets[uploadAttr]?.join('\n')}
                onChange={handleSelectAssets}
                rows={15}
              />
            </>}
        </Modal.Body>
        <Modal.Footer>
          {conditionalConfirmUpload}
          {conditionalClearUpload}
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='gallery' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">Media Gallery</h1>
              </header>

              <div className="page-section">
                <div className="card">
                  <div className="card-body">
                    <div className="card-body-top-row">
                      <h5>Unit gallery</h5>
                      <div className="card-controls">
                        {applyGalleryButton}
                        {uploadGalleryButton}
                      </div>
                    </div>
                    <p className="text-muted">All gallery items that have been uploaded to any of your units</p>
                    <div className="row">
                      {projectImages}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="card-body-top-row">
                      <h5>Unit layouts</h5>
                      <div className="card-controls">
                        {applyLayoutsButton}
                        {uploadLayoutsButton}
                      </div>
                    </div>
                    <p className="text-muted">All unit layouts that have been uploaded to any of your units</p>
                    <div className="row">
                      {projectLayouts}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="card-body-top-row">
                      <h5>{(project && (project.previewMode || project.projectType === 'land')) ? 'Phases' : 'Floorplans'}</h5>
                      <div className="card-controls">
                        {applyFloorplanButton}
                        {uploadFloorplanButton}
                      </div>
                    </div>
                    <p className="text-muted">All floorplans that have been uploaded to any of your floors</p>
                    <div className="row">
                      {projectFloorplans}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="card-body-top-row">
                      <h5>Unit PDFs</h5>
                      <div className="card-controls">
                        {applyPDFButton}
                        {uploadPDFButton}
                      </div>
                    </div>
                    <p className="text-muted">All PDFs that have been uploaded to any of your units</p>
                    <div className="row">
                      {projectPdfs}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="card-body-top-row">
                      <h5>Unit Finishes</h5>
                      <div className="card-controls">
                        {applyFinishesButton}
                        {uploadFinishesButton}
                      </div>
                    </div>
                    <p className="text-muted">All finishes that have been uploaded to any of your units</p>
                    <div className="row">
                      {projectFinishes}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="card-body-top-row">
                      <h5>Unit Embed Codes</h5>
                      <div className="card-controls">
                        {applyEmbedCodeButton}
                        {uploadEmbedCodeButton}
                      </div>
                    </div>
                    
                    <div className="row">
                      {projectEmbedCodes}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}