import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { Square, CheckSquare } from 'react-feather';

import Env from '../../Environments';

export default function ProjectCard(props) {
  const [project, setProject] = useState({})
  const [floors, setFloors] = useState([])
  const [fetchedProject, setFetchedProject] = useState(false)
  const [selected, setSelected] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchProject() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${props.project._id}`
      };

      const response = await axios(options)
      setProject(response.data)

      // fetch floors
      let floorData = []
      for (let floor of response.data.floors) {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/floors/${floor}`
        })

        floorData.push(response.data)
      }

      setFloors(floorData)
    }

    if (!fetchedProject) {
      fetchProject()
      setFetchedProject(true)
    }
  }, [fetchedProject, cookies, props.project._id])

  let units = []
  for (let floor of (floors || [])) {
    units = [...units, ...(floor.units || [])]
  }

  let projectRole;
  let projectDetailsLink = '';
  if (project) {
    if (project.user === props.user._id) {
      projectRole = 'Owner'
      projectDetailsLink = `/projects/${props.project._id}/details`
    } else if ((project.administrators || []).filter(a => a.username === props.user.username).length) {
      projectRole = 'Administrator'
    } else {
      projectRole = 'Editor'
      projectDetailsLink = `/projects/${props.project._id}/units`
    }
  }

  let conditionalFormsLink;
  if (props.project.enableForms) {
    conditionalFormsLink = (
      <a href={projectRole === 'Editor' ? '/' : `/projects/${props.project._id}/forms`} className='list-group-item list-group-item-action' disabled={projectRole === 'Editor'} style={{ opacity: projectRole === 'Editor' ? 0.5 : 1 }}>
        <div className="list-group-item-figure">
          <span className="fa fa-lg fa-fw fa-address-card"></span>
        </div>
        <div className="list-group-item-body d-flex justify-content-between align-items-center">
          <span className="list-group-item-text">Forms</span> <span className="badge badge-primary badge-pill">{props.project.leads.length}</span>
        </div>
      </a>
    )
  }

  function toggleSelected(evt) {
    evt.preventDefault()
    const isSelected = !selected

    if (isSelected) {
      props.addToSelection(props.project)
    } else {
      props.removeFromSelection(props.project)
    }

    setSelected(isSelected)
  }

  let selectedIcon = selected ? <CheckSquare size='16' /> : <Square size='16' />

  return (
    <div className="col-lg-6 col-xl-4">

      <div className="card card-fluid">

        <div className="card-header border-0">
          <div className="d-flex justify-content-between align-items-center">
            <a
              href='/'
              onClick={e => toggleSelected(e)}
              className={`btn btn-icon btn-${selected ? 'success' : 'light'}`}>
              {selectedIcon}
            </a>

            <h5>{props.project.name}</h5>

            <a href={`/projects/${props.project._id}/embed`} className="btn btn-icon btn-light">
              <i className="fa fa-code"></i>
            </a>
          </div>
        </div>

        <div className="card-body pt-0">
          <div className="project-card-image rounded-top" style={{ backgroundImage: `url('${props.project.projectImageUrl}')`, backgroundColor: '#f7f7f7' }} />

          <div className="list-group list-group-divider border-0">
            <a href={projectRole === 'Editor' ? '/' : projectDetailsLink} className='list-group-item list-group-item-action' disabled={projectRole === 'Editor'} style={{ opacity: projectRole === 'Editor' ? 0.5 : 1 }}>
              <div className="list-group-item-figure">
                <span className="fa fa-lg fa-fw fa-cogs"></span>
              </div>
              <div className="list-group-item-body d-flex justify-content-between align-items-center">
                <span className="list-group-item-text">Settings</span>
              </div>
            </a>

            <a href={projectRole === 'Editor' ? '/' : `/projects/${props.project._id}/exterior`} className='list-group-item list-group-item-action' disabled={projectRole === 'Editor'} style={{ opacity: projectRole === 'Editor' ? 0.5 : 1 }}>
              <div className="list-group-item-figure">
                <span className={(props.project.previewMode || props.project.projectType === 'land') ? 'fa fa-lg fa-fw fa-border-style' : 'fa fa-lg fa-fw fa-building'}>
                </span>
              </div>
              <div className="list-group-item-body d-flex justify-content-between align-items-center">
                <span className="list-group-item-text">
                  {(props.project.previewMode || props.project.projectType === 'land') ? 'Land' : 'Exterior'}
                </span>
              </div>
            </a>

            <a href={`/projects/${props.project._id}/units`} className='list-group-item list-group-item-action'>
              <div className="list-group-item-figure">
                <span className="fa fa-lg fa-fw fa-sitemap"></span>
              </div>
              <div className="list-group-item-body d-flex justify-content-between align-items-center">
                <span className="list-group-item-text">Units</span> <span className="badge badge-primary badge-pill">{units.length}</span>
              </div>
            </a>

            <a href={`/projects/${props.project._id}/gallery`} className='list-group-item list-group-item-action'>
              <div className="list-group-item-figure">
                <span className="fa fa-lg fa-fw fa-image"></span>
              </div>
              <div className="list-group-item-body d-flex justify-content-between align-items-center">
                <span className="list-group-item-text">Media Gallery</span>
              </div>
            </a>

            <a href={projectRole === 'Editor' ? '/' : `/projects/${props.project._id}/floors`} className='list-group-item list-group-item-action' disabled={projectRole === 'Editor'} style={{ opacity: projectRole === 'Editor' ? 0.5 : 1 }}>
              <div className="list-group-item-figure">
                <span className="fa fa-lg fa-fw fa-layer-group"></span>
              </div>
              <div className="list-group-item-body d-flex justify-content-between align-items-center">
                <span className="list-group-item-text">
                {(props.project.previewMode || props.project.projectType === 'land') ? 'Phases' : 'Floors'}</span> <span className="badge badge-primary badge-pill">{props.project.floors.length}</span>
              </div>
            </a>

            <a href={projectRole === 'Editor' ? '/' : `/projects/${props.project._id}/members`} className='list-group-item list-group-item-action' disabled={projectRole === 'Editor'} style={{ opacity: projectRole === 'Editor' ? 0.5 : 1 }}>
              <div className="list-group-item-figure">
                <span className="fa fa-lg fa-fw fa-users"></span>
              </div>
              <div className="list-group-item-body d-flex justify-content-between align-items-center">
                <span className="list-group-item-text">Members</span> <span className="badge badge-primary badge-pill">{(props.project.editors || []).length + (props.project.administrators || []).length}</span>
              </div>
            </a>

            <a href={`/projects/${props.project._id}/stats`} className='list-group-item list-group-item-action'>
              <div className="list-group-item-figure">
                <span className="fa fa-lg fa-fw fa-chart-bar"></span>
              </div>
              <div className="list-group-item-body d-flex justify-content-between align-items-center">
                <span className="list-group-item-text">Stats</span>
              </div>
            </a>

            {conditionalFormsLink}
          </div>
        </div>
      </div>
    </div>
  )
}
