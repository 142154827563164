import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import Header from './components/Header';
import Aside from './components/Aside';
import ProjectCard from './components/ProjectCard';

export default function Dashboard(props) {
  const [user, setUser] = useState()
  const [projects, setProjects] = useState([])
  const [groups, setGroups] = useState([])
  const [fetchedProjects, setFetchedProjects] = useState(false)
  const [fetchedUser, setFetchedUser] = useState(false)
  const [showNewGroupModal, setShowNewGroupModal] = useState(false)
  const [showNewCollectionModal, setShowNewCollectionModal] = useState(false)
  const [groupName, setGroupName] = useState()
  const [collectionName, setCollectionName] = useState()
  const [selectedProjects, setSelectedProjects] = useState([])
  const [showTransferAccountModal, setShowTransferAccountModal] = useState(false)
  const [transferTargetName, setTransferTargetName] = useState('')

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchUser() {
      if (cookies.get('planpoint')) {
        let response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/users/${props.user._id}`
        })

        setUser(response.data)
      } else {
        setTimeout(fetchUser, 200)
      }
    }

    async function fetchProjects() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects`
      };

      let response = await axios(options)
      setProjects(response.data)
    }

    async function fetchGroups() {
      let response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/groups`
      })

      setGroups(response.data)
    }

    if (!fetchedUser) {
      fetchUser()
      setFetchedUser(true)
    }

    if (user && !fetchedProjects) {
      fetchProjects()
      fetchGroups()
      setFetchedProjects(true)
    }
  }, [fetchedUser, fetchedProjects, cookies, props.user._id, user])

  function addToSelection(project) {
    setSelectedProjects([...selectedProjects, project])
  }

  function removeFromSelection(project) {
    setSelectedProjects(selectedProjects.filter(p => p._id !== project._id))
  }

  let projectCards = []
  if (user) {
    const blacklist = groups.map(g => g.projects).flat()

    projects.forEach((p, i) => {
      if (!blacklist.includes(p._id)) {
        projectCards.push(
          <ProjectCard
            key={`project-card-${i}`}
            project={p}
            user={props.user}
            addToSelection={p => addToSelection(p)}
            removeFromSelection={p => removeFromSelection(p)}
            />
        )
      }
    })
  }

  let groupCards;
  if (user) {
    groupCards = groups.map((g, i) => (
      <div className="col-lg-6 col-xl-4" key={i}>
        <div className="card card-fluid">
          <div className="card-header border-0">
            <div className="d-flex justify-content-between align-items-center">
              <h5>{g.name} ({g.projects.length})</h5>
              <div>
                <a href={`/groups/${g._id}/embed`} className="btn btn-icon btn-light">
                  <i className="fa fa-code"></i>
                </a>
                <a href={`/groups/${g._id}/details`} className="btn btn-icon btn-light">
                  <i className="fa fa-cogs"></i>
                </a>
                <a href={`/groups/${g._id}`} className="btn btn-icon btn-light">
                  <i className="fa fa-folder-open"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  async function createGroup() {
    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        name: groupName,
        user: { '_id': props.user._id }
      },
      url: `${Env.url}/api/v1/groups`
    })

    setGroupName()
    setShowNewGroupModal(false)
    setFetchedProjects(false)
  }

  async function createCollection() {
    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        name: collectionName,
        user: { '_id': props.user._id }
      },
      url: `${Env.url}/api/v1/collections`
    })

    setCollectionName()
    setShowNewCollectionModal(false)
    setFetchedProjects(false) // fetch projects again
  }

  async function addProjectsToGroup(evt, group) {
    // add selectedProjects to group
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        projects: [...group.projects, ...selectedProjects]
      },
      url: `${Env.url}/api/v1/groups/${group._id}`
    })

    setProjects([])
    setSelectedProjects([])
    setFetchedProjects(false)
  }

  async function transferProject() {
    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        targetUser: transferTargetName,
        project: selectedProjects[0]
      },
      url: `${Env.url}/api/v1/projects/transfer`
    })

    // not great - but functional
    document.location = '/'
  }

  let selectableGroups = groups.map((g, i) => (
    <Dropdown.Item
      key={`selectable-group-${i}`}
      onClick={e => addProjectsToGroup(e, g)}
      >
      {g.name}
    </Dropdown.Item>
  ))

  let transferAccountButton;
  if (props.user.username.endsWith('planpoint.io') && selectedProjects.length === 1) {
    // this is an admin feature ...
    transferAccountButton = (
      <button
        type="button"
        className="btn btn-outline-primary ml-2"
        onClick={() => setShowTransferAccountModal(true)}
        >
          Transfer project
        </button>
    )
  }

  return (
    <div className="app planpoint-dashboard">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar d-flex justify-content-between">
                <h1 className="page-title">Dashboard</h1>
                <div className="btn-toolbar d-flex flex-row align-items-center">
                  <Dropdown className={`mr-2 d-${selectedProjects.length > 0 ? 'block' : 'none'}`}>
                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                      Add {selectedProjects.length} project{selectedProjects.length > 1 ? 's' : ''} to group
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {selectableGroups}
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    type="button"
                    className={`btn btn-outline-primary d-${selectedProjects.length > 0 ? 'none' : 'block'}`}
                    onClick={() => setShowNewGroupModal(true)}
                    >
                    New group
                  </button>

                  <button
                    type="button"
                    className={`btn ml-2 btn-outline-primary d-${selectedProjects.length > 0 ? 'none' : 'block'}`}
                    onClick={() => setShowNewCollectionModal(true)}
                    >
                    New Collection
                  </button>

                  {transferAccountButton}
                </div>
              </header>

              <div className="page-section">
                <div className="row">
                  {groupCards}
                </div>
              </div>

              <div className="page-section">
                <div className="row">
                  {projectCards}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal show={showNewGroupModal} onHide={() => setShowNewGroupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New group</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Name your project group. The name will only be visible to you.</p>

          <div className="section-block mb-5">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Group #123"
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                />
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => createGroup()}>Create group</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showNewCollectionModal} onHide={() => setShowNewCollectionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New collection</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Name your new collection. This name will be displayed publicly.</p>

          <div className="alert alert-info alert-dismissible fade show">
            <strong>Note:</strong> You can access and manage all your collections on the <a href="/collections" className="alert-link">collection view</a> in the account menu.
          </div>

          <div className="section-block mb-5">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Collection #123"
                value={collectionName}
                onChange={e => setCollectionName(e.target.value)}
                />
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => createCollection()}>Create collection</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTransferAccountModal} onHide={() => setShowTransferAccountModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer project</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>You can transfer this project to another account.</p>

          <div className="alert alert-dark has-icon" role="alert">
            <div className="alert-icon">
              <span className="oi oi-bell"></span>
            </div>Please make sure that the account you transfer this project to has a provisioned subscription. A subscription is required to ensure this project will be paid for properly.
          </div>

          <div className="section-block mb-5">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="john.doe@example.com"
                value={transferTargetName}
                onChange={e => setTransferTargetName(e.target.value)}
                />
            </Form.Group>
          </div>
        </Modal.Body>


        <Modal.Footer>
          <Button variant="primary" onClick={() => transferProject()}>Transfer project</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
