import React, { useState, useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from './Environments';

import Auth from './views/Auth/Auth';
import Reset from './views/Reset/Reset';
import Restore from './views/Restore/Restore';
import Settings from './views/Settings/Settings';
import Dashboard from './views/Dashboard';
import Details from './views/Details';
import Exterior from './views/Exterior';
import Units from './views/Units';
import Gallery from './views/Gallery';
import Floors from './views/Floors';
import Forms from './views/Forms';
import Floorplan from './views/Floorplan';
import Embed from './views/Embed';
import Members from './views/Members';
import Stats from './views/Stats';
import Preview from './views/Preview';
import Hosted from './views/Hosted';
import GroupHosted from './views/GroupHosted';
import CollectionHosted from './views/CollectionHosted';
import Godmode from './views/Godmode';
import Collections from './views/Collections';
import CollectionSettings from './views/CollectionSettings';
import CollectionEmbed from './views/CollectionEmbed';
import Group from './views/Group';
import GroupEmbed from './views/GroupEmbed';
import GroupSettings from './views/GroupSettings';

export default function App() {
  const [user, setUser] = useState({});
  const [fetchedUser, setFetchedUser] = useState(false);

  const cookies = useMemo(() => new Cookies(), [])

  async function fetchMe() {
    try {
      const user = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/users/me`
      })

      setUser(user.data)
    } catch (e) {
      // do nothing in case there's a 404
    }
  }

  useEffect(() => {
    async function fetchCurrentUser() {
      const user = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/users/me`
      })

      setUser(user.data)
    }

    if (cookies.get('planpoint') && !fetchedUser) {
      fetchCurrentUser()
      setFetchedUser(true)
    }
  }, [cookies, fetchedUser])

  async function joinInvitation(user) {
    const projectId = window.location.pathname.split('/').pop()

    // sign in user
    signInUser(user)

    // fetch project
    const response = await axios({
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/projects/${projectId}`
    })

    let project = response.data
    let lastInvite = project.invites.filter(invite => invite.email === user.username).pop()

    if (lastInvite.level === 'Administrator') {
      project.administrators.push(user._id)
    } else {
      project.editors.push(user._id)
    }

    // patch project
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: project,
      url: `${Env.url}/api/v1/projects/${projectId}`
    })
  }

  function signInUser(user) {
    setUser(user)
    cookies.set('planpoint', user.token, { path: '/' });
  }

  function signOutUser() {
    setUser({})
    cookies.set('planpoint', '', { path: '/' });
  }

  let conditionalGodModeRoute;
  if (user && user.username && (user.isAdmin || user.username.endsWith('@planpoint.io'))) {
    conditionalGodModeRoute = (
      <Route path="/godmode">
        <Godmode
          user={user}
          signOutUser={() => signOutUser()}
          signInUser={user => signInUser(user)}
          fetchCurrentUser={() => fetchMe()}
          />
      </Route>
    )
  }

  let appContent;
  if (user.token) {
    appContent = (
      <Switch>
        <Route path="/groups/:groupId/embed">
          <GroupEmbed
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/groups/:groupId/details">
          <GroupSettings
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/groups/:groupId">
          <Group
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/collections/:collectionId/embed">
          <CollectionEmbed
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/collections/:collectionId">
          <CollectionSettings
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/collections">
          <Collections
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        {conditionalGodModeRoute}

        <Route path="/projects/:projectId/details">
          <Details
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/exterior">
          <Exterior
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/units">
          <Units
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/gallery">
          <Gallery
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/floors/:floorId">
          <Floorplan
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/floors">
          <Floors
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/forms">
          <Forms
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/embed">
          <Embed
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/members">
          <Members
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/projects/:projectId/stats">
          <Stats
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/settings">
          <Settings
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/preview">
          <Preview
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/dashboard">
          <Dashboard
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/c/:namespace/:hostName/:lang?">
          <CollectionHosted
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/g/:namespace/:hostName/:lang?">
          <GroupHosted
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/:namespace/:hostName/:lang?">
          <Hosted
            user={user}
            signOutUser={() => signOutUser()}
            fetchCurrentUser={() => fetchMe()}
            />
        </Route>

        <Route path="/">
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    )
  } else if (cookies.get('planpoint')) {
    // we're waiting for the user record to be fetched
  } else {
    appContent = (
      <Switch>
        <Route path="/signup">
          <Auth newUser signInUser={user => signInUser(user)} />
        </Route>

        <Route path="/join-project/:projectId">
          <Auth projectInvite newUser signInUser={user => joinInvitation(user)} />
        </Route>

        <Route path="/login">
          <Auth signInUser={user => signInUser(user)}/>
        </Route>

        <Route path="/reset">
          <Reset />
        </Route>

        <Route path="/restore">
          <Restore />
        </Route>

        <Route path="/g/:namespace/:hostName/:lang?">
          <GroupHosted />
        </Route>

        <Route path="/:namespace/:hostName/:lang?">
          <Hosted />
        </Route>

        <Route path="/">
          <Redirect to="/login" />
        </Route>

      </Switch>
    )
  }

  if (process.env.NODE_ENV === 'production' && !document.location.protocol.includes('https')) {
    document.location = document.location.href.replace('http://', 'https://')
  } else {
    return (
      <Router>
        {appContent}
      </Router>
    );
  }
}
