import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Papa from 'papaparse';

import Env from '../Environments';

import sampleCSV from '../assets/planpoint-unit-template.csv'

import Header from './components/Header';
import Aside from './components/Aside';
import UnitRow from './components/UnitRow';
import BreadcrumbMenu from './components/BreadcrumbMenu';

import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { ChevronDown, ChevronUp, Square, CheckSquare } from 'react-feather';

import plans from '../services/plans';

export default function Units(props) {
  const [loading, setLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('Loading ...')
  const [project, setProject] = useState({})
  const [floors, setFloors] = useState([])
  const [units, setUnits] = useState([])
  const [fetchedProject, setFetchedProject] = useState(false)
  const [fetchedFloors, setFetchedFloors] = useState(false)
  const [sortingProperty, setSortingProperty] = useState('name')
  const [sortingDirection, setSortingDirection] = useState('asc')
  const [importUnits, setImportUnits] = useState([])
  const [importFields, setImportFields] = useState([])
  const [importFieldMapping, setImportFieldMapping] = useState()
  const [confirmUnitImport, setConfirmUnitImport] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [customMapping, setCustomMapping] = useState(false)
  const [customMappingFloor, setCustomMappingFloor] = useState(false)
  const [customMappingName, setCustomMappingName] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState([])
  const [deletionInProgress, setDeletionInProgress] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  const { projectId } = useParams()

  const importFileInput = useRef()

  function toggleFileUpload() {
    importFileInput.current.click()
  }

  function handleFile(e, f) {
    const file = e.target.files[0]
    importFileInput.current.value = ''

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function(results, file) {
      	setImportUnits(results.data)
      	setImportFields(results.meta.fields)

        const defaultHeaders = ['floor','name','bedrooms','squareFeet','price','type','availability','parking','bathrooms','orientation','inclusions','deliveryDate','description']
        let containedHeaders = results.meta.fields.filter(f => defaultHeaders.indexOf(f) !== -1)

        if (containedHeaders.join(',') !== results.meta.fields.join(',')) {
          setImportFieldMapping({})
          setCustomMapping(true)
        } else {
          let defaultMapping = {}
          results.meta.fields.forEach(f => defaultMapping[f] = f)
          setImportFieldMapping(defaultMapping)
        }

      	setConfirmUnitImport(true)
      }
    })
  }

  async function uploadUnits() {
    try {
      setIsUploading(true)

      for (let unit of importUnits) {
        let floorId;
        let floor;
        for (let f of floors) {
          if (f.name === unit[importFieldMapping['floor']]) {
            floorId = f._id
            floor = f
            break
          }
        }

        let options;
        let oldVersions = floor.units.filter(u => u.name === unit[importFieldMapping['name']])
        if (oldVersions.length) {
          // if the unit already exists, PATCH this unit ...
          options = {
            method: 'PATCH',
            headers: {
              'content-type': 'application/json',
              'Authorization': cookies.get('planpoint')
            },
            data: {
              name: unit[importFieldMapping['name']],
              bedrooms: unit[importFieldMapping['bedrooms']],
              squareFeet: unit[importFieldMapping['squareFeet']],
              price: unit[importFieldMapping['price']],
              type: unit[importFieldMapping['type']],
              availability: unit[importFieldMapping['availability']],
              parking: unit[importFieldMapping['parking']],
              bathrooms: unit[importFieldMapping['bathrooms']],
              orientation: unit[importFieldMapping['orientation']],
              inclusions: unit[importFieldMapping['inclusions']],
              deliveryDate: unit[importFieldMapping['deliveryDate']],
              description: unit[importFieldMapping['description']]
            },
            url: `${Env.url}/api/v1/units/${oldVersions[0]._id}`
          }
        } else {
          // ... else, POST it
          options = {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              'Authorization': cookies.get('planpoint')
            },
            data: {
              floor: { _id: floorId },
              name: unit[importFieldMapping['name']],
              bedrooms: unit[importFieldMapping['bedrooms']],
              squareFeet: unit[importFieldMapping['squareFeet']],
              price: unit[importFieldMapping['price']],
              type: unit[importFieldMapping['type']],
              availability: unit[importFieldMapping['availability']],
              parking: unit[importFieldMapping['parking']],
              bathrooms: unit[importFieldMapping['bathrooms']],
              orientation: unit[importFieldMapping['orientation']],
              inclusions: unit[importFieldMapping['inclusions']],
              deliveryDate: unit[importFieldMapping['deliveryDate']],
              description: unit[importFieldMapping['description']],
              path: '',
              layoutUrl: '',
              downloadableAsset: '',
              embedCode: ''
            },
            url: `${Env.url}/api/v1/units`
          }
        }

        await axios(options)
      }
    } catch (e) {
      console.log(e)
      alert('Something went wrong while uploading your units. Please make sure to only upload units once you have set up the proper floors.')
    } finally {
      setConfirmUnitImport(false)
      setImportUnits([])
      setImportFields([])
      setFetchedFloors(false)
      setIsUploading(false)
    }
  }

  useEffect(() => {
    async function fetchProject() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      };

      const response = await axios(options)
      setProject(response.data)
    }

    async function fetchFloors() {
      let projectFloors = []
      let projectUnits = []

      for (let floor of project.floors) {
        setLoadingMessage(`Loading floor ${projectFloors.length+1}/${project.floors.length} ...`)

        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/floors/${floor}`
        })

        setFloors([...projectFloors, response.data])
        projectFloors.push(response.data)
        
        projectUnits = [...projectUnits, ...response.data.units]
        setUnits(projectUnits)
      }

      setLoading(false)
    }

    if (!fetchedProject) {
      fetchProject()
      setFetchedProject(true)
    }

    if (project._id && !fetchedFloors) {
      fetchFloors()
      setFetchedFloors(true)
    }
  }, [fetchedProject, project._id, fetchedFloors, cookies, projectId, project.floors])

  async function addUnit(floorId) {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        floor: { _id: floorId },
        name: '',
        bedrooms: '',
        squareFeet: '',
        price: '',
        type: '',
        availability: '',
        path: '',
        layoutUrl: '',
        downloadableAsset: '',
        embedCode: ''
      },
      url: `${Env.url}/api/v1/units`
    };

    await axios(options)
    setFetchedFloors(false)
  }

  let totalUnits = 0
  if (project) floors.forEach(f => totalUnits += f.units.length)

  let maxUnits = {}
  plans.plans[process.env.NODE_ENV].forEach(e => { maxUnits[e.priceId] = e.maxUnits })

  let maxSqft = {}
  plans.plans[process.env.NODE_ENV].forEach(e => { maxSqft[e.priceId] = e.maxSqft })

  let unitPossible = true;
  if (project && project.plan && !props.user.username.endsWith('planpoint.io')) {
    if (project.projectType === 'commercial') {
      // if this is a commercial project, it's about the collective area
      let totalSqft = floors.reduce((x, f) => x + f.units.reduce((y, u) => y + u.squareFeet, 0), 0)

      unitPossible = totalSqft <= maxSqft[project.plan.plan.id]
    } else if (project.projectType === 'rental') {
      // if this is a rental project, only available units count
      let availableUnits = 0
      floors.forEach(f => {
        availableUnits += f.units.filter(u => u.availability === 'Available').length
      })

      unitPossible = availableUnits <= maxUnits[project.plan.plan.id]
    } else {
      unitPossible = totalUnits <= maxUnits[project.plan.plan.id]
    }
  } else if (!props.user.username.endsWith('planpoint.io')) {
    unitPossible = false
  }

  function toggleSelectAll(evt) {
    evt.preventDefault()

    if (selectedUnits.length === units.length) {
      setSelectedUnits([])
    } else {
      setSelectedUnits(units)
    }
  }

  async function bulkDeleteUnits(evt) {
    evt.preventDefault()
    setDeletionInProgress(true)

    for (let unit of selectedUnits) {
      await axios({
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/units/${unit._id}`
      })
    }

    setSelectedUnits([])
    setFetchedFloors(false)
    setDeletionInProgress(false)
  }

  let floorCards;
  if (loading) {
    floorCards = (
      <div className="page-section text-center mt-5">
        <div className="spinner-border text-primary" role="status"></div>
        <p className="text-muted mt-2">{loadingMessage}</p>
      </div>
    )
  } else {
    floorCards = floors.sort((a, b) => a.name - b.name ).map((floor, i) => {
      let addUnitButton;
      if (unitPossible) {
        addUnitButton = (
          <button className="btn btn-outline-primary" onClick={() => addUnit(floor._id)}>
            Add unit
          </button>
        )
      } else if (totalUnits >= 399) {
        addUnitButton = (
          <a href="mailto:support@planpoint.io" className="btn btn-primary">
            Contact us
          </a>
        )
      } else {
        addUnitButton = (
          <a href="/settings" className="btn btn-primary">
            Upgrade plan
          </a>
        )
      }

      let bulkDeleteButton;
      if (selectedUnits.length) {
        let bulkDeleteLabel = 'Delete units';
        if (deletionInProgress) {
          bulkDeleteLabel = <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing ...</>
        }
        bulkDeleteButton = (
          <a
            href="/"
            onClick={e => bulkDeleteUnits(e)}
            className="btn btn-outline-danger mr-2"
            >
            {bulkDeleteLabel}
          </a>
        )
      }

      function sortBy(propertyName) {
        if (sortingProperty === propertyName) {
          setSortingDirection(sortingDirection === 'asc' ? 'desc' : 'asc')
        } else {
          setSortingProperty(propertyName)
          setSortingDirection('asc')
        }
      }

      function showChevron(property) {
        if (property === sortingProperty) {
          if (sortingDirection === 'asc') {
            return <ChevronUp size="16" />
          } else {
            return <ChevronDown size="16" />
          }
        }
      }

      function unitComparison(a, b) {
        const aValue = a[sortingProperty]
        const bValue = b[sortingProperty]

        const validNumberA = !isNaN(aValue) && !isNaN(parseFloat(aValue))
        const validNumberB = !isNaN(bValue) && !isNaN(parseFloat(bValue))

        if (sortingDirection === 'asc') {
          if (validNumberA && validNumberB) {
            return (parseInt(aValue) > parseInt(bValue)) ? 1 : -1
          } else {
            return (aValue > bValue) ? 1 : -1
          }
        } else {
          if (validNumberA && validNumberB) {
            return (parseInt(aValue) < parseInt(bValue)) ? 1 : -1
          } else {
            return (aValue < bValue) ? 1 : -1
          }
        }
      }

      let optionalParkingColumn;
      if (project.showParking) {
        optionalParkingColumn = (
          <th onClick={() => sortBy('parking')}>
            Parking {showChevron('parking')}
          </th>
        )
      }

      let optionalBathroomsColumn;
      if (project.showBathrooms) {
        optionalBathroomsColumn = (
          <th onClick={() => sortBy('bathrooms')}>
            Bathrooms {showChevron('bathrooms')}
          </th>
        )
      }

      let optionalOrientationColumn;
      if (project.showOrientation) {
        optionalOrientationColumn = (
          <th onClick={() => sortBy('orientation')}>
            Orientation {showChevron('orientation')}
          </th>
        )
      }

      let optionalInclusionsColumn;
      if (project.showInclusions) {
        optionalInclusionsColumn = (
          <th onClick={() => sortBy('inclusions')}>
            Inclusions {showChevron('inclusions')}
          </th>
        )
      }

      let optionalDeliveryDateColumn;
      if (project.deliveryDates) {
        optionalDeliveryDateColumn = (
          <th onClick={() => sortBy('deliveryDate')}>
            Date {showChevron('deliveryDate')}
          </th>
        )
      }

      return (
        <div className="card card-fluid" key={`floor-${i}`}>
          <div className="card-header border-0">
            <div className="d-flex align-items-center">
              <span className="mr-auto">{floor.name}</span>
              {bulkDeleteButton}
              {addUnitButton}
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">

              <thead>
                <tr>
                  <th>
                  <a
                    href='/'
                    onClick={e => toggleSelectAll(e)}
                    className={`btn btn-icon btn-${(selectedUnits.length === units.length) ? 'success' : 'light'}`}>
                    {(selectedUnits.length === units.length) ? <CheckSquare size='16' /> : <Square size='16' />}
                  </a>
                  </th>

                  <th onClick={() => sortBy('name')}>
                    Unit {showChevron('name')}
                  </th>

                  <th onClick={() => sortBy('type')}>
                    Model {showChevron('type')}
                  </th>

                  <th onClick={() => sortBy('squareFeet')}>
                    Area {showChevron('squareFeet')}
                  </th>

                  <th onClick={() => sortBy('bedrooms')}>
                    Bedrooms {showChevron('bedrooms')}
                  </th>

                  <th onClick={() => sortBy('price')}>
                    Price {showChevron('price')}
                  </th>

                  <th onClick={() => sortBy('availability')}>
                    Availability {showChevron('availability')}
                  </th>

                  {optionalParkingColumn}
                  {optionalBathroomsColumn}
                  {optionalOrientationColumn}
                  {optionalInclusionsColumn}
                  {optionalDeliveryDateColumn}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {floor.units.sort(unitComparison).map((unit, j) => (
                  <UnitRow
                    project={project}
                    floors={floors}
                    floor={floor}
                    unit={unit}
                    key={`unit-${i}-${j}`}
                    fetchFloors={() => setFetchedFloors(false)}
                    fetchProject={() => setFetchedProject(false)}
                    selectedUnits={selectedUnits}
                    setSelectedUnits={u => setSelectedUnits(u)}
                    />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    })
  }

  function updateUnitEmbed(field, value) {
    let newImportFieldMapping = Object.assign(importFieldMapping || {})

    if (value !== '') {
      newImportFieldMapping[value] = field
    } else {
      let cleanedImportFieldMapping = {}
      for (let k of Object.keys(newImportFieldMapping)) {
        if (newImportFieldMapping[k] !== field) {
          cleanedImportFieldMapping[k] = newImportFieldMapping[k]
        }
      }
      newImportFieldMapping = cleanedImportFieldMapping
    }

    setCustomMappingFloor(!!newImportFieldMapping['floor'])
    setCustomMappingName(!!newImportFieldMapping['name'])

    setImportFieldMapping(newImportFieldMapping)
  }

  let importFieldRows;
  if (importFields && importFields.length) {
    importFieldRows = importFields.map((importField, i) => (
      <tr key={`import-field-${i}`}>
        <td>{importField}</td>
        <td>
          <Form.Control
            custom
            as="select"
            onChange={e => updateUnitEmbed(importField, e.target.value)}
            >
            <option value=""></option>
            <option value="floor">floor</option>
            <option value="name">name</option>
            <option value="bedrooms">bedrooms</option>
            <option value="squareFeet">squareFeet</option>
            <option value="price">price</option>
            <option value="type">type</option>
            <option value="availability">availability</option>
            <option value="parking">parking</option>
            <option value="bathrooms">bathrooms</option>
            <option value="orientation">orientation</option>
            <option value="inclusions">inclusions</option>
            <option value="deliveryDate">deliveryDate</option>
            <option value="description">description</option>
          </Form.Control>
        </td>
      </tr>
    ))
  }

  let conditionalMappingTable;
  let conditionalMappingIntro;
  if (customMapping) {
    conditionalMappingTable = (
      <table className="table">
        <thead>
          <tr>
            <th>Column</th>
            <th>Info</th>
          </tr>
        </thead>
        <tbody>
          {importFieldRows}
        </tbody>
      </table>
    )

    conditionalMappingIntro = (
      <div className="alert alert-dark has-icon" role="alert">
        <div className="alert-icon">
          <span className="oi oi-bell"></span>
        </div>In order to continue, you need to map your columns to Planpoint unit properties.
      </div>
    )
  }

  let conditionalFloorWarning;
  if (!customMappingFloor && customMapping) {
    conditionalFloorWarning = (
      <div key='warning-you-need-floors' className="alert alert-warning" role="alert">
        You need to map a <strong>floor</strong> to every unit.
      </div>
    )
  }

  let conditionalNameWarning;
  if (!customMappingName && customMapping) {
    conditionalNameWarning = (
      <div key='warning-you-need-names' className="alert alert-warning" role="alert">
        You need to map a <strong>name</strong> field to every unit.
      </div>
    )
  }

  let conditionalSimilarityWarning;
  if (units && importUnits) {
    let similarUnits = []
    let similarUnitsMap = {}
    for (let newUnit of importUnits) {
      for (let unit of units) {
        const newUnitRe = new RegExp(`.${newUnit.name}`)
        const unitRe = new RegExp(`.${unit.name}`)
        if (newUnitRe.test(unit.name) || unitRe.test(newUnit.name)) {
          similarUnits.push({ old: unit, new: newUnit })
          
          if (similarUnitsMap[unit.name]) {
            similarUnitsMap[unit.name].push(newUnit.name)
          } else {
            similarUnitsMap[unit.name] = [newUnit.name]
          }

          break;
        }
      }
    }

    let similarUnitList = []
    // filter similarUnits that duplicate one another
    for (let pair of similarUnits) {
      const oldMatch = similarUnitsMap[pair.old.name] && similarUnitsMap[pair.old.name].includes(pair.new.name)
      const newMatch = similarUnitsMap[pair.new.name] && similarUnitsMap[pair.new.name].includes(pair.old.name)

      if (oldMatch && newMatch) {
        // do nothing - this is a duplicate
      } else {
        similarUnitList.push(
          <li key={`similar-unit-pair-${pair.old.name}=${pair.new.name}`}>
            Unit <strong>{pair.old.name}</strong> looks like unit <strong>{pair.new.name}</strong>.
          </li>
        )
      }
    }

    if (similarUnitList.length) {
      conditionalSimilarityWarning = (
        <div key='warning-similar-names' className="alert alert-warning" role="alert">
          It looks like your import file contains unit names that are similar (but not equal) to your existing units. Similar names will create new units if no unit with that name exists. Please double check your unit namens to avoid unnecessary unit duplication.

          <ul className="mt-4">{similarUnitList}</ul>
        </div>
      )
    }
  }

  let uploadUnitsCTA;
  if (isUploading) {
    uploadUnitsCTA = (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />{' '}
        Importing...
      </Button>
    )
  } else if (customMapping) {
    if (importFieldMapping && customMappingFloor && customMappingName) {
      uploadUnitsCTA = <Button variant="primary" onClick={() => uploadUnits()}>Confirm</Button>
    } else {
      uploadUnitsCTA = <Button variant="primary disabled" disabled>Confirm</Button>
    }
  } else {
    uploadUnitsCTA = (
      <Button variant="primary" onClick={() => uploadUnits()}>Confirm</Button>
    )
  }

  let conditionalFileBoundsWarning;
  if (importUnits.length && customMappingFloor) {
    const floorIds = importUnits.map(iu => iu[importFieldMapping['floor']])
    const uniqueFloorIds = Array.from(new Set(floorIds))
    // get existing floor Ids
    const actualFloorNames = floors.map(f => f.name)

    let unavailableFloors = []
    for (let fid of uniqueFloorIds) {
      if (!actualFloorNames.includes(fid)) unavailableFloors.push(fid)
    }

    if (unavailableFloors.length) {
      conditionalFileBoundsWarning = (
        <div key='warning-invalid-floors' className="alert alert-danger" role="alert">
          Your file contains floors <strong>{unavailableFloors.length}</strong> floors that have not been defined for this project, yet. Please make sure to create respective floors <i>before</i> importing any units.
        </div>
      )
    }
  }

  return (
    <div className="app planpoint-units">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='units' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <div className="d-flex justify-content-between">
                  <h1 className="page-title">Units</h1>
                  <div className="btn-toolbar d-flex flex-column">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={e => toggleFileUpload()}
                      >
                      Import
                    </button>
                    <small className="text-muted mt-1"><a href={sampleCSV} download className="text-muted">Download CSV template</a></small>
                  </div>
                </div>
              </header>

              <div className="page-section">
                {floorCards}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Form className="d-none">
        <Form.File
          ref={importFileInput}
          onChange={e => handleFile(e)}
          custom
          />
      </Form>

      <Modal show={confirmUnitImport} onHide={() => setConfirmUnitImport(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm import</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>You are about to import <strong>{importUnits.length}</strong> {importUnits.length === 1 ? 'unit' : 'units'} to this project. Would you like to continue?</p>

          {conditionalMappingIntro}

          {conditionalMappingTable}

          {conditionalFileBoundsWarning}
          {conditionalFloorWarning}
          {conditionalNameWarning}

          {conditionalSimilarityWarning}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmUnitImport(false)}>Cancel</Button>
          {uploadUnitsCTA}
        </Modal.Footer>
      </Modal>
    </div>
  )
}