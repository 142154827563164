import React, { useState } from 'react';
import axios from 'axios';

import Env from '../../../Environments';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function LoginForm(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  function handleSubmit(e) {
    e.preventDefault()

    async function signUpUser() {
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        data: {
          username: username,
          password: password
        },
        url: `${Env.url}/api/v1/users/login`
      };

      try {
        const user = await axios(options);

        if(user.data) {
          props.signInUser(user.data)
        } else {
          setShowWarning(true)
        }
      } catch (e) {
        setShowWarning(true)
      }
    }
    signUpUser()
  }

  let conditionalWarningBlock;
  if (showWarning) {
    conditionalWarningBlock = (
      <div className="alert alert-danger alert-dismissible fade show pr-4">
        <strong>Oh snap!</strong> Seems like the above email or password is not correct. Double check and try again!
      </div>
    )
  }

  return (
    <div className="Auth-col-body">
      <h3>Welcome back!</h3>
      <p>Enter your account details to sign in</p>

      <Form onSubmit={e => handleSubmit(e)} className="Auth-form">
        <Row>
          <Col>
            <Form.Group>
              <input
                type="email"
                id="inputUser"
                placeholder="you@email.com"
                autoFocus=""
                value={username}
                onChange={e => setUsername(e.target.value)}
                />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <input
              type="password"
                id="inputPassword"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className={`${showWarning ? 'is-invalid' : ''}`}
                placeholder="Password"
              />
            </Form.Group>
          </Col>
        </Row>

        {conditionalWarningBlock}

        <Button type="submit" className="w-100 Auth-form-submit">Sign In</Button>
      </Form>

      <div className="text-center Auth-form-redirect">
        <span>Don't have an account yet? <a href="/signup">Sign up here</a></span>
      </div>

      <div className="text-center Auth-form-redirect">
        <a href="/reset">Forgot password?</a>
      </div>
    </div>
  )
}
