import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import './CardSectionStyles.css'

import Env from '../../../Environments';

import plans from '../../../services/plans';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {
  const [projects, setProjects] = useState([]);
  const [fetchedProjects, setFetchedProjects] = useState(false);
  const [stripeError, setStripeError] = useState();
  const [updatingPaymentDetails, setUpdatingPaymentDetails] = useState(false);
  const [trials, setTrials] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(props.user.subscription);
  const [cardUpdateRequested, setCardUpdateRequested] = useState(false);
  const [couponCode, setCouponCode] = useState();

  const stripe = useStripe()
  const elements = useElements()

  const cookies = new Cookies();

  async function fetchProjects() {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/projects`
    };

    let response = await axios(options)
    setProjects(response.data)
    setFetchedProjects(true)
  }

  useEffect(() => {
    if (!fetchedProjects) fetchProjects()
  })

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && (subscription.status === 'active' || subscription.status === 'trialing')) {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          setStripeError(error);
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      setStripeError('Your card was declined.')
      setUpdatingPaymentDetails(false)
      setTrials(trials+1)
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function onSubscriptionComplete(result) {
    // Payment was successful.
    setUpdatingPaymentDetails(false)

    if (result.subscription.status === 'active' || result.subscription.status === 'trialing') {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      setStripeError('')
      setActiveSubscription(result.subscription)
      props.fetchCurrentUser()
    }
  }

  async function createSubscription(paymentMethod) {
    const customerId = props.user.stripeCustomer.id
    const paymentMethodId = paymentMethod.id
    const priceId = plans.plans[process.env.NODE_ENV][0].priceId // choose the smallest plan by default

    let subscriptionData = {
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      priceId: priceId,
      quantity: 0
    }

    if (couponCode) subscriptionData.coupon = couponCode

    return (
      axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: subscriptionData,
        url: `${Env.url}/api/v1/stripe/create-subscription`
      }).then((response) => {
        return response.data;
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
          isRetry: trials>0
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        setStripeError(error.message)
        setUpdatingPaymentDetails(false)
      })
    );
  }

  async function updateSubscriptionCard(paymentMethod) {
    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        customerId: props.user.stripeCustomer.id,
        paymentMethodId: paymentMethod.id
      },
      url: `${Env.url}/api/v1/stripe/edit-subscription`
    })

    setUpdatingPaymentDetails(false)
    setCardUpdateRequested(false)
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setUpdatingPaymentDetails(true)

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setStripeError(error.message)
      setUpdatingPaymentDetails(false)
    } else if (props.plan === '') {
      setStripeError('You need to select a plan in order to update your card details.')
      setUpdatingPaymentDetails(false)
    } else {
      await createSubscription(paymentMethod)
    }
  }

  async function handleSubmitUpdate(event) {
    event.preventDefault();
    setUpdatingPaymentDetails(true)

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setStripeError(error.message)
      setUpdatingPaymentDetails(false)
    } else if (props.plan === '') {
      setStripeError('You need to select a plan in order to update your card details.')
      setUpdatingPaymentDetails(false)
    } else {
      await updateSubscriptionCard(paymentMethod)
    }
  }

  async function updateSubscription() {
    // if (props.user.subscription && (props.plan !== props.user.subscription.plan.id)) {
    //   await axios({
    //     method: 'POST',
    //     headers: {
    //       'content-type': 'application/json',
    //       'Authorization': cookies.get('planpoint')
    //     },
    //     data: {
    //       subscriptionId: props.user.subscription.id,
    //       newPriceId: props.plan,
    //       quantity: props.user.subscription.quantity
    //     },
    //     url: `${Env.url}/api/v1/stripe/update-subscription`
    //   })

    //   props.fetchCurrentUser()
    // }

    // TODO: update Stripe customer information
    // await axios({
    //   method: 'POST',
    //   headers: {
    //     'content-type': 'application/json',
    //     'Authorization': cookies.get('planpoint')
    //   },
    //   url: `${Env.url}/api/v1/stripe/update-customer`
    // })

    props.submitForm()
  }

  let conditionalStripeError;
  if (stripeError) {
    conditionalStripeError = <p className="text-danger mt-2">{stripeError}</p>
  }

  let buttonLabel;
  if (updatingPaymentDetails) {
    buttonLabel = (
      <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing ...</>
    )
  } else {
    buttonLabel = 'Update Card Details'
  }

  let cancelSubscriptionButtonLabel;
  if (updatingPaymentDetails) {
    cancelSubscriptionButtonLabel = (
      <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing ...</>
    )
  } else {
    cancelSubscriptionButtonLabel = 'Cancel subscription'
  }

  function cancelSubscription() {
    setUpdatingPaymentDetails(true)

    return axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: { subscriptionId: activeSubscription.id },
      url: `${Env.url}/api/v1/stripe/cancel-subscription`
    }).then(response => {
      return response.data;
    }).then(cancelSubscriptionResponse => {
      // Display to the user that the subscription has been cancelled.
      setUpdatingPaymentDetails(false)
      setActiveSubscription({})
      props.fetchUser()
    });
  }

  let conditionalTrialInfo;
  if (activeSubscription && activeSubscription.items && activeSubscription.items.data.length && activeSubscription.items.data.map(i => i.plan.active).length) {
    let projectTotal = 0;
    projects.forEach(p => {
      if (p.plan) projectTotal += parseFloat(p.plan.price.unit_amount / 100)
    })

    if (activeSubscription.status === 'trialing') {
      const trialEnd = new Date(activeSubscription.trial_end * 1000)
      const today = new Date()
      const daysLeft = Math.ceil((trialEnd - today) / (1000 * 60 * 60 * 24));
      if (daysLeft >= 0) {
        conditionalTrialInfo = (
          <div className="alert alert-primary" role="alert">
            Days left in free trial: <strong>{daysLeft}</strong>
          </div>
        )
      }
    }

    let conditionalCardUpdateForm;
    if (cardUpdateRequested) {
      conditionalCardUpdateForm = (
        <>
          <hr />

          <form onSubmit={handleSubmitUpdate}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            {conditionalStripeError}

            <div className="mt-2">
              <input
                type="text"
                className="form-control w-25"
                placeholder="Do you have a coupon?"
                value={couponCode}
                onChange={e => setCouponCode(e.target.value)} required=""
                />
            </div>

            <button type="submit" disabled={!stripe || updatingPaymentDetails} className="btn btn-outline-primary text-nowrap mt-3">
              {buttonLabel}
            </button>
          </form>
        </>
      )
    }

    let updateBillingCTA;
    if (props.loading) {
      updateBillingCTA = (
        <button className="btn btn-outline-primary ml-2" type="button" disabled>
          <span className="spinner-border spinner-border-sm text-primary" role="status"></span> Loading ...
        </button>
      )
    } else {
      updateBillingCTA = (
        <button className="btn btn-outline-primary ml-2" type="button" onClick={() => updateSubscription()}>
          Update billing information
        </button>
      )
    }

    return (
      <>
        <div className="form-group">
          <div className="input-group h-auto">
            <div className="form-label-group w-50">
              <input
                type="text"
                name="paypalid"
                className="form-control"
                value={`Currently provisioned projects: ${props.user.projects.length}`}
                readOnly
              /> <label>Subscriptions</label>
            </div>
            <div className="input-group-append ml-auto">
              <span className="input-group-text text-success"><strong>{projectTotal.toLocaleString('en-CA', { style: 'currency', currency: 'CAD', minimumFractionDigits: 0 }).replace(/[A-Z]/g, '')}</strong></span>
            </div>
          </div>
        </div>

        {conditionalTrialInfo}

        <button className="btn btn-outline-danger" type="button" onClick={() => cancelSubscription()}>
          {cancelSubscriptionButtonLabel}
        </button>

        {updateBillingCTA}

        <button className="btn btn-outline-primary ml-2" type="button" onClick={() => setCardUpdateRequested(true)}>
          Update card information
        </button>

        {conditionalCardUpdateForm}
      </>
    )
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        {conditionalStripeError}

        <div className="mt-2">
          <input
            type="text"
            className="form-control w-25"
            placeholder="Do you have a coupon?"
            value={couponCode}
            onChange={e => setCouponCode(e.target.value)} required=""
            />
        </div>

        <button type="submit" disabled={!stripe || updatingPaymentDetails} className="btn btn-outline-primary text-nowrap mt-3">
          {buttonLabel}
        </button>
      </form>
    )
  }
}
