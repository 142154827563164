import React from 'react';

import Form from 'react-bootstrap/Form';

export default function GroupBreadcrumbMenu(props) {
  return (
    <Form.Control
      custom
      as="select"
      value={props.currentScope}
      className="project-scope-selector"
      onChange={e => document.location = `/groups/${props.groupId}/${e.target.value}`}
      >
      <option value="">Overview</option>
      <option value="details">Settings</option>
      <option value="embed">Embed</option>
    </Form.Control>
  )
}
