import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import planpointLogo from '../../assets/planpoint-logo_white.svg'

import plans from '../../services/plans';

export default function Header(props) {
  const [showProjectTypeModal, setShowProjectTypeModal] = useState(false)
  const [showProjectModal, setShowProjectModal] = useState(false)
  const [projectProcessing, setProjectProcessing] = useState(false)
  const [plan, selectPlan] = useState()
  const [projectType, setProjectType] = useState()

  const cookies = new Cookies();

  let planData = plans.filterPlans(
    plans.plans[process.env.NODE_ENV], projectType
  )

  const availablePlans = planData.map((pd, i) => (
    <div className="visual-picker has-peek mx-2" key={i} onClick={() => selectPlan(pd.priceId)}>
      <input
        type="radio"
        id={`vpc${i}`}
        name="subscription"
        checked={plan === pd.priceId}
        readOnly
        />

      <label className="visual-picker-figure" htmlFor="vpc03">
        <span className="visual-picker-content">
          <span className="h3 d-block">${pd.price}</span> <span>{pd.description}</span>
        </span>
      </label>
      <span className="visual-picker-peek">{pd.name}</span>
    </div>
  ))

  const projectTypeData = [{
    label: 'sales',
    title: 'Sales',
    price: 'from $69',
    description: 'For sales projects'
  }, {
    label: 'rental',
    title: 'Rental',
    price: 'from $39',
    description: 'For rental projects'
  }, {
    label: 'land',
    title: 'Land',
    price: 'from $69',
    description: 'For land projects'
  }, {
    label: 'commercial',
    title: 'Commercial',
    price: 'from $39',
    description: 'For commercial projects'
  }]
  const availableTypes = projectTypeData.map((pt, i) => (
    <div className="visual-picker visual-picker-lg has-peek mx-2 my-0" key={i} onClick={() => setProjectType(pt.label)}>
      <input
        type="radio"
        id={`vpc${i}`}
        name="subscription"
        checked={projectType === pt.label}
        readOnly
        />

      <label className="visual-picker-figure" htmlFor="vpc03">
        <span className="visual-picker-content">
          <span className="h3 d-block">{pt.title}</span> <span>{pt.description}</span>
        </span>
      </label>
    </div>
  ))

  async function createProject() {
    setProjectProcessing(true)
    
    const projectResponse = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: { projectType: (projectType || 'sales') },
      url: `${Env.url}/api/v1/projects`
    })

    // see if the user already has a subscription with the selected plan
    let existingPlan;
    for (let item of props.user.subscription.items.data) {
      if (item.plan.id === plan) {
        existingPlan = item
        break
      }
    }

    // if there's an existing plan, increase it's quantity
    // otherwise, set quantity to 1
    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        subscriptionId: props.user.subscription.id,
        newPriceId: plan,
        projectId: projectResponse.data._id,
        quantity: existingPlan ? existingPlan.quantity+1 : 1
      },
      url: `${Env.url}/api/v1/stripe/update-subscription`
    })

    // once the project has been created, refresh the user
    props.fetchCurrentUser()
    setShowProjectModal(false)

    // dirty hack ...
    document.location = '/dashboard'
  }

  function toggleModal(e) {
    e.preventDefault()

    setShowProjectTypeModal(true)
  }

  function proceedToPricing() {
    setShowProjectTypeModal(false)
    setShowProjectModal(true)
  }

  const subscription = props.user.subscription

  let projectTypeModalFooter;
  if (projectType) {
    projectTypeModalFooter = (
      <Modal.Footer>
        <Button variant="primary" onClick={() => proceedToPricing()}>Select plan</Button>
      </Modal.Footer>
    )
  } else {
    projectTypeModalFooter = (
      <Modal.Footer>
        <Button variant="primary" disabled>Select plan</Button>
      </Modal.Footer>
    )
  }

  let conditionalModalBody;
  let conditionalModalFooter;
  if (subscription) {
    conditionalModalBody = (
      <Modal.Body>
        <p>Pick a subscription for your project. The plan you pick for this project will not affect any other project subscription plan. You can change your plan for this project at any time.</p>

        <div className="section-block text-center my-5">
          {availablePlans}
        </div>
      </Modal.Body>
    )

    if (projectProcessing) {
      conditionalModalFooter = (
        <Modal.Footer>
          <Button disabled variant="primary">
            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Processing ...
          </Button>
        </Modal.Footer>
      )
    } else {
      conditionalModalFooter = (
        <Modal.Footer>
          <Button variant="primary" onClick={() => createProject()}>
            Agree and proceed
          </Button>
        </Modal.Footer>
      )
    }
  } else {
    conditionalModalBody = (
      <Modal.Body>
        <p>Before creating a project, you need to add a credit card to your account. Please visit your account settings to provide payment information.</p>
      </Modal.Body>
    )

    conditionalModalFooter = (
      <Modal.Footer>
        <a className="btn btn-primary" href="/settings?scope=billing">Go to settings</a>
      </Modal.Footer>
    )
  }

  return (
    <header className="app-header app-header-dark">
      <div className="top-bar">
        <div className="top-bar-brand">
          <a href="/">
            <img src={planpointLogo} height="24px" alt="" />
          </a>
        </div>

        <div className="top-bar-list">
          <div className="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
            <button className="hamburger hamburger-squeeze" type="button" data-toggle="aside" aria-label="Menu" aria-controls="navigation">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>

          <div className="top-bar-item top-bar-item-full">

          </div>

          <div className="top-bar-item top-bar-item-right px-0 d-none d-sm-flex">
            <ul className="header-nav nav">
              <li className="nav-item">
                <a className="nav-link" href="/" onClick={e => toggleModal(e)}>
                  <span className="oi oi-plus mr-1"></span> Create new project
                </a>
              </li>
            </ul>

            <div className="dropdown">
              <button className="btn-account d-none d-md-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="account-summary pr-lg-4 d-none d-lg-block">
                  <span className="account-name">My Account</span>
                  <span className="account-description">{props.user.username}</span>
                </span>
              </button>
              <div className="dropdown-menu">
                <div className="dropdown-arrow"></div>
                <h6 className="dropdown-header d-none d-md-block d-lg-none">{props.user.username || 'john.doe@example.com'}</h6>
                <a className="dropdown-item" href="/settings"><span className="dropdown-icon oi oi-person"></span> Settings</a>
                <a className="dropdown-item" href="/" onClick={() => props.signOutUser()}><span className="dropdown-icon oi oi-account-logout"></span> Logout</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="https://planpoint.helpscoutdocs.com/">Help Center</a>
                <a className="dropdown-item" href="https://planpoint.helpscoutdocs.com/">Contact us</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showProjectTypeModal} onHide={() => setShowProjectTypeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select your project type</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Pick a project type. Please note that your selection might impact the plans that are available for this project.</p>

          <div className="section-block text-center my-5">
            {availableTypes}
          </div>
        </Modal.Body>

        {projectTypeModalFooter}
      </Modal>

      <Modal
        show={showProjectModal}
        onHide={() => setShowProjectModal(false)}
        dialogClassName='modal-wide'
        >
        <Modal.Header closeButton>
          <Modal.Title>Before you continue ...</Modal.Title>
        </Modal.Header>

        {conditionalModalBody}
        {conditionalModalFooter}
      </Modal>
    </header>
  )
}
