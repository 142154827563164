import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import CustomSelect from './components/CustomSelect';
import Header from './components/Header';
import Aside from './components/Aside';
import BreadcrumbMenu from './components/BreadcrumbMenu';

import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme
import Pickr from '@simonwep/pickr';

import plans from '../services/plans';

export default function Details(props) {
  const [project, setProject] = useState()
  const [fetchedProject, setFetchedProject] = useState(false)
  const [showInclusions, setShowInclusions] = useState(false)
  const [showBathrooms, setShowBathrooms] = useState(false)
  const [showParking, setShowParking] = useState(false)
  const [showOrientation, setShowOrientation] = useState(false)
  const [showAvailability, setShowAvailability] = useState(false)
  const [hideSold, setHideSold] = useState(false)
  const [showPrices, setShowPrices] = useState(false)
  const [priceBeforeTax, setPriceBeforeTax] = useState(false)
  const [pricesStartingAt, setPricesStartingAt] = useState(false)
  const [showPriceFilter, setShowPriceFilter] = useState(false)
  const [showAreaFilter, setShowAreaFilter] = useState(false)
  const [showUnitDescription, setShowUnitDescription] = useState(false)
  const [showTaxes, setShowTaxes] = useState(false)
  const [rentalObject, setRentalObject] = useState(false)
  const [deliveryDates, setDeliveryDates] = useState(false)
  const [showBranding, setShowBranding] = useState(true)
  const [invertNav, setInvertNav] = useState(false)
  const [showFloorOverview, setShowFloorOverview] = useState(false)
  const [enableForms, setEnableForms] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [accentColor, setAccentColor] = useState('#000000');
  const [plan, selectPlan] = useState();
  const [pickr, setPickr] = useState();
  const [showToast, setShowToast] = useState(false);
  const [showDeletionToast, setShowDeletionToast] = useState(false);
  const [showProjectDeleteModal, setShowProjectDeleteModal] = useState(false);
  const [formsSendTo, setFormsSendTo] = useState('');
  const [formsSubject, setFormsSubject] = useState('');
  const [formsFromName, setFormsFromName] = useState('');
  const [formsMessage, setFormsMessage] = useState('');
  const [landOnly, setLandOnly] = useState(false);
  const [projectProcessing, setProjectProcessing] = useState(false);
  const [projectType, setProjectType] = useState();
  const [showUnitFinishes, setShowUnitFinishes] = useState(false);
  const [useCoordinates, setUseCoordinates] = useState(false);
  const [address, setAddress] = useState('');
  const [lon, setLon] = useState();
  const [lat, setLat] = useState();
  const [autocompletionResults, setAutocompletionResults] = useState([]);
  const [projectCollections, setProjectCollections] = useState([]);
  const [collections, setCollections] = useState([]);

  const [testingSFconnection, setTestingSFconnection] = useState(false);
  const [sfProjectId, setSFProjectId] = useState();
  const [sfKey, setSFKey] = useState();
  const [sfSecret, setSFSecret] = useState();
  const [sfPhase, setSFPhase] = useState();

  const cookies = useMemo(() => new Cookies(), [])

  const colorRef = useRef()

  const { projectId } = useParams()

  let planData = plans.filterPlans(
    plans.plans[process.env.NODE_ENV], projectType
  )

  useEffect(() => {
    async function fetchProject(initializePicker = false) {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/projects/${projectId}`
      };

      const response = await axios(options)
      setProject(response.data)
      if (response.data.plan) selectPlan(response.data.plan.plan.id)
      setShowAvailability(response.data.showAvailability)
      setShowInclusions(response.data.showInclusions)
      setShowBathrooms(response.data.showBathrooms)
      setShowParking(response.data.showParking)
      setShowOrientation(response.data.showOrientation)
      setHideSold(response.data.hideSold)
      setShowPrices(response.data.showPrices)
      setPriceBeforeTax(response.data.priceBeforeTax)
      setPricesStartingAt(response.data.pricesStartingAt)
      setShowPriceFilter(response.data.showPriceFilter)
      setShowAreaFilter(response.data.showAreaFilter)
      setShowUnitDescription(response.data.showUnitDescription)
      setShowTaxes(response.data.showTaxes)
      setProjectName(response.data.name)
      setRentalObject(response.data.rentalObject)
      setDeliveryDates(response.data.deliveryDates)
      setShowBranding(response.data.showBranding)
      setInvertNav(response.data.invertNav)
      setShowFloorOverview(response.data.showFloorOverview)
      setEnableForms(response.data.enableForms)
      setAccentColor(response.data.accentColor)
      setFormsSendTo(response.data.formsSendTo)
      setFormsSubject(response.data.formsSubject)
      setFormsFromName(response.data.formsFromName)
      setFormsMessage(response.data.formsMessage)
      setLandOnly(response.data.landOnly)
      setProjectType(response.data.projectType)
      setShowUnitFinishes(response.data.showUnitFinishes)
      setAddress(response.data.address)
      setUseCoordinates(response.data.lon || response.data.lat)
      setLon(response.data.lon)
      setLat(response.data.lat)
      setSFProjectId(response.data.sfProjectId)
      setSFKey(response.data.sfKey)
      setSFSecret(response.data.sfSecret)
      setSFPhase(response.data.sfPhase)
      setProjectCollections(response.data.collections)

      // fetch collections
      const cResponse = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/collections`
      })
      setCollections(cResponse.data)

      if (initializePicker) {
        const pickr = Pickr.create({
          el: colorRef.current,
          theme: 'nano',
          default: response.data.accentColor || '#000000',
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            interaction: {
              hex: true,
              rgba: true,
              // hsla: true,
              // hsva: true,
              // cmyk: true,
              input: true,
              save: true
            }
          }
        })

        pickr.on('change', (color, instance) => {
          setAccentColor(color.toHEXA().toString())
        })

        setPickr(pickr)
      }
    }

    if (!fetchedProject) {
      fetchProject(true)
      setFetchedProject(true)
    }
  }, [fetchedProject, cookies, projectId])

  async function updateProject(e) {
    e.preventDefault()

    let projectData = {
      showAvailability: showAvailability,
      showInclusions: showInclusions,
      showBathrooms: showBathrooms,
      showParking: showParking,
      showOrientation: showOrientation,
      hideSold: hideSold,
      showPrices: showPrices,
      priceBeforeTax: priceBeforeTax,
      pricesStartingAt: pricesStartingAt,
      showPriceFilter: showPriceFilter,
      showAreaFilter: showAreaFilter,
      showUnitDescription: showUnitDescription,
      showTaxes: showTaxes,
      rentalObject: rentalObject,
      deliveryDates: deliveryDates,
      showBranding: showBranding,
      invertNav: invertNav,
      showFloorOverview: showFloorOverview,
      name: projectName,
      accentColor: accentColor,
      enableForms: enableForms,
      formsSendTo: formsSendTo,
      formsSubject: formsSubject,
      formsFromName: formsFromName,
      formsMessage: formsMessage,
      landOnly: landOnly,
      projectType: projectType,
      showUnitFinishes: showUnitFinishes,
      address: address,
      lon: lon,
      lat: lat,
      collections: projectCollections,
      sfProjectId: sfProjectId,
      sfKey: sfKey,
      sfSecret: sfSecret,
      sfPhase: sfPhase
    }

    if (project.plan && project.plan.plan && project.plan.plan.id !== plan) {
      projectData.planUpdate = { from: project.plan, to: plan }
    }

    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: projectData,
      url: `${Env.url}/api/v1/projects/${project._id}`
    })

    setProject(response.data)

    setShowToast(true)

    // if the subscription changed, update accordingly
    if (project.plan.plan.id !== plan)  {
      // first, cancel exsiting subscription
      await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: {
          subscriptionId: props.user.subscription.id,
          newPriceId: project.plan.plan.id,
          projectId: project._id,
          quantity: Math.max(project.plan.quantity-1, 0)
        },
        url: `${Env.url}/api/v1/stripe/update-subscription`
      })

      // next, upgrade new subscription

      // see if the user already has a subscription with the selected plan
      let existingPlan;
      for (let item of props.user.subscription.items.data) {
        if (item.plan.id === plan) {
          existingPlan = item
          break
        }
      }

      // if there's an existing plan, increase it's quantity
      // otherwise, set quantity to 1
      await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: {
          subscriptionId: props.user.subscription.id,
          newPriceId: plan,
          projectId: project._id,
          quantity: existingPlan ? existingPlan.quantity+1 : 1
        },
        url: `${Env.url}/api/v1/stripe/update-subscription`
      })

      setFetchedProject(false)
    }
  }

  async function deleteProject() {
    setProjectProcessing(true)

    // let's determine the new quantity first
    let newQuantity = 0;
    for (let item of props.user.subscription.items.data) {
      if (project.plan && project.plan.plan && item.plan.id === project.plan.plan.id) newQuantity = item.quantity-1
    }

    // if the project has an attached plan, make sure to reduce the user's
    // subscription quantity by one
    if (project.plan) {
      await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: {
          subscriptionId: props.user.subscription.id,
          quantity: newQuantity,
          projectId: project._id
        },
        url: `${Env.url}/api/v1/stripe/update-subscription`
      })
    }

    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/projects/${project._id}`
    })

    document.location = '/'
  }

  function hasUnsavedChanges() {
    return project === undefined ||
    project.showAvailability !== showAvailability ||
    project.showInclusions !== showInclusions ||
    project.showBathrooms !== showBathrooms ||
    project.showParking !== showParking ||
    project.showOrientation !== showOrientation ||
    project.hideSold !== hideSold ||
    project.showPrices !== showPrices ||
    project.priceBeforeTax !== priceBeforeTax ||
    project.pricesStartingAt !== pricesStartingAt ||
    project.showPriceFilter !== showPriceFilter ||
    project.showAreaFilter !== showAreaFilter ||
    project.showUnitDescription !== showUnitDescription ||
    project.showTaxes !== showTaxes ||
    project.rentalObject !== rentalObject ||
    project.deliveryDates !== deliveryDates ||
    project.showBranding !== showBranding ||
    project.invertNav !== invertNav ||
    project.showFloorOverview !== showFloorOverview ||
    project.name !== projectName ||
    project.accentColor !== accentColor ||
    project.enableForms !== enableForms ||
    (project.plan && project.plan.plan.id !== plan) ||
    project.formsSendTo !== formsSendTo ||
    project.formsSubject !== formsSubject ||
    project.formsFromName !== formsFromName ||
    project.formsMessage !== formsMessage ||
    project.landOnly !== landOnly ||
    project.projectType !== projectType ||
    project.showUnitFinishes !== showUnitFinishes ||
    project.address !== address ||
    project.lon !== lon ||
    project.lat !== lat ||
    project.collections !== collections
  }

  function handleFileUpload(file) {
    async function uploadFile() {
      const data = new FormData()
      data.append('vfile', file)

      const options = {
        method: 'POST',
        headers: { 'Authorization': cookies.get('planpoint') },
        data: data,
        url: `${Env.url}/api/v1/projects/${project._id}/image`
      };

      const response = await axios(options)
      setProject(response.data)
    }
    uploadFile();
  }

  function updateAccentColor(newColor) {
    setAccentColor(newColor)
    if (newColor.length >= 7) pickr.setColor(newColor)
  }

  let conditionalDeleteButton;
  if (project && project.user === props.user._id) {
    conditionalDeleteButton = (
      <button
        type="button"
        className="btn btn-outline-danger mr-auto"
        onClick={() => setShowProjectDeleteModal(true)}
        >
        Delete Project
      </button>
    )
  }

  const availablePlans = planData.filter(p => !p.name.includes('Legacy')).map((pd, i) => (
    <div className="visual-picker has-peek mr-2" key={i} onClick={() => selectPlan(pd.priceId)}>
      <input
        type="radio"
        id={`project-plan-${i}`}
        name="project-plan"
        checked={plan === pd.priceId}
        readOnly
        />
      <label className="visual-picker-figure" htmlFor={`project-plan-${i}`}>
        <span className="visual-picker-content">
          <span className="tile tile-lg bg-primary"><i className={pd.icon}></i></span>
        </span>
      </label>
      <span className="visual-picker-peek">
        <p className="mb-0"><strong>{pd.name}</strong></p>
        <p className="text-muted mt-1">${pd.price}/month</p>
      </span>
    </div>
  ))

  const projectTypeData = [{
    label: 'sales',
    title: 'Sales',
    price: 'from $69',
    icon: 'fa fa-building'
  }, {
    label: 'rental',
    title: 'Rental',
    price: 'from $39',
    icon: 'fa fa-city'
  }, {
    label: 'land',
    title: 'Land',
    price: 'from $69',
    icon: 'fa fa-tree'
  }, {
    label: 'commercial',
    title: 'Commercial',
    price: 'from $69',
    icon: 'fa fa-hotel'
  }]
  const availableTypes = projectTypeData.map((pt, i) => (
    <div className="visual-picker has-peek mr-2" key={i} onClick={() => setProjectType(pt.label)}>
      <input
        type="radio"
        id={`project-type-${i}`}
        name="project-type"
        checked={projectType === pt.label}
        readOnly
        />
      <label className="visual-picker-figure" htmlFor={`project-type-${i}`}>
        <span className="visual-picker-content">
          <span className="tile tile-lg bg-primary"><i className={pt.icon}></i></span>
        </span>
      </label>
      <span className="visual-picker-peek">
        <p className="mb-0"><strong>{pt.title}</strong></p>
        <p className="text-muted mt-1">{pt.price}/month</p>
      </span>
    </div>
  ))

  let conditionalPlanInfo;
  if (project && plan && !props.user.username.endsWith('planpoint.io')) {
    const activePlans = planData.filter(p => p.priceId === plan)
    const activePlan = activePlans[0]

    if (activePlan) {
      conditionalPlanInfo = (
        <div className="alert alert-primary" role="alert">
          This plan will allow you to manage <strong>{activePlan.description.toLowerCase()}</strong>.
        </div>
      )
    }
  }

  let leadGenOptions;
  const formsOption = (
    <div className="form-row" key='formsOption'>
      <label htmlFor="tf1" className="col-md-3">Forms</label>
      <div className="col-md-9 mb-3">
        <label className="switcher-control switcher-control">
          <input
            type="checkbox"
            name="planpointBranding"
            className="switcher-input"
            checked={enableForms}
            onChange={() => setEnableForms(!enableForms)}
            />
          <span className="switcher-indicator"></span>
        </label>
      </div>
    </div>
  )

  if (enableForms) {
    const formsSendToOption = (
      <div className="form-row" key='formsSendToOption'>
        <label className="col-md-3">Send notifications to</label>
        <div className="col-md-9 mb-3">
          <input
            type="text"
            className="form-control"
            value={formsSendTo}
            onChange={e => setFormsSendTo(e.target.value)}
            />
          <small className="text-muted">Enter a comma separated list to send multiple notifications at once</small>
        </div>
      </div>
    )

    const formsSubjectOption = (
      <div className="form-row" key='formsSubjectOption'>
        <label className="col-md-3">Subject</label>
        <div className="col-md-9 mb-3">
          <input
            type="text"
            className="form-control"
            value={formsSubject}
            onChange={e => setFormsSubject(e.target.value)}
            />
        </div>
      </div>
    )

    const formsFromNameOption = (
      <div className="form-row" key='formsFromNameOption'>
        <label className="col-md-3">From name</label>
        <div className="col-md-9 mb-3">
          <input
            type="text"
            className="form-control"
            value={formsFromName}
            onChange={e => setFormsFromName(e.target.value)}
            />
        </div>
      </div>
    )

    const formsMessageOption = (
      <div className="form-row" key='formsMessageOption'>
        <label className="col-md-3">Message</label>
        <div className="col-md-9 mb-3">
          <textarea
            className="form-control"
            value={formsMessage}
            onChange={e => setFormsMessage(e.target.value)}
            />
          <small className="text-muted">Message will be displayed to leads after they signed up.</small>
        </div>
      </div>
    )

    leadGenOptions = [formsOption, formsSendToOption, formsSubjectOption, formsFromNameOption, formsMessageOption]
  } else {
    leadGenOptions = [formsOption]
  }

  let optionalStartingAt = (
    <>
      <div className="col-md-3"></div>
      <div className="col-md-3"></div>
    </>
  );
  if (showPrices) {
    optionalStartingAt = (
      <>
        <label htmlFor="tf1" className="col-md-3">Starting at</label>
        <div className="col-md-3 mb-3">
          <label className="switcher-control switcher-control">
            <input
              type="checkbox"
              name="pricesStartingAt"
              className="switcher-input"
              checked={pricesStartingAt}
              onChange={() => setPricesStartingAt(!pricesStartingAt)}
              />
            <span className="switcher-indicator"></span>
          </label>
        </div>
      </>
    )
  }

  let optionalPriceFilter = (
    <>
      <div className="col-md-3"></div>
      <div className="col-md-3"></div>
    </>
  );
  if (showPrices) {
    optionalPriceFilter = (
      <>
        <label htmlFor="tf1" className="col-md-3">Show price filter</label>
        <div className="col-md-3 mb-3">
          <label className="switcher-control switcher-control">
            <input
              type="checkbox"
              name="showPriceFilter"
              className="switcher-input"
              checked={showPriceFilter}
              onChange={() => setShowPriceFilter(!showPriceFilter)}
              />
            <span className="switcher-indicator"></span>
          </label>
        </div>
      </>
    )
  }

  let optionalPriceBeforeTax = (
    <>
      <div className="col-md-3"></div>
      <div className="col-md-3"></div>
    </>
  );
  if (showTaxes) {
    let taxTypeLabel = <span>Price before tax</span>
    if (project && project.projectType === 'commercial') {
      taxTypeLabel = <span>Gross lease</span>
    }
    optionalPriceBeforeTax = (
      <>
        <label htmlFor="tf1" className="col-md-3">{taxTypeLabel}</label>
        <div className="col-md-3 mb-3">
          <label className="switcher-control switcher-control">
            <input
              type="checkbox"
              name="priceBeforeTax"
              className="switcher-input"
              checked={priceBeforeTax}
              onChange={() => setPriceBeforeTax(!priceBeforeTax)}
              />
            <span className="switcher-indicator"></span>
          </label>
        </div>
      </>
    )
  }

  let optionalHideSold = (
    <>
      <div className="col-md-3"></div>
      <div className="col-md-3"></div>
    </>
  );
  if (showAvailability) {
    optionalHideSold = (
      <>
        <label htmlFor="tf1" className="col-md-3">Hide Sold units</label>
        <div className="col-md-3 mb-3">
          <label className="switcher-control switcher-control">
            <input
              type="checkbox"
              name="hideSold"
              className="switcher-input"
              checked={hideSold}
              onChange={() => setHideSold(!hideSold)}
              />
            <span className="switcher-indicator"></span>
          </label>
        </div>
      </>
    )
  }

  let optionalDeliveryDates = (
    <>
      <label htmlFor="tf1" className="col-md-3">Occupancy dates</label>
      <div className="col-md-3 mb-3">
        <label className="switcher-control switcher-control">
          <input
            type="checkbox"
            name="deliveryDates"
            className="switcher-input"
            checked={deliveryDates}
            onChange={() => setDeliveryDates(!deliveryDates)}
            />
          <span className="switcher-indicator"></span>
        </label>
      </div>
    </>
  )

  let landOnlyToggle;
  if (project && (project.previewMode || projectType === 'land')) {
    landOnlyToggle = (
      <div>
        <hr className="mt-4 mb-3" />

        <div className="form-row">
          <label htmlFor="tf1" className="col-md-3 mt-2">Land only</label>
          <div className="col-md-9 mt-2">
            <label className="switcher-control switcher-control">
              <input
                type="checkbox"
                name="planpointBranding"
                className="switcher-input"
                checked={landOnly}
                onChange={() => setLandOnly(!landOnly)}
                />
              <span className="switcher-indicator"></span>
            </label>
          </div>
        </div>
      </div>
    )
  }

  let deletionModalFooter;
  if (projectProcessing) {
    deletionModalFooter = (
      <Modal.Footer>
        <Button variant="secondary" disabled>Cancel</Button>
        <Button variant="danger" disabled>
          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Processing ...
        </Button>
      </Modal.Footer>
    )
  } else {
    deletionModalFooter = (
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowProjectDeleteModal(false)}>Cancel</Button>
        <Button variant="danger" onClick={() => deleteProject()}>Yes, I'm sure</Button>
      </Modal.Footer>
    )
  }

  let conditionalCoordinateFields;
  if (useCoordinates) {
    conditionalCoordinateFields = [
      <div className="col-md-4 mb-3">
        <input
          type="number"
          placeholder="Longitude"
          className="form-control"
          value={lon}
          onChange={e => setLon(e.target.value)}
          />
      </div>,
      <div className="col-md-4 mb-3">
        <input
          type="number"
          placeholder="Latitude"
          className="form-control"
          value={lat}
          onChange={e => setLat(e.target.value)}
          />
      </div>
    ]
  }

  async function autoCompleteAddress(text) {
    setAddress(text)
    setAutocompletionResults([])

    // get more info about the place
    const gc = new window.google.maps.Geocoder()
    const coordinates = await gc.geocode({ address: text })

    setLat(coordinates.results[0].geometry.location.lat())
    setLon(coordinates.results[0].geometry.location.lng())
  }

  async function googleAutocomplete(text) {
    setAddress(text)

    const places = window.google.maps.places
    const suggestions = await new places.AutocompleteService().getPlacePredictions({ input: text })
    setAutocompletionResults(suggestions.predictions)
  }

  let autocompletionItems = [];
  if (autocompletionResults.length) {
    let autocompletionResultList = []
    for (let result of autocompletionResults) {
      autocompletionResultList.push(
        <div className="list-group-item" onClick={e => autoCompleteAddress(result.description)}>
          <div className="list-group-item-body">
            <h4 className="list-group-item-title">
              <span >{result.structured_formatting.main_text}</span>
            </h4>
            <p className="list-group-item-text">{result.structured_formatting.secondary_text}</p>
          </div>
        </div>
      )
    }

    autocompletionItems = (
      <div className="card card-fluid mt-2 places-autocompletion">
        <div className="list-group list-group-flush list-group-divider">
          {autocompletionResultList}
        </div>
      </div>
    )
  }

  function updateProjectCollections(selectedCollections) {
    let collectionMap = {}
    for (let collection of collections) {
      collectionMap[collection._id] = collection
    }

    let newSelection = []
    for (let sc of selectedCollections) {
      newSelection.push(collectionMap[sc.value])
    }

    setProjectCollections(newSelection)
  }

  async function connectToSalesforce() {
    setTestingSFconnection(true)

    const response = await axios({
      method: 'GET',
      params: {
        'sfProjectId': sfProjectId,
        'sfKey': sfKey,
        'sfSecret': sfSecret,
        'sfPhase': sfPhase
      },
      url: `${Env.url}/api/v1/projects/sf`
    })

    if (response.data) {
      let floorMap = {}
      for (let u of response.data) {
        if (u['Floor__c']) {
          floorMap[u['Floor__c']] = (floorMap[u['Floor__c']] || 0) + 1
        }
      }
      alert(`Found ${response.data.length} units across ${Object.keys(floorMap).length} floors.`)
    } else {
      alert(`Unable to connect to Salesforce. Please double-check your project details and try again or reach out to us via support@planpoint.io!`)
    }

    setTestingSFconnection(false)
  }

  let sfButtonLabel;
  if (testingSFconnection) {
     sfButtonLabel = [
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
      <span>&nbsp; Testing connection ...</span>
     ]
  } else {
    sfButtonLabel = <span>Test connection</span>
  }

  let conditionalSFWarning;
  if (sfProjectId || sfKey || sfSecret) {
    conditionalSFWarning = (
      <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading">Caution!</h4>
        <p>Saving this project with a complete Salesforce integration will cause all floors and units to be wiped and replaced periodically with new data from Salesforce. While you can modify your floors and units, you must expect the data to be overridden on the next sync.</p>
        <hr />
        <p className="mb-0">Be aware that this project will sync immediately after you save any changes within this settings panel.</p>
      </div>
    )
  }

  let sfSettings;
  if (props.user && props.user.username && (props.user.isAdmin || props.user.username.endsWith('@planpoint.io'))) {
    sfSettings = [
      <hr className="mt-3 mb-4" />,
      <h5>Salesforce</h5>,
      <p className="text-muted mb-4">Integrate your project with Salesforce</p>,
      <div className="list-group list-group-flush">
        <div className="form-row">
          <label className="col-md-3">Project ID</label>
          <div className="col-md-9 mb-3">
            <input
              type="text"
              className="form-control"
              value={sfProjectId}
              onChange={e => setSFProjectId(e.target.value)}
              />
          </div>
        </div>

        <div className="form-row">
          <label className="col-md-3">API Key</label>
          <div className="col-md-9 mb-3">
            <input
              type="text"
              className="form-control"
              value={sfKey}
              onChange={e => setSFKey(e.target.value)}
              />
          </div>
        </div>

        <div className="form-row">
          <label className="col-md-3">API Secret</label>
          <div className="col-md-9 mb-3">
            <input
              type="text"
              className="form-control"
              value={sfSecret}
              onChange={e => setSFSecret(e.target.value)}
              />
          </div>
        </div>

        <div className="form-row">
          <label className="col-md-3">Phase</label>
          <div className="col-md-9 mb-3">
            <input
              type="text"
              className="form-control"
              value={sfPhase}
              onChange={e => setSFPhase(e.target.value)}
              />
          </div>
        </div>

        <div className="form-row">
          <label className="col-md-3"></label>
          <div className="col-md-9 mb-3">
            <Button
              variant="outline-primary w-100"
              onClick={() => connectToSalesforce()}
              >
                {sfButtonLabel}
            </Button>
          </div>
        </div>

        {conditionalSFWarning}
      </div>
    ]
  }

  return (
    <div className="app planpoint-details">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='details' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">Project Details</h1>
              </header>

              <div className="page-section">
                <div className="card">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card-body">
                        <form onSubmit={e => updateProject(e)}>
                          <fieldset>
                            <h5>Settings</h5>
                            <p className="text-muted mb-4">Project defaults and globals</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label htmlFor="input01" className="col-md-3">Cover image</label>
                                <div className="col-md-9 mb-3">
                                  <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="input01" onChange={e => handleFileUpload(e.target.files[0])} />
                                    <label className="custom-file-label" htmlFor="input01">Choose cover</label>
                                  </div>
                                  <small className="text-muted">Upload a new cover image (max. 2MB)</small>
                                </div>
                              </div>

                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Project name</label>
                                <div className="col-md-9 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="tf1"
                                    aria-describedby="tf1Help"
                                    value={projectName}
                                    onChange={e => setProjectName(e.target.value)}
                                    />
                                </div>
                              </div>

                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Accent color</label>
                                <div className="col-md-9 mb-3">
                                  <div id="colorpicker8" className="input-group input-group-alt" data-toggle="colorpicker" data-extensions='[ { "name": "swatches", "colors": { "black": "#000000", "white": "#ffffff", "red": "#FF0000", "default": "#777777", "primary": "#337ab7", "success": "#5cb85c", "info": "#5bc0de", "warning": "#f0ad4e", "danger": "#d9534f" }, "namesAsValues": true } ]'>
                                    <input type="text" className="form-control" value={accentColor} onChange={e => updateAccentColor(e.target.value)} />
                                    <span className="input-group-append">
                                      <span className="input-group-text colorpicker-input-addon">
                                        <input
                                          type="text"
                                          placeholder="#324A19"
                                          ref={colorRef}
                                          value={accentColor}
                                          onChange={e => setAccentColor(e.target.value)}
                                          />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {sfSettings}

                            <hr className="mt-3 mb-4" />

                            <h5>Prices</h5>
                            <p className="text-muted mb-4">Show pricing information to potential customers</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Prices</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showPrices"
                                      className="switcher-input"
                                      checked={showPrices}
                                      onChange={() => setShowPrices(!showPrices)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>

                                <label htmlFor="tf1" className="col-md-3">Taxes</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showTaxes"
                                      className="switcher-input"
                                      checked={showTaxes}
                                      onChange={() => setShowTaxes(!showTaxes)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>
                              </div>

                              <div className="form-row">
                                {optionalStartingAt}
                                {optionalPriceBeforeTax}
                              </div>

                              <div className="form-row">
                                {optionalPriceFilter}
                              </div>
                            </div>

                            <hr className="mt-3 mb-4" />

                            <h5>Availabilities</h5>
                            <p className="text-muted mb-4">Show availability information to potential customers</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Availabilities</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showAvailability"
                                      className="switcher-input"
                                      checked={showAvailability}
                                      onChange={() => setShowAvailability(!showAvailability)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>

                                <label htmlFor="tf1" className="col-md-3">Rental project</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="rentalProject"
                                      className="switcher-input"
                                      checked={rentalObject}
                                      onChange={() => setRentalObject(!rentalObject)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>
                              </div>

                              <div className="form-row">
                                {optionalHideSold}
                                {optionalDeliveryDates}
                              </div>
                            </div>

                            <hr className="mt-3 mb-4" />

                            <h5>Amenities</h5>
                            <p className="text-muted mb-4">Manage additional unit information</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Inclusions</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showInclusions"
                                      className="switcher-input"
                                      checked={showInclusions}
                                      onChange={() => setShowInclusions(!showInclusions)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>

                                <label htmlFor="tf1" className="col-md-3">Orientation</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showOrientation"
                                      className="switcher-input"
                                      checked={showOrientation}
                                      onChange={() => setShowOrientation(!showOrientation)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>
                              </div>

                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Parking</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showParking"
                                      className="switcher-input"
                                      checked={showParking}
                                      onChange={() => setShowParking(!showParking)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>

                                <label htmlFor="tf1" className="col-md-3">Bathrooms</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showBathrooms"
                                      className="switcher-input"
                                      checked={showBathrooms}
                                      onChange={() => setShowBathrooms(!showBathrooms)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>
                              </div>

                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Show area filter</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showAreaFilter"
                                      className="switcher-input"
                                      checked={showAreaFilter}
                                      onChange={() => setShowAreaFilter(!showAreaFilter)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>

                                <label htmlFor="tf1" className="col-md-3">Show description</label>
                                <div className="col-md-3 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="showUnitDescription"
                                      className="switcher-input"
                                      checked={showUnitDescription}
                                      onChange={() => setShowUnitDescription(!showUnitDescription)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <hr className="mt-3 mb-4" />

                            <h5>Lead Generation</h5>
                            <p className="text-muted mb-4">Add a form to your plan viewer and edit the recipients.</p>
                            <div className="list-group list-group-flush">
                              {leadGenOptions}
                            </div>

                            <hr className="mt-3 mb-4" />

                            <h5>Collection settings</h5>
                            <p className="text-muted mb-4">Define an address and/or coordinates to properly place this project on a collection map.</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label className="col-md-3">Address</label>
                                <div className="col-md-9 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={address}
                                    onChange={e => googleAutocomplete(e.target.value)}
                                    />

                                  {autocompletionItems}
                                </div>
                              </div>

                              <div className="form-row" key='formsOption'>
                                <label className="col-md-3">Use coordinates</label>
                                <div className="col-md-1 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="useCoordinates"
                                      className="switcher-input"
                                      checked={useCoordinates}
                                      onChange={() => setUseCoordinates(!useCoordinates)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>
                                {conditionalCoordinateFields}
                              </div>

                              <div className="form-row">
                                <label className="col-md-3">Collections</label>
                                <div className="col-md-9 mb-3">
                                <CustomSelect
                                  value={(projectCollections || []).map(c => (
                                    { value: c._id, label: c.name }
                                  ))}
                                  options={(collections || []).map(c => (
                                    { value: c._id, label: c.name }
                                  ))}
                                  isSearchable={false}
                                  isClearable={false}
                                  hideSelectedOptions={false}
                                  isMulti={true}
                                  placeholder="None"
                                  onChange={e => updateProjectCollections(e)}
                                  />
                                </div>
                              </div>
                            </div>

                            <hr className="mt-3 mb-4" />

                            <h5>Project type</h5>
                            <p className="text-muted">Select the type of project that is right for you. Different types of projects offer different features and plans.</p>

                            <div className="section-block text-center text-sm-left">
                              <div className="d-flex flex-row">
                                {availableTypes}
                              </div>

                              {landOnlyToggle}
                            </div>

                            <hr className="mt-4 mb-3" />

                            <h5>Payment</h5>
                            <p className="text-muted">Select a subscription size that is right for you. Keep in mind that the rate that you choose here will <strong>not affect other projects</strong>.</p>

                            <div className="section-block text-center text-sm-left d-flex flex-row">
                              {availablePlans}
                            </div>

                            {conditionalPlanInfo}

                            <hr className="mt-4 mb-3" />

                            <div className="form-row">
                              <label htmlFor="tf1" className="col-md-3 mt-2">Branding</label>
                              <div className="col-md-3 mt-2">
                                <label className="switcher-control switcher-control">
                                  <input
                                    type="checkbox"
                                    name="planpointBranding"
                                    className="switcher-input"
                                    checked={showBranding}
                                    onChange={() => setShowBranding(!showBranding)}
                                    />
                                  <span className="switcher-indicator"></span>
                                </label>
                              </div>
                              <div className="col-md-6 mt-2">
                                <p className="text-muted">
                                  <small>Display a Planpoint badge on your projects</small>
                                </p>
                              </div>
                            </div>
                            <div className="form-row">
                              <label htmlFor="tf1" className="col-md-3 mt-2">Invert navigation</label>
                              <div className="col-md-3 mt-2">
                                <label className="switcher-control switcher-control">
                                  <input
                                    type="checkbox"
                                    name="invertNavigation"
                                    className="switcher-input"
                                    checked={invertNav}
                                    onChange={() => setInvertNav(!invertNav)}
                                    />
                                  <span className="switcher-indicator"></span>
                                </label>
                              </div>
                              <div className="col-md-6 mt-2">
                                <p className="text-muted">
                                  <small>Useful for very high, portrait unit images</small>
                                </p>
                              </div>
                            </div>
                            <div className="form-row">
                              <label htmlFor="tf1" className="col-md-3 mt-2">Show floor overview</label>
                              <div className="col-md-3 mt-2">
                                <label className="switcher-control switcher-control">
                                  <input
                                    type="checkbox"
                                    name="showFloorOverview"
                                    className="switcher-input"
                                    checked={showFloorOverview}
                                    onChange={() => setShowFloorOverview(!showFloorOverview)}
                                    />
                                  <span className="switcher-indicator"></span>
                                </label>
                              </div>
                              <div className="col-md-6 mt-2">
                                <p className="text-muted">
                                  <small>Shows tooltips & available unit count on top of floors</small>
                                </p>
                              </div>
                            </div>
                            <div className="form-row">
                              <label htmlFor="tf1" className="col-md-3 mt-2">Show finishes</label>
                              <div className="col-md-3 mt-2">
                                <label className="switcher-control switcher-control">
                                  <input
                                    type="checkbox"
                                    name="showUnitFinishes"
                                    className="switcher-input"
                                    checked={showUnitFinishes}
                                    onChange={() => setShowUnitFinishes(!showUnitFinishes)}
                                    />
                                  <span className="switcher-indicator"></span>
                                </label>
                              </div>
                              <div className="col-md-6 mt-2">
                                <p className="text-muted">
                                  <small>Shows a gallery of finish options on units</small>
                                </p>
                              </div>
                            </div>

                            <hr />

                            <div className="form-actions">
                              {conditionalDeleteButton}

                              <button type="submit" className="btn btn-primary ml-auto" disabled={!hasUnsavedChanges()}>
                                Update Settings
                              </button>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bg-secondary h-100 rounded">
                        <img className="w-100 rounded-right" src={(project || {}).projectImageUrl} alt={`${(project || {}).projectImageUrl} cover`} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Toast
        style={{ position: 'fixed', top: '4.5rem', right: '1rem', zIndex: '1050' }}
        onClose={() => setShowToast(false)} show={showToast}
        delay={3000} autohide
        >
        <Toast.Header>
          <strong className="mr-auto">Info</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>The project has been updated successfully.</Toast.Body>
      </Toast>

      <Toast
        style={{ position: 'fixed', top: '4.5rem', right: '1rem', zIndex: '1050' }}
        onClose={() => setShowDeletionToast(false)} show={showDeletionToast}
        >
        <Toast.Header>
          <strong className="mr-auto">Info</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>The project has been deleted successfully.</Toast.Body>
      </Toast>

      <Modal show={showProjectDeleteModal} onHide={() => setShowProjectDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>You are about to permanently delete this project and all it's units, floors and forms. This action can not be undone.</p>
        </Modal.Body>

        {deletionModalFooter}
      </Modal>
    </div>
  )
}
