import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Header from '../components/Header';
import Aside from '../components/Aside';
// import CollectionBreadcrumbMenu from './components/CollectionBreadcrumbMenu';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function CollectionSettings(props) {
  const [collection, setCollection] = useState()
  const [fetchedCollection, setFetchedCollection] = useState(false)
  const [collectionName, setCollectionName] = useState()
  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false)
  const [collectionProcessing, setCollectionProcessing] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(9);
  const [collectionAddress, setCollectionAddress] = useState();
  const [lon, setLon] = useState();
  const [lat, setLat] = useState();
  const [collectionMap, setCollectionMap] = useState(9);
  const [autocompletionResults, setAutocompletionResults] = useState([]);

  const cookies = useMemo(() => new Cookies(), [])

  const { collectionId } = useParams()

  useEffect(() => {
    async function fetchCollection() {
      let response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/collections/${collectionId}`
      })

      setCollection(response.data)
      setCollectionName(response.data.name)
      setZoomLevel(response.data.zoomLevel || zoomLevel)
      setCollectionAddress(response.data.address)
      setLon(response.data.lon)
      setLat(response.data.lat)
      initializeMapbox(response.data)
    }

    if (!fetchedCollection) {
      fetchCollection()
      setFetchedCollection(true)
    }
  }, [fetchedCollection, cookies, props.user._id, collectionId, zoomLevel])

  function initializeMapbox(c) {
    let coordinates = [ -123.113889, 49.260833 ]
    if (c && c.lon && c.lat) {
      coordinates = [ c.lon, c.lat ]
    } else {
      for (let project of c.projects) {
        if (project.lon && project.lat) coordinates = [ project.lon, project.lat ]
      }
    }

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXM5N2NrIiwiYSI6ImNrczhwa25ndTB4MXIybnJuaGl2NXA0d2EifQ.Okc9gC3_brAyb8A4jIsyfQ';
    const map = new mapboxgl.Map({
      container: `mapbox-${c._id}`,
      style: 'mapbox://styles/mapbox/light-v10',
      center: coordinates,
      zoom: c.zoomLevel
    });
    setCollectionMap(map)

    const projectGeo = c.projects.map(p => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [p.lon, p.lat]
      },
      properties: {
        title: p.name,
        description: p.address
      }
    }))

    const groupGeo = c.groups.map(g => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [g.lon, g.lat]
      },
      properties: {
        title: g.name,
        description: g.address
      }
    }))

    const geojson = {
      type: 'FeatureCollection',
      features: [...projectGeo, ...groupGeo]
    };

    // add markers to map
    geojson.features.forEach(function(marker) {

      // create a HTML element for each feature
      var el = document.createElement('div');
      el.className = 'marker';

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML('<h3 className="card-title">' + marker.properties.title + '</h3><p className="card-subtitle text-muted">' + marker.properties.description + '</p>'))
        .addTo(map);
    });
  }

  async function deleteCollection() {
    setCollectionProcessing(true)

    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/collections/${collection._id}`
    })

    // HACK
    document.location = '/'
    setCollectionProcessing(false)
  }

  async function autoCompleteAddress(text) {
    setAutocompletionResults([])

    // get more info about the place
    const gc = new window.google.maps.Geocoder()
    const coordinates = await gc.geocode({ address: text })

    setLat(coordinates.results[0].geometry.location.lat())
    setLon(coordinates.results[0].geometry.location.lng())

    updateCollectionAddress(text, lon, lat)
  }

  async function googleAutocomplete(text) {
    setCollectionAddress(text)

    const places = window.google.maps.places
    const suggestions = await new places.AutocompleteService().getPlacePredictions({ input: text })
    setAutocompletionResults(suggestions.predictions)
  }

  let autocompletionItems = [];
  if (autocompletionResults.length) {
    let autocompletionResultList = []
    for (let result of autocompletionResults) {
      autocompletionResultList.push(
        <div className="list-group-item" onClick={e => autoCompleteAddress(result.description)}>
          <div className="list-group-item-body">
            <h4 className="list-group-item-title">
              <span >{result.structured_formatting.main_text}</span>
            </h4>
            <p className="list-group-item-text">{result.structured_formatting.secondary_text}</p>
          </div>
        </div>
      )
    }

    autocompletionItems = (
      <div className="card card-fluid mt-2 places-autocompletion">
        <div className="list-group list-group-flush list-group-divider">
          {autocompletionResultList}
        </div>
      </div>
    )
  }

  async function updateCollection(evt) {
    evt.preventDefault()

    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        name: collectionName,
        address: collectionAddress,
        lon: lon,
        lat: lat,
        zoomLevel: zoomLevel
      },
      url: `${Env.url}/api/v1/collections/${collection._id}`
    })

    setCollection(response.data)
    initializeMapbox(response.data)
  }

  function hasUnsavedChanges() {
    return collection && (
      zoomLevel !== collection.zoomLevel ||
      lon !== collection.lon ||
      lat !== collection.lat ||
      collectionAddress !== collection.address ||
      collectionName !== collection.name
    )
  }

  let conditionalDeleteButton;
  if (collection && collection.user === props.user._id) {
    conditionalDeleteButton = (
      <button
        type="button"
        className="btn btn-outline-danger mr-auto"
        onClick={() => setShowCollectionDeleteModal(true)}
        >
        Delete Collection
      </button>
    )
  }

  let deletionModalFooter;
  if (collectionProcessing) {
    deletionModalFooter = (
      <Modal.Footer>
        <Button variant="secondary" disabled>Cancel</Button>
        <Button variant="danger" disabled>
          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Processing ...
        </Button>
      </Modal.Footer>
    )
  } else {
    deletionModalFooter = (
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowCollectionDeleteModal(false)}>Cancel</Button>
        <Button variant="danger" onClick={() => deleteCollection()}>Yes, I'm sure</Button>
      </Modal.Footer>
    )
  }

  function updateZoomLevel(newZoomLevel) {
    setZoomLevel(newZoomLevel)
    collectionMap.jumpTo({ 'center': collectionMap.getCenter(), 'zoom': newZoomLevel });
  }
  
  function updateCollectionAddress(newCollectionAddress, lon, lat) {
    setCollectionAddress(newCollectionAddress)
    collectionMap.jumpTo({ 'center': [lon, lat], 'zoom': collectionMap.getZoom() });
  }

  return (
    <div className="app planpoint-dashboard">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/collections/${collectionId}`}>{(collection || {}).name}</a>
                    </li>
                    {/* <li className="breadcrumb-item active">
                      <CollectionBreadcrumbMenu collection={collection} currentScope='' collectionId={collectionId} />
                    </li> */}
                  </ol>
                </nav>
                <div className="d-flex justify-content-between">
                  <h1 className="page-title">{(collection || {}).name}</h1>
                  <div className="btn-toolbar d-flex flex-row">
                    <a
                      type="button"
                      className="btn btn-outline-primary"
                      href={`/collections/${(collection || {})._id}/embed`}
                      >
                      <i className="fas fa-code"></i>
                    </a>
                  </div>
                </div>
              </header>

              <div className="page-section">
                <div className="card">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card-body">
                        <form onSubmit={e => updateCollection(e)}>
                          <fieldset>
                            <h5>Settings</h5>
                            <p className="text-muted mb-4">Collection defaults and globals</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label className="col-md-3">Collection name</label>
                                <div className="col-md-9 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={collectionName}
                                    onChange={e => setCollectionName(e.target.value)}
                                    />
                                </div>
                              </div>

                              <div className="form-row">
                                <label className="col-md-3">Map settings</label>
                                <div className="col-md-6 mb-3">
                                  <label>Focus area</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={collectionAddress}
                                    onChange={e => googleAutocomplete(e.target.value)}
                                    />
                                  <small className="text-muted">Address to center the map at</small>

                                  {autocompletionItems}
                                </div>

                                <div className="col-md-3 mb-3">
                                  <label>Zoom level</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={zoomLevel}
                                    onChange={e => updateZoomLevel(e.target.value)}
                                    />
                                  <small className="text-muted">Inital zoom level of the map</small>
                                </div>
                              </div>
                            </div>

                            <hr />

                            <div className="form-actions">
                              {conditionalDeleteButton}

                              <button type="submit" className="btn btn-primary ml-auto" disabled={!hasUnsavedChanges()}>
                                Update Settings
                              </button>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bg-secondary h-100 rounded">
                        <div id={`mapbox-${(collection || {})._id}`} style={{ height: '100%', width: '100%', minHeight: '40vw' }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal show={showCollectionDeleteModal} onHide={() => setShowCollectionDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>You are about to permanently delete this collection. This action can not be undone.</p>
        </Modal.Body>

        {deletionModalFooter}
      </Modal>
    </div>
  )
}
