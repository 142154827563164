import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
// import * as serviceWorker from './serviceWorker';

import './custom.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://86e7b36e1235429ab6127845b1d49fcf@o442621.ingest.sentry.io/5414920",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
