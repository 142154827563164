import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import Header from './components/Header';
import Aside from './components/Aside';
import ProjectCard from './components/ProjectCard';
import GroupBreadcrumbMenu from './components/GroupBreadcrumbMenu';

export default function Group(props) {
  const [user, setUser] = useState()
  const [group, setGroup] = useState()
  const [projects, setProjects] = useState([])
  const [fetchedProjects, setFetchedProjects] = useState(false)
  const [fetchedUser, setFetchedUser] = useState(false)
  const [selectedProjects, setSelectedProjects] = useState([])

  const cookies = useMemo(() => new Cookies(), [])

  const { groupId } = useParams()

  useEffect(() => {
    async function fetchUser() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/users/${props.user._id}`
      };

      let response = await axios(options)
      setUser(response.data)
    }

    async function fetchGroup() {
      let response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/groups/${groupId}`
      })

      setGroup(response.data)
      fetchProjects(response.data)
    }

    async function fetchProjects(g) {
      let newProjects = []

      for (let p of g.projects) {
        let project = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/projects/${p}`
        })

        newProjects.push(project.data)
      }

      setProjects(newProjects)
    }

    if (!fetchedUser) {
      fetchUser()
      setFetchedUser(true)
    }

    if (!fetchedProjects) {
      fetchGroup()
      setFetchedProjects(true)
    }
  }, [fetchedUser, fetchedProjects, cookies, props.user._id, groupId])

  function addToSelection(project) {
    setSelectedProjects([...selectedProjects, project])
  }

  function removeFromSelection(project) {
    setSelectedProjects(selectedProjects.filter(p => p._id !== project._id))
  }

  let projectCards;
  if (user) {
    projectCards = projects.map((p, i) => (
      <ProjectCard
        key={`project-card-${i}`}
        project={p}
        user={props.user}
        addToSelection={p => addToSelection(p)}
        removeFromSelection={p => removeFromSelection(p)}
        />
    ))
  }

  async function removeProjectsFromGroup() {
    const blacklist = selectedProjects.map(p => p._id)
    const newProjects = projects.filter(p => !blacklist.includes(p._id))

    // add selectedProjects to group
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: { projects: newProjects },
      url: `${Env.url}/api/v1/groups/${group._id}`
    })

    setProjects([])
    setSelectedProjects([])
    setFetchedProjects(false)
  }

  async function deleteGroup() {
    // add selectedProjects to group
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/groups/${group._id}`
    })

    // HACK
    document.location = '/'
  }

  return (
    <div className="app planpoint-dashboard">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/groups/${groupId}`}>{(group || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <GroupBreadcrumbMenu group={group} currentScope='' groupId={groupId} />
                    </li>
                  </ol>
                </nav>
                <div className="d-flex justify-content-between">
                  <h1 className="page-title">{(group || {}).name}</h1>
                  <div className="btn-toolbar d-flex flex-row">
                    <button
                      type="button"
                      className={`btn btn-light d-${selectedProjects.length > 0 ? 'block' : 'none'}`}
                      onClick={e => removeProjectsFromGroup(e)}
                      >
                      Remove {selectedProjects.length} project{selectedProjects.length === 1 ? '' : 's'} from group
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => deleteGroup()}
                      >
                      Delete group
                    </button>
                  </div>
                </div>
              </header>

              <div className="page-section">
                <div className="row">
                  {projectCards}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
