import React, { useEffect, useRef } from 'react';

import Chart from 'chart.js/auto';

export default function BarChart(props) {
  const canvasRef = useRef(null)

  useEffect(() => {
    var ctx = canvasRef.current;

    const data = {
      labels: props.items.map(i => i.label).slice(0, props.limit || 5),
      datasets: [{
        label: ' Total responses',
        data: props.items.map(i => i.value).slice(0, props.limit || 5),
        backgroundColor: '#2418ab',
        borderColor: '#2418ab',
        borderWidth: 1
      }]
    };
    
    new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
          x: {
            grid: {
              display: 0
            }
          }
        },
        plugins: { legend: { display: false } }
      }
    })
  }, [props.items, props.limit])

  return (
    <div className="BarChart">
      <canvas className="BarChart-canvas" ref={canvasRef}></canvas>
    </div>
  )
}