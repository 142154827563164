import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import CustomSelect from './components/CustomSelect';
import GroupBreadcrumbMenu from './components/GroupBreadcrumbMenu';
import Header from './components/Header';
import Aside from './components/Aside';
import InteractiveCanvas from './components/InteractiveCanvas/InteractiveCanvas';

import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';

import '@simonwep/pickr/dist/themes/nano.min.css';
import Pickr from '@simonwep/pickr';
import e from 'cors';

export default function Settings(props) {
  const [group, setGroup] = useState({})
  const [projects, setProjects] = useState([])
  const [fetchedGroup, setFetchedGroup] = useState(false)
  const [fetchedProjects, setFetchedProjects] = useState(false)
  const [editableProject, setEditableProject] = useState();
  const [editableProjectPath, setEditableProjectPath] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [accentColor, setAccentColor] = useState('');
  const [pickr, setPickr] = useState();
  const [highlightProject, setHighlightProject] = useState();
  const [useCoordinates, setUseCoordinates] = useState(false);
  const [address, setAddress] = useState('');
  const [lon, setLon] = useState();
  const [lat, setLat] = useState();
  const [showToast, setShowToast] = useState(false);
  const [autocompletionResults, setAutocompletionResults] = useState([]);
  const [groupCollections, setGroupCollections] = useState([]);
  const [collections, setCollections] = useState([]);

  const cookies = useMemo(() => new Cookies(), [])

  const colorRef = useRef()
  const placeDetailsContainerRef = useRef()

  const { groupId } = useParams()

  useEffect(() => {
    async function fetchGroup() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/groups/${groupId}`
      };

      const response = await axios(options)
      setGroup(response.data)
      setGroupName(response.data.name)
      setAccentColor(response.data.accentColor)
      setAddress(response.data.address)
      setUseCoordinates(response.data.lon || response.data.lat)
      setLon(response.data.lon)
      setLat(response.data.lat)
      setGroupCollections(response.data.collections)

      // fetch collections
      const cResponse = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/collections`
      })
      setCollections(cResponse.data)

      if (!pickr) {
        const pickr = Pickr.create({
          el: colorRef.current,
          theme: 'nano',
          default: response.data.accentColor || '#000000',
          components: {
            // Main components
            preview: true,
            opacity: true,
            hue: true,
            interaction: {
              hex: true,
              rgba: true,
              // hsla: true,
              // hsva: true,
              // cmyk: true,
              input: true,
              save: true
            }
          }
        })

        pickr.on('change', (color, instance) => {
          setAccentColor(color.toHEXA().toString())
        })

        setPickr(pickr)
      }
    }

    async function fetchProjects() {
      let groupProjects = []

      for (let project of group.projects) {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          url: `${Env.url}/api/v1/projects/${project}`
        })

        setProjects([...groupProjects, response.data])
        groupProjects.push(response.data)
      }
    }

    if (!fetchedGroup) {
      fetchGroup()
      setFetchedGroup(true)
    }

    if (group._id && !fetchedProjects) {
      fetchProjects()
      setFetchedProjects(true)
    }
  }, [fetchedGroup, group._id, fetchedProjects, cookies, group.projects, groupId, pickr])

  async function updateEditableProject() {
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        path: editableProjectPath
      },
      url: `${Env.url}/api/v1/projects/${editableProject}`
    })

    document.querySelector("#root .InteractiveCanvas .edit-submission").click()
  }

  function setNewEditableProject(project) {
    setEditableProject(project ? project._id : undefined)
    setEditableProjectPath(project ? project.path : undefined)
  }

  let projectItems = (projects || []).map((project, i) => {
    let projectActions;
    if (project._id === editableProject) {
      projectActions = (
        <div className="list-group-item-figure">
          <Form.Group className="mb-0 mr-2 h-100 d-flex align-items-end">
            <button type="button" className="btn btn-outline-primary" onClick={() => setNewEditableProject()}>
              Cancel
            </button>
          </Form.Group>

          <Form.Group className="mb-0 h-100 d-flex align-items-end">
            <button type="button" className="btn btn-primary" onClick={() => updateEditableProject()}>
              Save
            </button>
          </Form.Group>
        </div>
      )
    } else {
      projectActions = (
        <div className="list-group-item-figure">
          <button type="button" className="btn btn-primary" onClick={() => setNewEditableProject(project)}>
            Edit
          </button>
        </div>
      )
    }

    return (
      <div
        className="list-group-item"
        key={`project-${i}`}
        onMouseEnter={() => setHighlightProject(project)}
        onMouseLeave={() => setHighlightProject()}
        >
        <div className="list-group-item-body">
          <h4 className="list-group-item-title">
            <a href={`/projects/${project._id}/details`}>{project.name}</a>
          </h4>
          <p className="list-group-item-text">
            {(project.projectType || 'Sales').replace(/^\w/, c => c.toUpperCase())} project
          </p>
        </div>

        {projectActions}
      </div>
    )
  })

  async function patchProject(project, resetEditableProject = true) {
    console.log(`patchProject`, project)

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: project,
      url: `${Env.url}/api/v1/projects/${project._id}`
    })
    // setFetchedGroup(false)

    let newProjects = []
    for (let p of projects) {
      if (p._id.toString() === project._id.toString()) {
        let newProject = JSON.parse(JSON.stringify(p))
        for (let key of Object.keys(project)) {
          newProject[key] = project[key]
        }
        newProjects.push(newProject)
      } else {
        newProjects.push(p)
      }

      setProjects(newProjects)
    }

    if (resetEditableProject) setEditableProject()
  }

  async function saveCoordinates(project, coordinates) {
    await patchProject({
      _id: project._id,
      path: JSON.stringify(coordinates)
    })
  }

  async function resetCoordinates(project) {
    await patchProject({
      _id: project._id,
      path: ''
    }, true)
  }

  function zoomIn() {
    setFullscreen(true)
  }

  function zoomOut() {
    setFullscreen(false)
  }

  async function updateGroup(e) {
    e.preventDefault()

    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        name: groupName,
        accentColor: accentColor,
        address: address,
        lon: lon,
        lat: lat,
        collections: groupCollections
      },
      url: `${Env.url}/api/v1/groups/${groupId}`
    })

    setGroup(response.data)

    setShowToast(true)
  }

  async function handleFileUpload(file) {
    const data = new FormData()
    data.append('vfile', file)

    const response = await axios({
      method: 'POST',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: data,
      url: `${Env.url}/api/v1/groups/${groupId}/image`
    })

    setGroup(response.data)
  }

  function updateAccentColor(newColor) {
    setAccentColor(newColor)
    if (newColor.length >= 7) pickr.setColor(newColor)
  }

  function hasUnsavedChanges() {
    return group === undefined ||
    group.name !== groupName ||
    group.accentColor !== accentColor ||
    group.address !== address ||
    group.lon !== lon ||
    group.lat !== lat ||
    group.collections !== collections
  }

  let conditionalCoordinateFields;
  if (useCoordinates) {
    conditionalCoordinateFields = [
      <div className="col-md-4 mb-3">
        <input
          type="number"
          placeholder="Longitude"
          className="form-control"
          value={lon}
          onChange={e => setLon(e.target.value)}
          />
      </div>,
      <div className="col-md-4 mb-3">
        <input
          type="number"
          placeholder="Latitude"
          className="form-control"
          value={lat}
          onChange={e => setLat(e.target.value)}
          />
      </div>
    ]
  }

  async function autoCompleteAddress(text) {
    setAddress(text)
    setAutocompletionResults([])

    // get more info about the place
    const gc = new window.google.maps.Geocoder()
    const coordinates = await gc.geocode({ address: text })

    setLat(coordinates.results[0].geometry.location.lat())
    setLon(coordinates.results[0].geometry.location.lng())
  }

  async function googleAutocomplete(text) {
    setAddress(text)

    const places = window.google.maps.places
    const suggestions = await new places.AutocompleteService().getPlacePredictions({ input: text })
    setAutocompletionResults(suggestions.predictions)
  }

  let autocompletionItems = [];
  if (autocompletionResults.length) {
    let autocompletionResultList = []
    for (let result of autocompletionResults) {
      autocompletionResultList.push(
        <div className="list-group-item" onClick={e => autoCompleteAddress(result.description)}>
          <div className="list-group-item-body">
            <h4 className="list-group-item-title">
              <span >{result.structured_formatting.main_text}</span>
            </h4>
            <p className="list-group-item-text">{result.structured_formatting.secondary_text}</p>
          </div>
        </div>
      )
    }

    autocompletionItems = (
      <div className="card card-fluid mt-2 places-autocompletion">
        <div className="list-group list-group-flush list-group-divider">
          {autocompletionResultList}
        </div>
      </div>
    )
  }

  function updateGroupCollections(selectedCollections) {
    let collectionMap = {}
    for (let collection of collections) {
      collectionMap[collection._id] = collection
    }

    let newSelection = []
    for (let sc of selectedCollections) {
      newSelection.push(collectionMap[sc.value])
    }

    setGroupCollections(newSelection)
  }

  return (
    <div className="app planpoint-exterior">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/groups/${groupId}/details`}>{group.name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <GroupBreadcrumbMenu group={group} currentScope='details' groupId={groupId} />
                    </li>
                  </ol>
                </nav>
                <h1 className="page-title">
                  {(group && (group.previewMode || group.groupType === 'land')) ? 'Land' : 'Settings'}
                </h1>
              </header>

              <div className="page-section">
                <div className="card">
                  <div className="row">
                    <div className={fullscreen ? "col-md-12 order-2" : "col-md-6"}>
                      <div className="card-body">
                        <form onSubmit={e => updateGroup(e)}>
                          <fieldset>
                            <h5>Settings</h5>
                            <p className="text-muted mb-4">Group defaults and globals</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label htmlFor="input01" className="col-md-3">Cover image</label>
                                <div className="col-md-9 mb-3">
                                  <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="input01" onChange={e => handleFileUpload(e.target.files[0])} />
                                    <label className="custom-file-label" htmlFor="input01">Choose cover</label>
                                  </div>
                                  <small className="text-muted">Upload a new cover image (max. 2MB)</small>
                                </div>
                              </div>

                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Group name</label>
                                <div className="col-md-9 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="tf1"
                                    aria-describedby="tf1Help"
                                    value={groupName}
                                    onChange={e => setGroupName(e.target.value)}
                                    />
                                </div>
                              </div>

                              <div className="form-row">
                                <label htmlFor="tf1" className="col-md-3">Accent color</label>
                                <div className="col-md-9 mb-3">
                                  <div id="colorpicker8" className="input-group input-group-alt" data-toggle="colorpicker" data-extensions='[ { "name": "swatches", "colors": { "black": "#000000", "white": "#ffffff", "red": "#FF0000", "default": "#777777", "primary": "#337ab7", "success": "#5cb85c", "info": "#5bc0de", "warning": "#f0ad4e", "danger": "#d9534f" }, "namesAsValues": true } ]'>
                                    <input type="text" className="form-control" value={accentColor} onChange={e => updateAccentColor(e.target.value)} />
                                    <span className="input-group-append">
                                      <span className="input-group-text colorpicker-input-addon">
                                        <input
                                          type="text"
                                          placeholder="#324A19"
                                          ref={colorRef}
                                          value={accentColor}
                                          onChange={e => setAccentColor(e.target.value)}
                                          />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <hr className="mt-3 mb-4" />

                            <h5>Collection settings</h5>
                            <p className="text-muted mb-4">Define an address and/or coordinates to properly place this group on a collection map.</p>
                            <div className="list-group list-group-flush">
                              <div className="form-row">
                                <label className="col-md-3">Address</label>
                                <div className="col-md-9 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={address}
                                    onChange={e => googleAutocomplete(e.target.value)}
                                    />

                                  {autocompletionItems}
                                </div>
                              </div>

                              <div className="form-row" key='formsOption'>
                                <label className="col-md-3">Use coordinates</label>
                                <div className="col-md-1 mb-3">
                                  <label className="switcher-control switcher-control">
                                    <input
                                      type="checkbox"
                                      name="useCoordinates"
                                      className="switcher-input"
                                      checked={useCoordinates}
                                      onChange={() => setUseCoordinates(!useCoordinates)}
                                      />
                                    <span className="switcher-indicator"></span>
                                  </label>
                                </div>
                                {conditionalCoordinateFields}
                              </div>

                              <div className="form-row">
                                <label className="col-md-3">Collections</label>
                                <div className="col-md-9 mb-3">
                                <CustomSelect
                                  value={(groupCollections || []).map(c => (
                                    { value: c._id, label: c.name }
                                  ))}
                                  options={(collections || []).map(c => (
                                    { value: c._id, label: c.name }
                                  ))}
                                  isSearchable={false}
                                  isClearable={false}
                                  hideSelectedOptions={false}
                                  isMulti={true}
                                  placeholder="None"
                                  onChange={e => updateGroupCollections(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </form>

                        <hr className="mt-3 mb-4" />

                        <h5>Projects</h5>
                        <p className="text-muted mb-4">Draw project outlines on the right canvas</p>
                        <div className="list-group list-group-flush list-group-divider">
                          {projectItems}
                        </div>

                        <hr className="mt-3 mb-4" />

                        <div className="form-actions">
                          <button
                            onClick={e => updateGroup(e)}
                            disabled={!hasUnsavedChanges()}
                            className="btn btn-primary ml-auto"
                            >
                            Update Settings
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={fullscreen ? "col-md-12 order-1" : "col-md-6"}>
                      <InteractiveCanvas
                        styleSettings={group.groupImageUrl ? {backgroundImage: `url('${group.groupImageUrl}')`} : {}}
                        backgroundUrl={group.groupImageUrl}
                        editableItem={editableProject}
                        highlightItem={highlightProject}
                        container={group}
                        group={group}
                        scope='groups'
                        accentColor={group.accentColor}
                        items={projects}
                        containerId={groupId}
                        setContainer={p => setGroup(p)}
                        saveAction={(project, coordinates) => saveCoordinates(project, coordinates)}
                        resetAction={(project) => resetCoordinates(project)}
                        zoomIn={() => zoomIn()}
                        zoomOut={() => zoomOut()}
                        disableLabel
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Toast
        style={{ position: 'fixed', top: '4.5rem', right: '1rem', zIndex: '1050' }}
        onClose={() => setShowToast(false)} show={showToast}
        delay={3000} autohide
        >
        <Toast.Header>
          <strong className="mr-auto">Info</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>The group has been updated successfully.</Toast.Body>
      </Toast>

      <div className="d-none" ref={placeDetailsContainerRef}></div>
    </div>
  )
}
