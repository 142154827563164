import React, { useState } from 'react'
import axios from 'axios';

import Env from '../../Environments';

import '../Auth/Auth.css';

import planpointLogo from '../../assets/planpoint-logo.svg'

export default function Reset(props) {
  const [email, setEmail] = useState()
  const [waitingForServer, setWaitingForServer] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    setWaitingForServer(true)

    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: { username: email },
      url: `${Env.url}/api/v1/users/reset-password`
    })

    setWaitingForServer(false)

    // go back
    document.location = '/'
  }

  let buttonLabel;
  if (waitingForServer) {
    buttonLabel = <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing ...</>
  } else {
    buttonLabel = 'Reset Password'
  }

  return (
    <div className="Auth">
      <main className="auth bg-white" style={{ minHeight: '100vh' }}>
        <form className="auth-form auth-form-reflow Auth-form" onSubmit={e => handleSubmit(e)}>
          <div className="text-center mb-4">
            <div className="mb-4">
              <img src={planpointLogo} height="24px" alt="" />
            </div>
            <h4>Reset Your Password</h4>
          </div>

          <div className="form-group mb-4">
            <label className="d-block text-left" for="inputUser"></label>
            <input
              type="text"
              className="form-control form-control-lg"
              required=""
              autofocus=""
              placeholder="you@email.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
              />

            <p className="text-muted">
              <small>We'll send a password reset link to your email.</small>
            </p>
          </div>

          <div className="d-block d-md-inline-block mb-2">
            <button className="w-100 Auth-form-submit btn btn-primary" type="submit">
              {buttonLabel}
            </button>
          </div>

          <div className="d-block d-md-inline-block">
            <a href="/login" className="btn btn-block btn-light">Return to signin</a>
          </div>
        </form>
      </main>
    </div>
  )
}
