import React from 'react';

import Form from 'react-bootstrap/Form';

export default function BreadcrumbMenu(props) {
  let conditionalStatOption;
  if (props.user.username.endsWith('@planpoint.io')) {
    conditionalStatOption = <option value="stats">Stats</option>
  }

  return (
    <Form.Control
      custom
      as="select"
      value={props.currentScope}
      className="project-scope-selector"
      onChange={e => document.location = `/projects/${props.projectId}/${e.target.value}`}
      >
      <option value="embed">Embed</option>
      <option value="details">Settings</option>
      <option value="exterior">{(props.project && (props.project.previewMode || props.project.projectType === 'land')) ? 'Land' : 'Exterior'}</option>
      <option value="units">Units</option>
      <option value="gallery">Media</option>
      <option value="floors">{(props.project && (props.project.previewMode || props.project.projectType === 'land')) ? 'Phases' : 'Floors'}</option>
      <option value="members">Members</option>
      <option value="forms">Forms</option>
      {conditionalStatOption}
    </Form.Control>
  )
}
