import React, { useEffect, useState } from 'react';
import BarChart from '../../charts/BarChart';

export default function SalesLastMonth(props) {
  const [data, setData] = useState([])
  const [days, setDays] = useState(28)
  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)

    let thisMonthStart = new Date(today)
    thisMonthStart.setDate(1)

    let lastMonthStart = new Date(thisMonthStart)
    lastMonthStart.setDate(0)
    lastMonthStart.setDate(1)

    const daysCount = ((((thisMonthStart-lastMonthStart)/1000)/60)/60)/24
    setDays(daysCount)

    let monthEvents = []
    let runningDate = new Date(lastMonthStart)
    for (let i = 0; i < daysCount; i++) {
      runningDate.setDate(i+1)

      monthEvents[i] = {
        label: runningDate.toLocaleDateString('en-US', { month: '2-digit', day: 'numeric' }),
        value: 0
      }
    }

    if (props.data && props.data.sale) {
      for (let event of props.data.sale) {
        const eventTS = new Date(event.createdAt)

        if (eventTS >= lastMonthStart && eventTS < thisMonthStart) {
          monthEvents[eventTS.getDate()-1].value += 1
        }
      }
    }

    setData(monthEvents)
    setDataReady(true)
  }, [props.data])

  let conditionalChart;
  if (dataReady) {
    conditionalChart = (
      <BarChart
        limit={days}
        items={data}
        />
    )
  }

  return (
    <div className="col-md-6 col-sm-12">
      <div className="card card-fluid">
      <div className="card-header"> Sales/rentals last month </div>
        <div className="card-body text-center">
          {conditionalChart}
        </div>
      </div>
    </div>
  )
}