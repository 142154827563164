import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Header from '../components/Header';
import Aside from '../components/Aside';

import Account from './components/Account';
import Billing from './components/Billing';
import Notifications from './components/Notifications';
import Invoices from './components/Invoices';

export default function Settings(props) {
  const [user, setUser] = useState()
  const [scope, setScope] = useState('account')
  const [fetchedUser, setFetchedUser] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchUser() {
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        url: `${Env.url}/api/v1/users/${props.user._id}`
      };

      let response = await axios(options)
      setUser(response.data)
    }

    if (!fetchedUser) {
      fetchUser()
      setFetchedUser(true)
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('scope') !== 'null') setScope(urlParams.get('scope'))
  }, [user, cookies, fetchedUser, props.user._id])

  let settingsPanel;
  switch (scope) {
    case 'account':
      settingsPanel = (
        <Account
          user={props.user}
          fetchUser={() => setFetchedUser(false)}
          />
        )
      break;
    case 'billing':
      settingsPanel = (
        <Billing
          user={props.user}
          fetchUser={() => setFetchedUser(false)}
          fetchCurrentUser={() => props.fetchCurrentUser()}
          />
        )
      break;
    case 'notifications':
      settingsPanel = (
        <Notifications
          user={props.user}
          fetchUser={() => setFetchedUser(false)}
          />
        )
      break;
    case 'invoices':
      settingsPanel = (
        <Invoices
          user={props.user}
          fetchUser={() => setFetchedUser(false)}
          />
        )
      break;
    default:
      settingsPanel = (
        <Account
          user={props.user}
          fetchUser={() => setFetchedUser(false)}
          />
        )
      break;
  }

  return (
    <div className="app planpoint-dashboard">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <h1 className="page-title">Settings</h1>
              </header>

              <div className="page-section">

                <div className="row">

                  <div className="col-lg-4">

                    <div className="card card-fluid">
                      <h6 className="card-header"> Your Details </h6>
                      <nav className="nav nav-tabs flex-column border-0">
                        <a
                          href="/settings?scope=account"
                          className={`nav-link ${scope === 'account' ? 'active' : ''}`}
                          >
                          Account
                        </a>
                        <a
                          href="/settings?scope=billing"
                          className={`nav-link ${scope === 'billing' ? 'active' : ''}`}
                          >
                          Billing
                        </a>
                        <a
                          href="/settings?scope=invoices"
                          className={`nav-link ${scope === 'invoices' ? 'active' : ''}`}
                          >
                          Invoices
                        </a>
                      </nav>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    {settingsPanel}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
