import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Env from '../../../Environments';

export default function Account(props) {
  const [firstName, setFirstName] = useState(props.user.name)
  const [lastName, setLastName] = useState(props.user.lastName)
  const [username, setUsername] = useState(props.user.username)
  // eslint-disable-next-line
  const [password, setPassword] = useState()

  const cookies = new Cookies();

  async function handleSubmit(e) {
    e.preventDefault()

    let updatedUser = Object.assign(props.user)
    updatedUser.name = firstName
    updatedUser.lastName = lastName
    updatedUser.username = username

    if (password) updatedUser.password = password

    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: updatedUser,
      url: `${Env.url}/api/v1/users/${props.user._id}`
    })

    props.fetchUser()
  }

  return (
    <div className="card card-fluid">
      <h6 className="card-header">Account Information </h6>
        <div className="card-body">
          <Form onSubmit={e => handleSubmit(e)}>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="first-name">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="first-name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)} required=""
                  />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="last-name">Last Name</label>
                <input type="text"
                  className="form-control"
                  id="last-name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)} required=""
                  />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email"
                className="form-control"
                id="email"
                value={username}
                onChange={e => setUsername(e.target.value)} required=""
                />
            </div>

            <div className="form-group">
              <label htmlFor="new-password">New Password</label>
              <input type="password"
                className="form-control"
                id="new-password"
                />
              <small className="text-muted">Leave this field empty if you don't want to set a new password.</small>
            </div>
            <hr />

            <div className="form-actions">
              <Button type="submit" className="btn btn-primary text-nowrap ml-auto">Update Account</Button>
            </div>
          </Form>
        </div>
    </div>
  )
}
