import React, { useState } from "react"

export default function ProjectEventTable(props) {
  const [unitSorting, setUnitSorting] = useState('name')
  const [unitSortingDirection, setUnitSortingDirection] = useState('asc')

  function reorderUnits(property) {
    if (property === unitSorting) {
      setUnitSortingDirection(unitSortingDirection === 'desc' ? 'asc' : 'desc')
    } else {
      setUnitSorting(property)
      setUnitSortingDirection('desc')
    }
  }

  function sortRows(data) {
    return data.sort((a, b) => {
      if (unitSortingDirection === 'asc') {
        return a[unitSorting] - b[unitSorting]
      } else {
        return b[unitSorting] - a[unitSorting]
      }
    })
  }

  let rowData = props.units.map(u => {
    let unitViews = 0;
    if (props.unitEvents[u._id] && props.unitEvents[u._id].view) {
      unitViews = props.unitEvents[u._id].view.length
    }

    let unitFavs = 0;
    if (props.unitEvents[u._id] && props.unitEvents[u._id].add_to_favorites) {
      unitFavs = props.unitEvents[u._id].add_to_favorites.length
    }

    let unit3DView = 0;
    if (props.unitEvents[u._id] && props.unitEvents[u._id]['3D_view']) {
      unit3DView = props.unitEvents[u._id]['3D_view'].length
    }

    let unitGalleryView = 0;
    if (props.unitEvents[u._id] && props.unitEvents[u._id].gallery_view) {
      unitGalleryView = props.unitEvents[u._id].gallery_view.length
    }

    let unitFinishesView = 0;
    if (props.unitEvents[u._id] && props.unitEvents[u._id].finishes_view) {
      unitFinishesView = props.unitEvents[u._id].finishes_view.length
    }

    let unitPDFDl = 0;
    if (props.unitEvents[u._id] && props.unitEvents[u._id].download_pdf) {
      unitPDFDl = props.unitEvents[u._id].download_pdf.length
    }

    let unitLeads = 0;
    if (props.unitEvents[u._id] && props.unitEvents[u._id].signup) {
      unitLeads = props.unitEvents[u._id].signup.length
    }

    return ({
      name: u.name,
      unitViews: unitViews,
      unitLeads: unitLeads,
      unitPDFDl: unitPDFDl,
      unit3DView: unit3DView,
      unitGalleryView: unitGalleryView,
      unitFinishesView: unitFinishesView,
      unitFavs: unitFavs
    })
  })

  let unitRows = sortRows(rowData).map((rd, i) => (
    <tr key={`unit-row-${i}`}>
        <td>{rd.name}</td>
        <td>{rd.unitViews}</td>
        <td>{rd.unitLeads}</td>
        <td>{rd.unitPDFDl}</td>
        <td>{rd.unit3DView}</td>
        <td>{rd.unitGalleryView}</td>
        <td>{rd.unitFinishesView}</td>
        <td>{rd.unitFavs}</td>
      </tr>
  ))

  return (
    <table id="dt-responsive" className="table dt-responsive nowrap w-100 dataTable dtr-inline" role="grid" aria-describedby="dt-responsive_info">
      <thead>
        <tr role="row">
          <th
            className={`align-middle ${unitSorting === 'name' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('name')}
            >
            Unit
          </th>
          <th
            className={`align-middle ${unitSorting === 'unitViews' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('unitViews')}
            >
            Views
          </th>
          <th
            className={`align-middle ${unitSorting === 'unitLeads' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('unitLeads')}
            >
            Leads
          </th>
          <th
            className={`align-middle ${unitSorting === 'unitPDFDl' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('unitPDFDl')}
            >
            Downloads
          </th>
          <th
            className={`align-middle ${unitSorting === 'unit3DView' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('unit3DView')}
            >
            3D Views</th>
          <th
            className={`align-middle ${unitSorting === 'unitGalleryView' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('unitGalleryView')}
            >
            Gallery
            </th>
          <th
            className={`align-middle ${unitSorting === 'unitFinishesView' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('unitFinishesView')}
            >
              Finishes
            </th>
          <th
            className={`align-middle ${unitSorting === 'unitFavs' ? (unitSortingDirection === 'desc' ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}
            tabIndex="0"
            aria-controls="dt-responsive"
            rowSpan="1"
            colSpan="1"
            onClick={() => reorderUnits('unitFavs')}
            >
            Favorites
          </th>
        </tr>
      </thead>
      <tbody>
        {unitRows}
      </tbody>
    </table>
  )
}