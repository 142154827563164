const production = {
  url: 'https://planpoint-v1.herokuapp.com',
  stripePromise: 'pk_live_51HJozNCEdfdmzaWJxbO7fUkw8d1WkjnBArhoPlIUN3GHa2igi44YO9kSRagMcmgrE0O0nkDz8JinXRhMlY8ZIH2V003GMqeB3V'
}

const development = {
  url: 'http://localhost:5001',
  stripePromise: 'pk_test_51HIgX4BIBKI9V4KICMMW9McV4JCfY8DN1Z05fI7YhmM7CZF23hSYKYPLIZkNnLf6QNH7T8ElnQNIzZ7kUul3dnn400rQFlvwK0'
}

export default process.env.NODE_ENV === 'production' ? production : development;
