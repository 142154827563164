import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../Environments';

import BreadcrumbMenu from './components/BreadcrumbMenu';
import Header from './components/Header';
import Aside from './components/Aside';
import MemberRow from './components/MemberRow';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import plans from '../services/plans';

export default function Floors(props) {
  const [project, setProject] = useState({})
  const [scope, setScope] = useState('all')
  const [showNewMemberModal, setShowNewMemberModal] = useState(false)
  const [invitationEmail, setInvitationEmail] = useState('')
  const [invitationLevel, setInvitationLevel] = useState('')

  const cookies = new Cookies();

  const { projectId } = useParams()

  async function fetchProject() {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      url: `${Env.url}/api/v1/projects/${projectId}`
    };

    const response = await axios(options)
    setProject(response.data)
  }

  useEffect(() => {
    if (!project._id) fetchProject()
  })

  async function sendInvite() {
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: {
        invites: [...(project.invites || []), {
          email: invitationEmail, level: invitationLevel
        }]
      },
      url: `${Env.url}/api/v1/projects/${projectId}`
    };

    const response = await axios(options)
    setProject(response.data)
    setInvitationEmail('')
    setInvitationLevel('')
    setShowNewMemberModal(false)

    document.location.reload()
  }

  async function deleteMember(memberType, member) {
    let updatedProject = Object.assign(project)

    if (memberType === 'admin') updatedProject.administrators = updatedProject.administrators.filter(a => a !== member)
    if (memberType === 'editor') updatedProject.editors = updatedProject.editors.filter(e => e !== member)
    if (memberType === 'invite') updatedProject.invites = updatedProject.invites.filter(i => i !== member)

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('planpoint')
      },
      data: updatedProject,
      url: `${Env.url}/api/v1/projects/${projectId}`
    };

    const response = await axios(options)
    setProject(response.data)
  }

  let administrators = (project.administrators || []).map((admin, i) => (
    <MemberRow
      key={`administrators-${i}`}
      email={admin.username}
      projectId={project._id}
      status='Activated'
      level='Administrator'
      deleteMember={() => deleteMember('admin', admin)}
      />
  ))

  let editors = (project.editors || []).map((editor, i) => (
    <MemberRow
      key={`editors-${i}`}
      email={editor.username}
      projectId={project._id}
      status='Activated'
      level='Editor'
      deleteMember={() => deleteMember('editor', editor)}
      />
  ))

  let invites = (project.invites || []).map((invite, i) => (
    <MemberRow
      key={`invites-${i}`}
      email={invite.email}
      projectId={project._id}
      status='Pending'
      level={invite.level}
      deleteMember={() => deleteMember('invite', invite)}
      />
  ))

  let selectedMembers;
  switch (scope) {
    case 'all':
      selectedMembers = [...administrators, ...editors, ...invites]
      break;
    case 'editors':
      selectedMembers = [...editors]
      break;
    case 'administrators':
      selectedMembers = [...administrators]
      break;
    default:
      selectedMembers = invites
  }

  const totalMembers = administrators.length + editors.length + invites.length

  let maxMembers = {}
  plans.plans[process.env.NODE_ENV].forEach(e => { maxMembers[e.priceId] = e.maxMembers })

  let invitePossible = true;
  if (props.user.username.endsWith('@planpoint.io')) {
    invitePossible = true
  } else if (project && project.plan) {
    invitePossible = totalMembers <= maxMembers[project.plan.plan.id]
  } else {
    invitePossible = false
  }

  let inviteCTA;
  if (invitePossible) {
    inviteCTA = (
      <button type="button" className="btn btn-light" onClick={() => setShowNewMemberModal(true)}>
        <i className="oi oi-people"></i> <span className="ml-1">Invite members</span>
      </button>
    )
  } else if (totalMembers >= 10) {
    inviteCTA = (
      <button type="button" className="btn btn-light" href="mailto:support@planpoint.io">
        <i className="oi oi-people"></i> <span className="ml-1">Upgrade plan</span>
      </button>
    )
  } else {
    inviteCTA = (
      <a href="/settings" className="btn btn-light">
        <i className="oi oi-people"></i> <span className="ml-1">Upgrade plan</span>
      </a>
    )
  }

  return (
    <div className="app planpoint-floors">
      <Header user={props.user} signOutUser={() => props.signOutUser()} fetchCurrentUser={() => props.fetchCurrentUser()}/>
      <Aside user={props.user} signOutUser={() => props.signOutUser()} />

      <main className="app-main">
        <div className="wrapper">
          <div className="page">
            <div className="page-inner">
              <header className="page-title-bar">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <a href="/"><i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={`/projects/${projectId}/details`}>{(project || {}).name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <BreadcrumbMenu user={props.user} project={project} currentScope='members' projectId={projectId} />
                    </li>
                  </ol>
                </nav>
                <div className="d-md-flex align-items-md-start">
                  <h1 className="page-title mr-sm-auto">Team</h1>
                  <div className="btn-toolbar">
                    {inviteCTA}
                  </div>
                </div>
              </header>

              <div className="page-section">

                <div className="card card-fluid">

                  <div className="card-header">

                    <ul className="nav nav-tabs card-header-tabs">
                      <li className="nav-item">
                        <a className="nav-link active show" data-toggle="tab" href="#tab1" onClick={e => setScope('all')}>All</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tab2" onClick={e => setScope('administrators')}>Administrators</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tab3" onClick={e => setScope('editors')}>Editors</a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">

                      <table className="table">

                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Link</th>
                            <th style={{ width: '150px', minWidth: '150px' }}>Invitation</th>
                            <th style={{ width: '150px', minWidth: '150px' }}>Level</th>
                            <th style={{ width: '50px', minWidth: '50px' }}> &nbsp; </th>
                          </tr>
                        </thead>

                        <tbody>
                          {selectedMembers}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={showNewMemberModal} onHide={() => setShowNewMemberModal(false)}>
                <Modal.Header closeButton>
                  <h6 className="modal-title inline-editable">
                    Invite member
                  </h6>
                </Modal.Header>
                <Modal.Body>

                  <form onSubmit={() => sendInvite()}>
                    <fieldset>
                      <div className="list-group list-group-flush">
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="cnContactEmail">Contact email</label>
                              <input type="email" id="cnContactEmail" className="form-control" value={invitationEmail} onChange={e => setInvitationEmail(e.target.value)}/>
                              <small className="form-text text-muted">If the user has no account yet, they will have to use this email for signup.</small>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="cnContactEmail">Permission level</label>
                              <select className="custom-select" id="sel1" required="" onChange={e => setInvitationLevel(e.target.value)}>
                                <option value=""> Choose... </option>
                                <option value='Administrator'>Administrator</option>
                                <option value='Editor'>Editor</option>
                              </select>

                              <small className="form-text text-muted">
                                <strong>Administrators</strong> can edit everything, but can't delete a project.<br /><strong>Editors</strong> can only edit unit lists, but can't delete a project.
                              </small>
                            </div>
                          </div>
                        </div>

                      </div>
                    </fieldset>
                  </form>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={() => sendInvite()}>
                    Send Invite
                  </Button>

                  <Button variant="secondary" onClick={() => setShowNewMemberModal(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
