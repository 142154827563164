import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SignupForm from './components/SignupForm'
import LoginForm from './components/LoginForm'
import ProjectInviteForm from './components/ProjectInviteForm'

import './Auth.css';

import splash1 from '../../assets/signup.svg'
import splash2 from '../../assets/login.jpg'
import logo from '../../assets/planpoint-logo.svg'

import { Linkedin, Instagram, Facebook } from 'react-feather';

export default function Auth(props) {

  let conditionalForm;
  if (props.newUser) {
    if (props.projectInvite) {
      conditionalForm = <ProjectInviteForm signInUser={d => props.signInUser(d)} />
    } else {
      conditionalForm = <SignupForm signInUser={d => props.signInUser(d)} />
    }
  } else {
    conditionalForm = <LoginForm signInUser={d => props.signInUser(d)} />
  }

  return (
    <div className="Auth-wrapper">
      <div className="Auth">
        <Row className="Auth-row">
          <Col className="Auth-col" md={12} lg={6}>
            <div className="Auth-col-header"></div>

            {conditionalForm}

            <div className="Auth-col-footer"></div>
          </Col>

          <Col className="Auth-col" md={12} lg={6}>
            <div className="Auth-img">
              <img src={props.newUser ? splash1 : splash2} alt="" />
            </div>
          </Col>
        </Row>
      </div>

      <div className="Auth-footer">
        <div className="Auth-footerInner">
          <div className="Auth-footerCols">
            <div className="Auth-footerCol">
              <img src={logo} width={205} height={37} />
            </div>
            <div className="Auth-footerCol">
              <div className="Auth-footerMenu">
                <span className="Auth-footerMenuTitle">Legal</span>
                <a href="https://www.planpoint.io/privacy-policy" className="Auth-footerMenuLink">Privacy Policy</a>
                <a href="https://www.planpoint.io/terms-of-service" className="Auth-footerMenuLink">Terms of service</a>
              </div>
            </div>
            <div className="Auth-footerCol">
              <div className="Auth-footerMenu">
                <span className="Auth-footerMenuTitle">Support</span>
                <a href="https://planpoint.helpscoutdocs.com/" className="Auth-footerMenuLink">Knowledge base</a>
                <a href="/" className="Auth-footerMenuLink">Chat with us</a>
              </div>
            </div>
            <div className="Auth-footerCol">
            </div>
          </div>

          <div className="Auth-postFooter">
            <div className="Auth-postFooterLogo">
              <img src={logo} width={121} height={22} />
            </div>

            <div className="Auth-social">
              <a href="https://www.linkedin.com/company/68575632/"><Linkedin size={20} /></a>
              <a href="https://www.instagram.com/planpoint.io/"><Instagram size={20} /></a>
            </div>
            <div className="Auth-copyright">
              © {new Date().getFullYear()} Planpoint
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
