import React, { useState, useRef, useMemo } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export default function FloorRow(props) {
  // eslint-disable-next-line
  const [floor, setFloor] = useState(props.floor)
  const [isUploading, setIsUploading] = useState(false)
  const [showFloorplanCloneModal, setShowFloorplanCloneModal] = useState(false)
  const [cloneSource, setCloneSource] = useState()
  const [showFloorplanModal, setShowFloorplanModal] = useState(false)
  const [floorplans, setFloorplans] = useState([])

  const cookies = useMemo(() => new Cookies(), [])

  const floorplanUrl = useRef()

  let availableUnits = []
  if (floor._id) availableUnits = floor.units.filter(u => u.availability === 'Available')

  async function uploadFloorplan(file, floor) {
    setIsUploading(true)

    const data = new FormData()
    data.append('vfile', file)

    const options = {
      method: 'POST',
      headers: { 'Authorization': cookies.get('planpoint') },
      data: data,
      url: `${Env.url}/api/v1/floors/${floor._id}/image`
    };

    await axios(options)
    await props.fetchProject()
    setIsUploading(false)
  }

  async function cloneFloor() {
    // // delete all units from the current floor
    // floor.units.forEach(async unit => {
    //   await axios({
    //     method: 'DELETE',
    //     headers: { 'Authorization': cookies.get('planpoint') },
    //     url: `${Env.url}/api/v1/units/${unit._id}`
    //   })
    // })

    // duplicate every unit from the source floor
    const source = props.floors.filter(floor => floor._id === cloneSource)[0]
    source.units.forEach(async (unit, i) => {
      if (floor.units[i]) {
        let newUnit = Object.assign(floor.units[i])
        newUnit.path = unit.path // update polygon

        await axios({
          method: 'PATCH',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          data: newUnit,
          url: `${Env.url}/api/v1/units/${newUnit._id}`
        })
      } else {
        let newUnit = Object.assign(unit)
        newUnit.floor = { _id: floor._id }

        await axios({
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('planpoint')
          },
          data: newUnit,
          url: `${Env.url}/api/v1/units`
        })
      }
    })

    setCloneSource()
    setShowFloorplanCloneModal(false)
    props.fetchProject()
  }

  if (floor._id) {
    let floorplanUploadCta;
    if (isUploading) {
      floorplanUploadCta = (
        <button className={floor.floorplanUrl ? 'btn btn-success mr-2' : 'btn btn-secondary mr-2'} type="button" disabled>
          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...
        </button>
      )
    } else {
      floorplanUploadCta = (
        <OverlayTrigger placement='top' overlay={<Tooltip>{(floor.project.previewMode || floor.project.projectType === 'land') ? 'Phaseplan' : 'Floorplan'}</Tooltip>}>
          <button className={`btn btn-sm btn-icon btn-${floor.floorplanUrl ? 'success' : 'secondary'} ml-1`} onClick={() => setShowFloorplanModal(true)}>
            <i className="fa fa-street-view"></i>
          </button>
        </OverlayTrigger>
      )
    }

    let floorplanUpload = (
      <>
        <input type="file" className="d-none" ref={floorplanUrl} onChange={e => uploadFloorplan(e.target.files[0], floor)} />

        {floorplanUploadCta}
      </>
    )

    let floorPlanClone = (
      <OverlayTrigger key='top' placement='top' overlay={<Tooltip>{floor.project.previewMode ? 'Clone phase' : 'Clone floorplan'}</Tooltip>}>
        <button className={`btn btn-sm btn-icon btn-secondary ml-1`} onClick={() => setShowFloorplanCloneModal(true)}>
          <i className="far fa-object-ungroup"></i>
        </button>
      </OverlayTrigger>
    )

    let floorAction;
    if (floor.units.length) {
      floorAction = (
        <div className="list-group-item-figure">
          {floorPlanClone}
          {floorplanUpload}
          <OverlayTrigger placement='top' overlay={<Tooltip>{floor.project.previewMode ? 'Edit units on phase' : 'Edit units on floorplan'}</Tooltip>}>
            <a href={`/projects/${props.project._id}/floors/${floor._id}`} className={`btn btn-sm btn-icon btn-secondary ml-1`}>
              <i className="fa fa-draw-polygon"></i>
            </a>
          </OverlayTrigger>
        </div>
      )
    } else {
      floorAction = (
        <div className="list-group-item-figure">
          {floorPlanClone}
          {floorplanUpload}
          <OverlayTrigger placement='top' overlay={<Tooltip>{floor.project.previewMode ? 'Add units to phase' : 'Add units to floor'}</Tooltip>}>
            <a href={`/projects/${props.project._id}/units`} className={`btn btn-sm btn-icon btn-secondary ml-1`}>
              <i className="fa fa-draw-polygon"></i>
            </a>
          </OverlayTrigger>
        </div>
      )
    }

    let floorCloningOptions = []
    props.floors.sort((a, b) => a.name - b.name).forEach((f, i) => {
      if (floor._id !== f._id) {
        floorCloningOptions.push(
          <option key={i} value={f._id}>{f.name}</option>
        )
      }
    })

    let conditionalSaveButton;
    if (cloneSource) {
      conditionalSaveButton = (
        <Button variant="primary" onClick={() => cloneFloor()}>
          Confirm and clone
        </Button>
      )
    }

    async function copyFloorplanFromProject(e, floorplan) {
      e.preventDefault()

      await axios({
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: { floorplanUrl: floorplan },
        url: `${Env.url}/api/v1/floors/${floor._id}`
      })

      await props.fetchProject()
    }

    async function deleteFloorplan(e) {
      e.preventDefault()

      await axios({
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('planpoint')
        },
        data: { floorplanUrl: '' },
        url: `${Env.url}/api/v1/floors/${floor._id}`
      })

      await props.fetchProject()
    }

    let floorFloorplans;
    if (floor.floorplanUrl) {
      floorFloorplans = (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="card card-figure">
            <figure className="figure">
              <div className="figure-img">
                <img className="img-fluid" src={floor.floorplanUrl} alt="Card cap" />
                <div className="figure-tools">
                  <a href={floor.floorplanUrl} target="_blank" rel="noopener noreferrer" className="tile tile-circle tile-sm mr-auto">
                    <span className="oi oi-data-transfer-download"></span>
                  </a>
                </div>
                <div className="figure-action">
                  <a href="/" className="btn btn-block btn-sm btn-danger" onClick={e => deleteFloorplan(e)}>
                    Delete
                  </a>
                </div>
              </div>
            </figure>
          </div>
        </div>
      )
    }

    let projectFloorplans = props.project.floorplans.map((floorplan, i) => {
      let fileSegments = floorplan.split('/').pop().split('.')
      let fileExtension = fileSegments.pop()
      let fileNameSegments = fileSegments.join('.').split('-')
      fileNameSegments.pop() // drop hash
      let fileNamePreview = fileNameSegments.join('-')
      if (fileNamePreview.length > 10) fileNamePreview = `${fileNamePreview.slice(0, 8)}...`

      return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
          <div className="card card-figure">
            <figure className="figure">
              <div className="figure-img">
                <img className="img-fluid" src={floorplan} alt="Card cap" />
                <div className="figure-action">
                  <a href="/" className="btn btn-block btn-sm btn-primary" onClick={e => copyFloorplanFromProject(e, floorplan)}>
                    Select
                  </a>
                </div>
              </div>
            </figure>
            <small className="text-center pt-1">{fileNamePreview}.{fileExtension}</small>
          </div>
        </div>
      )
    })

    function saveAndCloseFloorplanModal() {
      setShowFloorplanModal(false)
      setFloorplans([])
      props.fetchProject()
    }

    return (
      <div className="list-group-item">
        <div className="list-group-item-body">
          <h4 className="list-group-item-title">
            <a href={`/projects/${props.project._id}/floors/${floor._id}`}>{floor.name}</a>
          </h4>
          <p className="list-group-item-text">{availableUnits.length}/{floor.units.length} units available</p>
        </div>
        {floorAction}

        <Modal show={showFloorplanCloneModal} onHide={() => setShowFloorplanCloneModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Clone floorplan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-muted">You can clone another floor's layout with all it's units configurations. Be aware that cloning an existing floor layout will replace every unit on this floor.</p>

            <Form.Group>
              <Form.Label>Select floor</Form.Label>
              <Form.Control as="select" custom onChange={e => setCloneSource(e.target.value)}>
                <option value=""></option>
                {floorCloningOptions}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {conditionalSaveButton}
            <Button variant="secondary" onClick={() => setShowFloorplanCloneModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showFloorplanModal} onHide={() => saveAndCloseFloorplanModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Floorplan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="section-block">
              <h2 className="section-title">Upload a new floorplan</h2>
              <p className="text-muted">Add a new floorplan to this floor.</p>
            </div>

            <div className="row">
              {floorFloorplans}
            </div>

            <FilePond
              files={floorplans}
              allowRevert={false}
              onupdatefiles={setFloorplans}
              allowMultiple={false}
              maxParallelUploads={1}
              maxFiles={1}
              server={`${Env.url}/api/v1/floors/${floor._id}/floorplans`}
              name="floorplans"
              labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
            />

            <hr className="my-3" />

            <div className="section-block">
              <h2 className="section-title">Or pick from the gallery:</h2>
              <p className="text-muted">Select one of your previously uploaded floorplans from this project.</p>
            </div>

            <div className="row">
              {projectFloorplans}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => saveAndCloseFloorplanModal()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  } else {
    return (
      <div className="list-group-item">
        <div className="list-group-item-body"></div>
      </div>
    )
  }
}
